import React from "react";
import { Eye } from "iconsax-react";
import StatusBadge from "../../../table/StatusBadge";
import MainButton from "../../../button/MainButton";
import { TransformTime } from "../../../../utilities/helper/TransformPublishedAt";

interface MobileUsageCardProps {
  usage: any;
  onViewDetails: (item: any) => void;
}

const MobileUsageCard: React.FC<MobileUsageCardProps> = ({ usage, onViewDetails }) => {
  return (
    <div className="flex flex-col items-center px-4 text-center *:w-full *:py-6 *:border-b *:border-b-gorgonzola-blue-100 text-closed-shutter-300">
      <span className="font-medium line-clamp-1">{usage.user_email ? usage.user_email : "-"}</span>

      <div className="grid grid-cols-2 gap-2 *:space-y-2">
        <div>
          <p className="text-xs text-gray-500">Service</p>
          <span className="font-medium">{usage.service_name ? usage.service_name : "-"}</span>
        </div>
        <div>
          <p className="text-xs text-gray-500">Status</p>
          <div className="flex justify-center">
            <StatusBadge statusText={usage.status ? usage.status : "-"} />
          </div>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-2 *:space-y-2">
        <div>
          <p className="text-xs text-gray-500">Date</p>
          <p>{usage.created_at ? new Date(usage.created_at * 1000).toLocaleDateString() : "-"}</p>
        </div>
        <div>
          <p className="text-xs text-gray-500">Time</p>
          <p>{usage.created_at ? TransformTime(new Date(usage.created_at * 1000).toISOString()) : "-"}</p>
        </div>
      </div>

      <div className="flex justify-center w-full">
        <MainButton type="ghostOutlined" onClick={() => onViewDetails(usage)}>
          <Eye variant="Broken" className="mr-2 size-4" />
          See
        </MainButton>
      </div>
    </div>
  );
};

export default MobileUsageCard;
