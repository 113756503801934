import { useAutoTaggingServiceContext } from "../../../../contexts/AutoTaggingServiceContext";
import AutoTaggingServicePagination from "../AutoTaggingServicePagination";
import InteractiveAutoTaggingResult from "../InteractiveAutoTaggingResult";
import { ClothingItem } from "../../../../utilities/types/free-trial/auto-tagging-service/types";
import { useState, useEffect } from "react";

const AutoTaggingResultDisplay = () => {
  const { autoTaggingResult, hoveredItem, setHoveredItem } = useAutoTaggingServiceContext();
  const [activeItem, setActiveItem] = useState<ClothingItem | null>(null);

  // Set initial active item
  useEffect(() => {
    if (autoTaggingResult?.Clothes?.length && !activeItem) {
      setActiveItem(autoTaggingResult.Clothes[0]);
    }
  }, [autoTaggingResult]);

  // Update active item when hover changes
  useEffect(() => {
    if (hoveredItem) {
      setActiveItem(hoveredItem);
    }
  }, [hoveredItem]);

  const renderPropertyCard = (label: string, value: string) => (
    <div
      className="flex items-center gap-3 px-4 py-2.5 transition-colors duration-200 
                    bg-gradient-to-r from-[#FAFAED]/40 to-[#E3E4F8]/30 
                    hover:from-[#FAFAED]/60 hover:to-[#E3E4F8]/50 
                    border border-gorgonzola-blue-50/80 rounded-lg shadow-sm"
    >
      {/* <img src="/assets/image/free-trial/auto-tagging/sparkles.svg" alt="sparkles" className="w-5 h-5" /> */}
      <span className="text-xs font-semibold text-gorgonzola-blue-400">{label}:</span>
      <span className="text-xs capitalize text-closed-shutter-400">{value}</span>
    </div>
  );

  const renderColorTag = (colorArray: any) => {
    const processColor = (color: any) => {
      if (typeof color === "string") {
        const [r, g, b] = color
          .replace(/[\[\]]/g, "")
          .split(" ")
          .map(Number);
        return { r, g, b };
      } else if (Array.isArray(color) && color.length > 0 && typeof color[0] === "string") {
        const [r, g, b] = color[0]
          .replace(/[\[\]]/g, "")
          .split(" ")
          .map(Number);
        return { r, g, b };
      }
      return null;
    };

    const renderSingleColor = (color: any, index: number) => {
      const processedColor = processColor(color);
      if (!processedColor) return null;

      const { r, g, b } = processedColor;
      return (
        <div
          key={index}
          className="flex items-center gap-3 px-4 py-2.5 bg-gradient-to-r from-[#FAFAED]/40 to-[#E3E4F8]/30 hover:from-[#FAFAED]/60 hover:to-[#E3E4F8]/50 border border-gorgonzola-blue-50/80 rounded-lg shadow-sm transition-all duration-200"
        >
          {/* <img src="/assets/image/free-trial/auto-tagging/sparkles.svg" alt="sparkles" className="w-5 h-5" /> */}
          <span className="text-xs font-semibold text-gorgonzola-blue-400">Color:</span>
          <div className="w-6 h-6 border-2 rounded-full shadow-inner border-white/80" style={{ backgroundColor: `rgb(${r}, ${g}, ${b})` }} />
        </div>
      );
    };

    if (Array.isArray(colorArray)) {
      return colorArray.map((color, index) => renderSingleColor(color, index));
    }
    return renderSingleColor(colorArray, 0);
  };

  const renderClothingCard = (item: ClothingItem, index: number) => {
    return (
      <div
        key={index}
        className={`
             relative flex flex-col gap-5 p-5
             bg-gradient-to-br from-white via-white to-gorgonzola-blue-50/10
             border rounded-xl  border-gorgonzola-blue-50 transition-all duration-600
             ${hoveredItem === item ? "shadow-lg shadow-gorgonzola-blue-50/20 !border-gorgonzola-blue-300" : ""}
           `}
      >
        {/* Header - ClotheType */}
        <div className="relative pb-4 border-b border-gorgonzola-blue-50">
          <h3 className="text-base font-bold text-center text-closed-shutter-400">{item.ClotheType}</h3>
          <div className="absolute bottom-0 w-16 h-px transform -translate-x-1/2 left-1/2 bg-gradient-to-r from-transparent via-gorgonzola-blue-200 to-transparent" />
        </div>

        {/* Properties Grid */}
        <div className="grid">
          {/* Style Properties */}
          <div className="flex flex-wrap gap-x-2 gap-y-2">
            {Object.entries(item).map(([key, value]) => {
              if (!value || key === "Colors" || key === "Region" || key === "Gender" || key === "ClotheType") return null;
              return (
                <div key={key} className="w-fit">
                  {renderPropertyCard(key, value as string)}
                </div>
              );
            })}
          </div>

          {/* Colors Section */}
          {item.Colors?.length > 0 && <div className="flex flex-wrap gap-2 mt-3">{item.Colors.map((color, idx) => color && renderColorTag(color))}</div>}
        </div>
      </div>
    );
  };

  const renderClothesList = () => {
    if (!autoTaggingResult?.Clothes) return null;

    return (
      <div className="flex flex-wrap gap-2 p-2 mb-4 bg-white border rounded-xl border-gorgonzola-blue-50">
        {autoTaggingResult.Clothes.map((item, index) => (
          <button
            key={index}
            className={`px-3 py-1.5 text-xs rounded-md transition-all duration-200
              ${activeItem === item ? "bg-gorgonzola-blue-50 text-closed-shutter-400 font-medium shadow-sm" : "text-closed-shutter-300 hover:bg-gorgonzola-blue-50/50"}`}
            onMouseEnter={() => setHoveredItem(item)}
            onMouseLeave={() => setHoveredItem(null)}
            onClick={() => setActiveItem(item)}
          >
            {item.ClotheType}
          </button>
        ))}
      </div>
    );
  };

  return (
    <div className="flex flex-col items-center w-full gap-8">
      <div className="flex flex-col items-center w-full max-w-2xl gap-6 md:items-start md:flex-row">
        {/* Interactive Preview */}
        <InteractiveAutoTaggingResult />

        {/* Results Section */}
        <div className="">
          {autoTaggingResult?.Clothes.length ? (
            <div className="flex flex-col">
              {/* Clothes List */}
              {renderClothesList()}

              {/* Active Item Details */}
              {activeItem && <div className="max-w-xl bg-white md:min-w-[20rem] h-full rounded-xl">{renderClothingCard(activeItem, autoTaggingResult.Clothes.indexOf(activeItem))}</div>}
            </div>
          ) : null}
        </div>
      </div>

      <AutoTaggingServicePagination handlerButtonText="Try Again" isTaggingCompleted={true} />
    </div>
  );
};

export default AutoTaggingResultDisplay;
