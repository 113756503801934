import { useContext } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { SidebarContext } from "../../contexts/SidebarContext";
import { SidebarLeft } from "iconsax-react";
import { Link } from "react-router-dom";
import { useGlobalInfo } from "../../contexts/GlobalInfoContext";

const NavbarLeftSide = () => {
  const { isLogoOpen, toggleLogo } = useContext(SidebarContext)!;
  const { isAdmin } = useGlobalInfo();

  return (
    <div className={`relative flex items-center justify-between gap-2 w-fit `}>
      {/* <Link to={isAdmin ? "/admin" : "/dashboard"} className="z-30 min-w-fit max-h-[2.6875rem]">
        <img src="/assets/svg/main_logo_desktop.svg" alt="Main Logo" />
      </Link> */}

      <Link to={isAdmin ? "/admin" : "/dashboard"} className="z-30 min-w-fit bg-[#F9F9FA]">
        <img src="/assets/image/logo/new-logo.png" alt="Main Logo" className="size-[2.6875rem]" />
      </Link>

      <AnimatePresence>
        <motion.div className={`hidden lg:flex items-center justify-between w-full `} initial={{ x: 0, opacity: 0 }} animate={{ x: isLogoOpen ? 0 : 0, opacity: 1 }} transition={{ duration: 0.6 }}>
          {/*
            <Link to={isAdmin ? "/admin" : "/dashboard"} className={`hidden lg:block`}>
              <motion.img src="/assets/svg/logo_property.svg" alt="Main Logo" initial={{ opacity: 1 }} animate={{ opacity: isLogoOpen ? 0 : 1 }} transition={{ duration: 0.6 }} />
            </Link> 
          */}

          <motion.div
            onClick={toggleLogo}
            initial={{ rotate: 180 }}
            animate={{ rotate: isLogoOpen ? 180 : 0 }}
            transition={{ duration: 0.6 }}
            className="p-2 rounded-full cursor-pointer size-10 hover:bg-gorgonzola-blue-100 duration-600"
          >
            <motion.div initial={{ x: 0 }} animate={{ x: 0 }} whileHover={{ x: [0, -3, 3, -3, 3, -3, 0] }} transition={{ duration: 2, delay: 0.3 }}>
              <SidebarLeft variant="Broken" className="size-6 text-closed-shutter-300" />
            </motion.div>
          </motion.div>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default NavbarLeftSide;
