import { CardPos, Crown1, Data2, DocumentText1, HierarchySquare3, HomeHashtag, I24Support, LogoutCurve, MessageQuestion, Money4, People, ProfileCircle, Signpost } from "iconsax-react";
import { SidebarItem } from "../../types/sidebar/types";

// User Main Sidebar Items
export const USER_MAIN_SIDEBAR_ITEMS: SidebarItem[] = [
  {
    id: 0,
    title: "Dashboard",
    href: "/dashboard",
    type: "primary",
    svg: <HomeHashtag variant="Broken" />,
  },
  {
    id: 1,
    title: "Free Trial",
    href: "/dashboard/free-trial",
    type: "primary",
    svg: <HierarchySquare3 variant="Broken" />,
  },
  {
    id: 2,
    title: "Application",
    href: "/dashboard/application",
    type: "primary",
    svg: <Data2 variant="Broken" />,
  },
  {
    id: 3,
    title: "Plans",
    href: "/dashboard/plans",
    type: "primary",
    svg: <Crown1 variant="Broken" />,
  },
  {
    id: 4,
    title: "Usage",
    href: "/dashboard/usage",
    type: "primary",
    svg: <People variant="Broken" />,
  },
  {
    id: 5,
    title: "Payment",
    href: "/dashboard/payment",
    type: "primary",
    svg: <CardPos variant="Broken" />,
  },
];

// User Primary Sidebar Items
export const USER_PRIMARY_SIDEBAR_ITEMS: SidebarItem[] = [
  {
    id: 0,
    title: "Profile",
    href: "/dashboard/profile",
    type: "primary",
    svg: <ProfileCircle variant="Broken" />,
  },
  {
    id: 1,
    title: "Tutorial",
    href: "/dashboard/tutorial",
    type: "primary",
    svg: <Signpost variant="Broken" />,
  },
  {
    id: 2,
    title: "Support",
    href: "/dashboard/support",
    type: "primary",
    svg: <MessageQuestion variant="Broken" />,
  },
  {
    id: 3,
    title: "Log out",
    logout: true,
    type: "logout",
    svg: <LogoutCurve variant="Broken" />,
  },
];

// User Upgrade Sidebar Items
export const USER_UPGRADE_SIDEBAR_ITEMS: SidebarItem[] = [
  {
    id: 0,
    title: "Upgrade Plan",
    href: "/dashboard/plans",
    type: "upgrade",
    svg: <Crown1 variant="Broken" />,
  },
];

// Admin Main Sidebar Items
export const ADMIN_MAIN_SIDEBAR_ITEMS: SidebarItem[] = [
  {
    id: 0,
    title: "Dashboard",
    href: "/admin",
    type: "primary",
    svg: <HomeHashtag variant="Broken" />,
  },
  {
    id: 1,
    title: "Users List",
    href: "/admin/users",
    type: "primary",
    svg: <DocumentText1 variant="Broken" />,
  },
  {
    id: 2,
    title: "All Usage",
    href: "/admin/analytics/usage",
    type: "primary",
    svg: <Data2 variant="Broken" />,
  },
  {
    id: 3,
    title: "All plan Buy",
    href: "/admin/analytics/plans",
    type: "primary",
    svg: <Crown1 variant="Broken" />,
  },
  {
    id: 4,
    title: "All payments",
    href: "/admin/analytics/payments",
    type: "primary",
    svg: <Money4 variant="Broken" />,
  },
  {
    id: 5,
    title: "Received tickets",
    href: "/admin/tickets",
    type: "primary",
    svg: <I24Support variant="Broken" />,
  },
];

// Admin Primary Sidebar Items
export const ADMIN_PRIMARY_SIDEBAR_ITEMS: SidebarItem[] = [
  {
    id: 0,
    title: "Profile",
    href: "/admin/profile",
    type: "primary",
    svg: <ProfileCircle variant="Broken" />,
  },
  {
    id: 1,
    title: "Contact us",
    href: "/admin/contact",
    type: "primary",
    svg: <MessageQuestion variant="Broken" />,
  },
  {
    id: 2,
    title: "Log out",
    logout: true,
    type: "logout",
    svg: <LogoutCurve variant="Broken" />,
  },
];

export const ADMIN_UPGRADE_SIDEBAR_ITEMS: SidebarItem[] = [];
