import React, { useState } from "react";
import { motion } from "framer-motion";
import { PlanUsageProps, Plan } from "../../utilities/types/plan/types";
import { useGlobalInfo } from "../../contexts/GlobalInfoContext";
import { InfoCircle } from "iconsax-react";
import FreePlanModal from "./FreePlanModal";
import SizeMeasurementIcon from "../icon/SizeMeasurementIcon";
import ClothesRecognitionIcon from "../icon/ClothesRecognitionIcon";
import VisualClothesSearchIcon from "../icon/VisualClothesSearchIcon";

interface ProgressBarPlan {
  name: string;
  apis: {
    name: string;
    remaining_requests_pre: number;
  }[];
}

const PlanUsage: React.FC<PlanUsageProps> = ({ data, userDetail }) => {
  const { isAdmin, userInfo } = useGlobalInfo();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<Plan | null>(null);

  const services = [
    { name: "Body Measurement", label: "Body Measurement", icon: SizeMeasurementIcon, color: "#F7BA1E" },
    { name: "Auto Tagging", label: "Auto Tagging", icon: ClothesRecognitionIcon, color: "#3491FA" },
    { name: "Visual Clothes Search", label: "Visual Clothes Search", icon: VisualClothesSearchIcon, color: "#00A991" },
  ];

  const renderProgressBar = (plan: ProgressBarPlan, color: string) => {
    const usagePercentage = plan.apis[0].remaining_requests_pre ?? 0;

    return (
      <div className="relative w-full h-2.5 rounded-full bg-white">
        <div
          className={`absolute inset-0 h-full rounded-full`}
          // className={`absolute inset-0 h-full rounded-full bg-[linear-gradient(90deg,_${color}_44.5%,_${color}99_100%)]`}
          // className={`absolute inset-0 h-full rounded-full ${
          //   plan.apis[0].name === "Body Measurement" ? "bg-[linear-gradient(90deg,_#3491FA_44.5%,_#004594_100%)]" : "bg-[linear-gradient(90deg,_#F7BA1E_44.5%,_#AB7B00_100%)]"
          // }`}
          style={{ width: `${usagePercentage}%`, backgroundImage: `linear-gradient(90deg, ${color} 44.5%, ${color}99 100%)` }}
          aria-label={`${usagePercentage}% of ${plan.apis[0].name} used`}
        />

        <div className="absolute inset-0 w-[calc(100%-2rem)]">
          <motion.div
            initial={{ opacity: 0, x: 0 }}
            animate={{ opacity: 1, x: `${Math.min(usagePercentage <= 10 ? 10 : usagePercentage, 100)}%` }}
            transition={{ duration: 1, delay: 1 }}
            className="absolute w-full -translate-y-full bottom-3.5"
          >
            <div className="relative -translate-x-1/2 w-fit">
              <img src="/assets/image/plan/persentage_bg.svg" alt="percentage background" className="w-16" />
              <span className="absolute inset-0 flex items-center justify-center text-xs font-bold">{usagePercentage}%</span>
            </div>
          </motion.div>
        </div>
      </div>
    );
  };

  const handleOpenModal = (plan: Plan) => {
    setSelectedPlan(plan);
    setIsModalOpen(true);
  };

  return (
    <div className="flex justify-between gap-4 w-full *:min-w-[18.75rem] overflow-x-auto">
      {services.map((service, index) => {
        const plan = (data && data.find((item) => item.plan.apis[0].name === service.name)?.plan) || {
          name: "No Plan",
          is_free_plan: false,
          label: "no_plan",
          main_price: 0,
          remaining_price: 0,
          apis: [{ name: service.name, total_requests: 0, remaining_requests: 0, remaining_requests_pre: 0 }],
        };

        const IconComponent = service.icon;

        return (
          <div key={index} className="flex flex-col w-full gap-2">
            <div className="flex flex-col gap-10 p-3 overflow-hidden rounded-md" style={{ backgroundColor: plan.label === "no_plan" ? "#EDECEE" : `${service.color}1a` }}>
              <div className="relative flex flex-col w-full gap-4 ps-3">
                <span className="absolute inset-0 w-1 h-full rounded-lg" style={{ backgroundColor: service.color }} />

                <div className="flex flex-col gap-2">
                  <div className="flex items-center gap-2">
                    <div className="*:size-5" style={{ color: service.color }}>
                      <IconComponent />
                    </div>
                    <div className="flex items-center justify-between w-full">
                      <h4 className="text-base font-bold capitalize text-closed-shutter-300 line-clamp-1">{service.label}</h4>
                      {plan.is_free_plan ? (
                        <div
                          className="w-[3.25rem] min-h-[1.625rem] flex justify-center p-1 gap-1 items-center text-closed-shutter-300 hover:text-gorgonzola-blue-300 cursor-pointer duration-600 bg-white/70 rounded-full"
                          onClick={() => handleOpenModal(plan)}
                        >
                          <span className="text-2xs ">Free</span>
                          <InfoCircle className="size-4" variant="Broken" />
                        </div>
                      ) : null}

                      {plan.label === "no_plan" ? (
                        <div className="w-[3.25rem] min-h-[1.625rem] flex justify-center p-1 gap-1 items-center bg-white/70 rounded-full">
                          <span className="text-2xs ">No Plan</span>
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="flex items-center justify-between text-base font-bold">
                    <div className="flex items-center gap-1">
                      <span style={{ color: service.color }}>{plan.apis[0].total_requests}</span>
                      <span style={{ color: service.color }}>/ {plan.apis[0].remaining_requests}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex flex-col gap-4">
                {renderProgressBar(plan, service.color)}

                <span className=" text-closed-shutter-300 text-2xs">
                  Dear <span className="capitalize">{isAdmin ? (userDetail?.first_name ? " " + userDetail?.first_name : null) : userInfo?.first_name ? " " + userInfo?.first_name : null}</span>,{" "}
                  {plan.apis[0].total_requests - plan.apis[0].remaining_requests} requests have been used so far
                </span>
              </div>
            </div>
          </div>
        );
      })}

      {selectedPlan && <FreePlanModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} freeRequests={selectedPlan.apis[0].total_requests} />}
    </div>
  );
};

export default PlanUsage;
