import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import UserDetails from "./UserDetails";
import { AuthContext } from "../../../contexts/AuthContext";
import { useUsersList } from "../../../contexts/UsersListContext";
import { getUserActivePlans, getUserPlanChart, getUserPlans } from "../../../utilities/api/adminService";
import Loader from "../../loading/Loader";
import PlanUsage from "../../plan/PlanUsage";
import PlanChart from "../../plan/PlanChart";
import CalendarSelector from "../../plan/CalendarSelector";
import { adminRoutes } from "../../../utilities/defaults/routes/adminRoutes";
import { ArrowRight2 } from "iconsax-react";
import { useTimestamp } from "../../../hooks/useTimestamp";
import UserPlansTable from "./UserPlansTable";
import CustomPagination from "../../table/CustomPagination";

const UserPlans: React.FC = () => {
  const { userId } = useParams<{ userId: string }>();
  const { getTimestamp } = useTimestamp();
  const { accessToken } = useContext(AuthContext);
  const [planHistory, setPlanHistory] = useState<any[]>([]);
  const [activePlans, setActivePlans] = useState<any[]>([]);
  const [planChartData, setPlanChartData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [selectedLabelId, setSelectedLabelId] = useState<number | null>(null);
  const [error, setError] = useState<string | null>(null);
  const hasPreviousPage = currentPage > 1;
  const hasNextPage = currentPage < totalPages;
  const { userDetail, fetchUserDetail } = useUsersList();
  const navigate = useNavigate();

  useEffect(() => {
    if (userId) {
      fetchUserDetail(userId);
    }
  }, [userId]);

  useEffect(() => {
    const fetchUserPlansData = async () => {
      if (!accessToken || !userId) return;

      setLoading(true);

      try {
        const fromDate = getTimestamp(selectedDate);
        const toDate = getTimestamp(selectedDate, true);
        const dateRange = { from_date: fromDate.toString(), to_date: toDate.toString() };
        const historyResponse = await getUserPlans(accessToken, userId, currentPage);
        const activeResponse = await getUserActivePlans(accessToken, userId);
        const chartResponse = await getUserPlanChart(accessToken, userId, dateRange, "hourly", selectedLabelId, Intl.DateTimeFormat().resolvedOptions().timeZone);

        if (historyResponse.ok && activeResponse.ok && chartResponse.ok) {
          setPlanHistory(historyResponse.data.data);
          setActivePlans(activeResponse.data);
          setPlanChartData(chartResponse.data);
          setTotalPages(historyResponse.data.last_page);
          setError(null);
        } else {
          setError("Failed to load user plans data.");
        }
      } catch (err) {
        console.error("Failed to fetch user plans data:", err);
        setError("An error occurred while fetching user plans data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchUserPlansData();
  }, [accessToken, userId, currentPage, selectedDate, selectedLabelId]);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleDateChange = (date: Date) => {
    setSelectedDate(date);
  };

  const handleLabelChange = (labelId: number | null) => {
    setSelectedLabelId(labelId);
  };

  const handleRowClick = (plan: any) => {
    navigate(`/admin/users/${plan.user_id}/payments`);
  };

  return (
    <div className="flex flex-col divide-y divide-gorgonzola-blue-50">
      <div className="flex flex-col w-full p-4 md:p-6">
        <div className="flex items-center gap-1">
          <Link to={adminRoutes.users.list.path} className="text-xs transition-colors w-fit text-closed-shutter-300/70 hover:text-gorgonzola-blue-300 duration-600">
            Users List
          </Link>
          <ArrowRight2 className="text-closed-shutter-300/70 size-4" />
          <Link to={`/admin/users/${userId}`} className="font-bold transition-colors text-closed-shutter-300 hover:text-gorgonzola-blue-300 duration-600">
            #{userId}
          </Link>
        </div>
        <span className="font-bold text-closed-shutter-300">Service</span>
      </div>

      {loading || !userId ? (
        <div className="flex items-center justify-center p-6">
          <Loader />
        </div>
      ) : !userDetail ? (
        <div className="flex justify-center w-full p-4 md:p-6">
          {/* <span className="capitalize text-english-vermillion-300">No user details found</span> */}
          <span className="capitalize text-closed-shutter-300">No user details found</span>
        </div>
      ) : (
        <div className="flex flex-col justify-between w-full gap-8 p-4 md:p-6">
          <UserDetails userId={userId} />

          <div className="flex flex-col justify-between w-full gap-4">
            <PlanUsage data={activePlans} userDetail={userDetail} />

            <div className="flex flex-col flex-grow w-full gap-4 p-4 border rounded-md border-gorgonzola-blue-50">
              <div className="flex flex-col items-center justify-between gap-4 md:gap-0 md:flex-row">
                <h3 className="font-bold text-closed-shutter-300">Plan & User Report</h3>
                <CalendarSelector selectedDate={selectedDate} onDateChange={handleDateChange} />
              </div>

              {loading ? (
                <div className="flex items-center justify-center p-4 md:p-6">
                  <Loader />
                </div>
              ) : (
                <>
                  {error ? (
                    // <div className="text-english-vermillion-300">{error}</div>
                    <div className="text-closed-shutter-300">Error: {error}</div>
                  ) : (
                    <PlanChart data={planChartData} selectedDate={selectedDate} selectedLabelId={selectedLabelId} onLabelChange={handleLabelChange} />
                  )}
                </>
              )}
            </div>
          </div>

          <div className="w-full border rounded-md border-gorgonzola-blue-50">
            <div className="flex flex-col divide-y divide-gorgonzola-blue-50">
              <div className="flex items-center justify-between w-full px-4 py-6">
                <h2 className="font-bold text-closed-shutter-300">User plan purchase history</h2>
              </div>
              {loading ? (
                <div className="flex justify-center w-full p-4 md:p-6">
                  <Loader />
                </div>
              ) : error ? (
                // <div className="p-6 text-center text-english-vermillion-300">{error}</div>
                <div className="p-6 text-center text-closed-shutter-300">Error: {error}</div>
              ) : (
                <>
                  <UserPlansTable plansData={planHistory} onRowClick={handleRowClick} />
                  <CustomPagination currentPage={currentPage} totalPages={totalPages} hasPreviousPage={hasPreviousPage} hasNextPage={hasNextPage} onPageChange={handlePageChange} />
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserPlans;
