import { useState, useEffect } from "react";
import { useBodyMeasurementServiceContext } from "../../../../contexts/BodyMeasurementServiceContext";
import BodyAnalysisVisualizer from "./BodyAnalysisVisualizer";
import UnitToggle from "../../../shared/UnitToggle";

interface SizeGuideResultProps {
  results: any;
  gender: "men" | "women" | "genderfree";
}

const SizeGuideResult: React.FC<SizeGuideResultProps> = ({ results, gender }) => {
  const { selectedUnit, handleUnitChange } = useBodyMeasurementServiceContext();
  const [recommendations, setRecommendations] = useState<Record<string, string>>({});
  const [measurements, setMeasurements] = useState<Record<string, Record<string, { Difference: string; Message: string }>>>({});
  const [selectedSize, setSelectedSize] = useState<string>("");
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const formatMeasurement = (value: string) => {
    const numValue = parseFloat(value);
    if (selectedUnit === "inch") {
      const inchValue = numValue * 0.393701;
      return {
        value: inchValue.toFixed(1),
        unit: "in",
      };
    }
    return {
      value: value,
      unit: "cm",
    };
  };

  useEffect(() => {
    try {
      let recs = {};
      let meas = {};

      if (Array.isArray(results) && results.length >= 2) {
        [recs, meas] = results;
      } else if (Array.isArray(results) && results.length > 0 && Array.isArray(results[0]) && results[0].length >= 2) {
        [recs, meas] = results[0];
      } else if (results && results.data && Array.isArray(results.data)) {
        if (results.data.length > 0 && Array.isArray(results.data[0]) && results.data[0].length >= 2) {
          [recs, meas] = results.data[0];
        } else if (results.data.length >= 2) {
          [recs, meas] = results.data;
        }
      }

      if (!recs || Object.keys(recs).length === 0) {
        console.error("No valid recommendation data found");
        return;
      }

      setRecommendations(recs);
      setMeasurements(meas);

      const sizes = Object.entries(recs);
      const recommendedSize = sizes.find(([_, status]) => status === "Recommended" || status === "recommended")?.[0];

      setSelectedSize(recommendedSize || sizes[0]?.[0] || "");
      setIsDataLoaded(true);
    } catch (error) {
      console.error("Error processing size guide data:", error);
    }
  }, [results]);

  if (!isDataLoaded || Object.keys(recommendations).length === 0) {
    return <div className="p-4 text-center">Loading size recommendations...</div>;
  }

  const prepareAnalysisData = (measurementsData: any) => {
    if (!measurementsData || !measurementsData[selectedSize]) {
      return {};
    }

    const result: Record<string, string> = {};

    Object.entries(measurementsData[selectedSize]).forEach(([part, data]: [string, any]) => {
      if (data && typeof data === "object" && "Message" in data) {
        result[part] = data.Message;
      }
    });

    return result;
  };

  const analysisData = prepareAnalysisData(measurements);

  return (
    <div className="flex flex-col items-center gap-4 md:gap-6 lg:gap-8">
      {/* Header */}
      <div className="p-2 text-center bg-white md:p-6 rounded-xl">
        <h2 className="text-lg font-semibold text-closed-shutter-300">Size Recommendation</h2>
        <p className="mt-2 text-xs text-closed-shutter-200">Based on your measurements</p>
      </div>

      <div className="flex flex-col gap-4 md:gap-6 lg:gap-8 lg:flex-row max-w-[50rem]">
        <div className="flex flex-col justify-center w-full gap-4 md:gap-6 lg:gap-8">
          {/* Size Buttons */}
          <div className="flex flex-wrap justify-center gap-2">
            {Object.entries(recommendations).map(([size, status]) => (
              <button
                key={size}
                onClick={() => setSelectedSize(size)}
                className={`px-4 py-2 rounded-lg transition-all ${selectedSize === size ? "bg-gorgonzola-blue-300 text-white" : "bg-gorgonzola-blue-50 text-closed-shutter-300"}`}
              >
                <div className="flex flex-col items-center">
                  <span>Size {size}</span>
                  {/* <span className={`text-xs ${selectedSize === size ? "text-white/90" : status === "Recommended" ? "text-emerald-500 font-medium" : "text-amber-500 font-medium"}`}>{status}</span> */}
                  <span className={`text-xs ${selectedSize === size ? "text-white/90" : "text-closed-shutter-200"}`}>{status}</span>
                </div>
              </button>
            ))}
          </div>

          <div className="flex flex-col w-full md:gap-6 md:flex-row">
            {/* Measurements Analysis */}
            <div className="flex flex-col flex-1 gap-6">
              <div className="flex justify-center">
                <UnitToggle selectedUnit={selectedUnit} onUnitChange={handleUnitChange} />
              </div>
              <div className="grid grid-cols-1 gap-4 min-w-64">
                {measurements[selectedSize] &&
                  Object.entries(measurements[selectedSize]).map(([part, data]) => {
                    const difference = parseFloat(data.Difference);
                    const formattedDiff = formatMeasurement(data.Difference);
                    return (
                      <div key={part} className="flex flex-col gap-1.5 p-3 rounded-lg border bg-gorgonzola-blue-50 border-gorgonzola-blue-100">
                        <span className="text-base text-closed-shutter-300">{part}</span>
                        <div className="flex items-center justify-between">
                          {/* <span className={`text-xs ${Math.abs(difference) <= 2 ? "text-emerald-500" : Math.abs(difference) <= 5 ? "text-amber-500" : "text-english-vermillion-400"}`}> */}
                          <span className={`text-xs text-closed-shutter-200`}>{data.Message}</span>
                          <span className="text-xs text-closed-shutter-200">
                            {difference > 0 ? "+" : ""}
                            {formattedDiff.value}
                            {formattedDiff.unit}
                          </span>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>

            <BodyAnalysisVisualizer analysis={analysisData} selectedSize={selectedSize} gender={gender} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SizeGuideResult;
