import React, { useState, useEffect, useMemo } from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartData, ChartOptions } from "chart.js";
import Select, { components } from "react-select";
import { ArrowDown2, ArrowRight2 } from "iconsax-react";
import Loader from "../loading/Loader";
import { useTimestamp } from "../../hooks/useTimestamp";
import useDevice from "../../hooks/useDevice";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface FinanceSummaryChartProps {
  data: {
    chart: {
      action: string;
      amount: string;
      service: string | null;
      date: number;
    }[];
  };
  userCreatedAt: number;
}

interface MonthOption {
  value: string;
  label: string;
}

const FinanceSummaryChart: React.FC<FinanceSummaryChartProps> = ({ data, userCreatedAt }) => {
  const { getTimestamp } = useTimestamp();
  const [chartData, setChartData] = useState<ChartData<"bar"> | null>(null);
  const [selectedDate, setSelectedDate] = useState<MonthOption | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const { isMobile } = useDevice();

  const dateOptions = useMemo(() => {
    const options: MonthOption[] = [];
    const creationDate = new Date(userCreatedAt * 1000);
    const currentDate = new Date();

    creationDate.setDate(1);
    creationDate.setHours(0, 0, 0, 0);

    currentDate.setDate(1);
    currentDate.setHours(0, 0, 0, 0);

    while (creationDate <= currentDate) {
      const value = `${creationDate.getFullYear()}-${String(creationDate.getMonth() + 1).padStart(2, "0")}`;
      const label = new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "long",
      }).format(creationDate);

      options.unshift({ value, label });
      creationDate.setMonth(creationDate.getMonth() + 1);
    }

    return options;
  }, [userCreatedAt]);

  useEffect(() => {
    if (dateOptions.length > 0 && !selectedDate) {
      setSelectedDate(dateOptions[0]);
    }
  }, [dateOptions, selectedDate]);

  useEffect(() => {
    const processChartData = async () => {
      if (!selectedDate || !data || !data.chart) {
        setChartData(null);
        setIsLoading(false);
        return;
      }

      setIsLoading(true);

      const [year, month] = selectedDate.value.split("-");
      const lastDay = new Date(parseInt(year), parseInt(month), 0).getDate();

      const fromDate = getTimestamp(new Date(`${year}-${month}-01`));
      const toDate = getTimestamp(new Date(`${year}-${month}-${lastDay}`));

      const filteredData = data.chart.filter((item) => {
        const itemDate = new Date(item.date * 1000);
        return itemDate >= new Date(fromDate * 1000) && itemDate <= new Date(toDate * 1000);
      });

      const days = Array.from({ length: lastDay }, (_, i) => i + 1);
      const monthName = new Date(parseInt(year), parseInt(month) - 1).toLocaleString("default", { month: "short" });

      const labels = days.map((day) => `${day} ${monthName}`);

      const datasets = [
        {
          label: "Payment",
          data: days.map((day) => {
            const dayData = filteredData.filter((item) => new Date(item.date * 1000).getDate() === day && item.action === "deposit" && item.service === null);
            return dayData.reduce((sum, item) => sum + parseFloat(item.amount), 0);
          }),
          backgroundColor: "rgba(94, 114, 244, 1)",
          barThickness: 10,
          borderRadius: 20,
        },
        {
          label: "Body Measurement",
          data: days.map((day) => {
            const dayData = filteredData.filter((item) => new Date(item.date * 1000).getDate() === day && item.action === "withdraw" && item.service === "Body Measurement");
            return dayData.reduce((sum, item) => sum + parseFloat(item.amount), 0);
          }),
          backgroundColor: "#F7BA1E",
          barThickness: 10,
          borderRadius: 20,
        },
        {
          label: "Auto Tagging",
          data: days.map((day) => {
            const dayData = filteredData.filter((item) => new Date(item.date * 1000).getDate() === day && item.action === "withdraw" && item.service === "Auto Tagging");
            return dayData.reduce((sum, item) => sum + parseFloat(item.amount), 0);
          }),
          backgroundColor: "#3491FA",
          barThickness: 10,
          borderRadius: 20,
        },
        {
          label: "Visual Clothes Search",
          data: days.map((day) => {
            const dayData = filteredData.filter((item) => new Date(item.date * 1000).getDate() === day && item.action === "withdraw" && item.service === "Visual Clothes Search");
            return dayData.reduce((sum, item) => sum + parseFloat(item.amount), 0);
          }),
          backgroundColor: "#14C9C9",
          barThickness: 10,
          borderRadius: 20,
        },
      ];

      setChartData({ labels, datasets });
      setIsLoading(false);
    };

    processChartData();
  }, [selectedDate, data]);

  const maxY = useMemo(() => {
    if (!chartData || chartData.datasets.length === 0) return 40;
    const allValues = chartData.datasets.flatMap((dataset) => dataset.data as number[]);
    return Math.max(40, Math.ceil(Math.max(...allValues) / 10 + 5) * 10);
  }, [chartData]);

  const options: ChartOptions<"bar"> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        mode: "index",
        intersect: false,
        backgroundColor: "#fff",
        titleColor: "#484554c2",
        titleFont: {
          size: 15,
          weight: "bold",
        },
        titleAlign: "center",
        bodyColor: "#23262F",
        bodyFont: {
          size: 15,
          weight: "bolder",
        },
        bodyAlign: "left",
        bodySpacing: 8,
        borderColor: "#C6C7F0",
        borderWidth: 1,
        cornerRadius: 16,
        padding: 12,
        usePointStyle: true,
        boxPadding: 8,
        callbacks: {
          label: function (context) {
            const label = context.dataset.label || "";
            const value = context.parsed.y;
            return `${label}: $${value.toFixed(2)}`;
          },
          labelTextColor: function (context) {
            return context.dataset.backgroundColor as string;
          },
          title: (tooltipItems) => {
            if (tooltipItems.length > 0) {
              return tooltipItems[0].label;
            }
            return "";
          },
        },
      },
    },
    scales: {
      x: {
        stacked: false,
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: false,
          maxRotation: 0,
          callback: function (value, index, values) {
            const day = index + 1;
            return day === 1 || day % (isMobile ? 10 : 5) === 0 ? this.getLabelForValue(value as number) : "";
          },
        },
      },
      y: {
        stacked: false,
        beginAtZero: true,
        grid: {
          display: true,
          color: "#EDEDFA",
          lineWidth: 1,
          drawTicks: false,
          drawOnChartArea: true,
        },
        min: 0,
        max: maxY,
        ticks: {
          callback: function (value) {
            return "$" + value;
          },
          stepSize: 10,
          padding: 10,
        },
        border: {
          display: false,
        },
      },
    },
    interaction: {
      mode: "nearest",
      axis: "x",
      intersect: false,
    },
  };

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      padding: "0.25rem 0.5rem",
      border: "1px solid #EDEDFA",
      borderRadius: "0.375rem",
      boxShadow: "none",
      transition: "0.6s linear",
      "&:hover": {
        border: "1px solid #C6C7F0",
      },
      cursor: "pointer",
    }),
    option: (provided: any, state: { isSelected: any; isFocused: any }) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#EDEDFA" : "white",
      padding: "0.5rem",
      color: "#484554c2",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#EDEDFA",
      },
      borderBottom: "1px solid #C6C7F0",
      "&:last-child": {
        borderBottom: "none",
      },
    }),
    menu: (provided: any) => ({
      ...provided,
      overflow: "hidden",
      borderRadius: "0.375rem",
      boxShadow: "none",
      border: "1px solid #C6C7F0",
      "&:hover": {
        borderColor: "#C6C7F0",
      },
    }),
    menuList: (provided: any) => ({
      ...provided,
      maxHeight: "15rem",
      padding: 0,
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        margin: "0.5rem 0",
        width: "0.75rem",
      },
      "&::-webkit-scrollbar-track": {
        background: "#EDEDFA",
      },
      "&::-webkit-scrollbar-thumb": {
        position: "relative",
        background: "#FFFFFF",
        border: "1px solid #E3E4F8",
        borderRadius: "4px",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: "#FFFFFF",
      },
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: "#484554c2",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      color: "#A0AEC0",
    }),
  };

  const DropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        {isOpen ? <ArrowDown2 variant="Broken" className="size-5 text-closed-shutter-300" /> : <ArrowRight2 variant="Broken" className="size-5 text-closed-shutter-300" />}
      </components.DropdownIndicator>
    );
  };

  return (
    <div className="flex flex-col justify-between flex-grow w-full min-w-0 gap-3 p-4 border rounded-md border-gorgonzola-blue-50 xl:flex-1">
      <div className="flex flex-col items-center justify-between w-full gap-2 mb-4 md:gap-0 md:flex-row">
        <h3 className="text-base font-bold text-closed-shutter-300">Financial Overview</h3>
        <div className="select-none">
          <Select<MonthOption>
            value={selectedDate}
            onChange={(newValue) => setSelectedDate(newValue)}
            options={dateOptions}
            styles={customStyles}
            components={{
              IndicatorSeparator: () => null,
              DropdownIndicator,
            }}
            onMenuOpen={() => setIsOpen(true)}
            onMenuClose={() => setIsOpen(false)}
            isSearchable={false}
          />
        </div>
      </div>

      <div className="flex mb-2 space-x-6 *:min-w-fit overflow-x-auto">
        <div className="flex items-center">
          <div className="w-3 h-3 mr-2 rounded-full bg-gorgonzola-blue-300"></div>
          <span className="text-xs text-gray-600 capitalize">Payment</span>
        </div>
        <span className="w-px h-10 bg-gorgonzola-blue-50" />
        <div className="flex items-center">
          <div className="w-3 h-3 mr-2 rounded-full bg-[#F7BA1E]"></div>
          <span className="text-xs text-gray-600 capitalize">Body Measurement</span>
        </div>
        <div className="flex items-center">
          <div className="w-3 h-3 mr-2 rounded-full bg-[#3491FA]"></div>
          <span className="text-xs text-gray-600 capitalize">Auto Tagging</span>
        </div>
        <div className="flex items-center">
          <div className="w-3 h-3 mr-2 rounded-full bg-[#14C9C9]"></div>
          <span className="text-xs text-gray-600 capitalize">Visual clothes search</span>
        </div>
      </div>

      {isLoading ? (
        <div className="flex items-center justify-center w-full">
          <Loader />
        </div>
      ) : chartData && chartData.datasets[0].data.length > 0 ? (
        <div className="w-full h-[16.75rem]">
          <Bar options={options} data={chartData} />
        </div>
      ) : (
        <div className="flex items-center justify-center w-full">
          <p className="text-closed-shutter-300">No data available for the selected period</p>
        </div>
      )}
    </div>
  );
};

export default FinanceSummaryChart;
