import React, { useState } from "react";
import Table from "../../../table/Table";
import StatusBadge from "../../../table/StatusBadge";
import MobilePaymentCard from "./MobilePaymentCard";
import { TableColumn } from "../../../../utilities/types/support/types";
import PaymentDetailsModal from "./PaymentDetailsModal";
import MainButton from "../../../button/MainButton";
import { Eye } from "iconsax-react";
import { TransformTime } from "../../../../utilities/helper/TransformPublishedAt";

interface PaymentsAnalyticsTableProps {
  paymentsData: any[];
}

const PaymentsAnalyticsTable: React.FC<PaymentsAnalyticsTableProps> = ({ paymentsData }) => {
  const [selectedPayment, setSelectedPayment] = useState<any>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const paymentsColumns: TableColumn<any>[] = [
    {
      key: "id",
      header: "Invoice",
      render: (value) => <span className="text-xs font-medium">#{value ? value : "-"}</span>,
    },
    {
      key: "user",
      header: "Email",
      render: (value, item) => <span className="text-xs">{item.user.email ? item.user.email : "-"}</span>,
    },
    {
      key: "pay_operator",
      header: "P.Method",
      render: (value, item) => {
        return (
          <div className="flex items-center gap-2">
            <img src={item.pay_operator_logo} alt={value} className="w-8 h-6" />
            {item.last4_card_number ? <span>•••• {item.last4_card_number}</span> : null}
          </div>
        );
      },
    },
    {
      key: "amount",
      header: "Amount",
      render: (value) => <span className="text-xs">${value ? value : "-"}</span>,
    },
    {
      key: "status",
      header: "Status",
      render: (value) => (
        <div className="flex w-full">
          <StatusBadge statusText={value.toLowerCase()} />
        </div>
      ),
    },
    {
      key: "created_at",
      header: "Date",
      render: (value) => <span className="text-xs">{value ? new Date(value * 1000).toLocaleDateString() : "-"}</span>,
    },
    {
      key: "time",
      header: "Time",
      render: (value, item) => <span className="text-xs">{item.created_at ? TransformTime(new Date(item.created_at * 1000).toISOString()) : "-"}</span>,
    },
    {
      key: "id",
      header: "Details",
      render: (value, row) => (
        <MainButton
          type="ghostOutlined"
          onClick={() => {
            setSelectedPayment(row);
            setIsModalOpen(true);
          }}
        >
          <Eye variant="Broken" className="mr-2 size-4" />
          See
        </MainButton>
      ),
    },
  ];

  return (
    <>
      <div className="block xl:hidden odd:*:bg-gorgonzola-blue-50/30 even:*:bg-white">
        {paymentsData.map((payment) => (
          <MobilePaymentCard key={payment.id} payment={payment} />
        ))}
      </div>

      <div className="hidden w-full xl:block">
        <Table data={paymentsData} columns={paymentsColumns} />
      </div>

      <PaymentDetailsModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} paymentId={selectedPayment?.id} />
    </>
  );
};

export default PaymentsAnalyticsTable;
