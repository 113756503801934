import React, { useState, useEffect, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import { getMyApisChart, getActivedOrders } from "../../utilities/api/planService";
import CalendarSelector from "./CalendarSelector";
import PlanChart from "./PlanChart";
import { useTimestamp } from "../../hooks/useTimestamp";
import PlanUsage from "./PlanUsage";
import { PlanUsageData } from "../../utilities/types/plan/types";
import ShakuPlans from "../shaku-plans/ShakuPlans";
import Loader from "../loading/Loader";
import PaymentStatusModal from "./PaymentStatusModal";

const Plans: React.FC = () => {
  const { getTimestamp } = useTimestamp();
  const { accessToken } = useContext(AuthContext);
  const [searchParams] = useSearchParams();
  const [chartData, setChartData] = useState<any>(null);
  const [activedOrders, setActivedOrders] = useState<PlanUsageData[] | null>(null);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [selectedLabelId, setSelectedLabelId] = useState<number | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState<string | null>(null);

  useEffect(() => {
    if (accessToken) {
      fetchChartData();
      fetchActivedOrders();
    }
  }, [accessToken, selectedDate, selectedLabelId]);

  useEffect(() => {
    const status = searchParams.get("status");
    if (status) {
      setPaymentStatus(status);
      setIsPaymentModalOpen(true);
    }
  }, [searchParams]);

  const fetchChartData = async () => {
    setIsLoading(true);
    try {
      setError(null);
      const fromDate = getTimestamp(selectedDate);
      const toDate = getTimestamp(selectedDate, true);
      const response = await getMyApisChart(accessToken!, {
        from_date: fromDate.toString(),
        to_date: toDate.toString(),
        point: "hourly",
        label_id: selectedLabelId || undefined,
        client_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      });
      setChartData(response.data);
    } catch (error) {
      console.error("Error fetching chart data:", error);
      setError("Failed to fetch chart data. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchActivedOrders = async () => {
    setIsLoading(true);
    try {
      const response = await getActivedOrders(accessToken!);
      if (response.ok) {
        setActivedOrders(response.data);
      } else {
        console.error("Error fetching actived orders:", response.message);
        setError("Failed to fetch active orders. Please try again later.");
      }
    } catch (error) {
      console.error("Error fetching actived orders:", error);
      setError("Failed to fetch active orders. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleDateChange = (date: Date) => {
    setSelectedDate(date);
  };

  const handleLabelChange = (labelId: number | null) => {
    setSelectedLabelId(labelId);
  };

  const handleClosePaymentModal = () => {
    setIsPaymentModalOpen(false);
    setPaymentStatus(null);
  };

  return (
    <div className="flex flex-col divide-y divide-gorgonzola-blue-50">
      <div className="w-full p-4 md:p-6">
        <h2 className="font-bold text-closed-shutter-300">Subscription Details</h2>
      </div>
      <div className="flex flex-col w-full gap-4 p-4 md:p-6">
        <div className="flex flex-col justify-between w-full gap-4">
          <div className="w-full p-4 border rounded-md min-h-max border-gorgonzola-blue-50 custom_scroll">
            <div className="flex flex-col gap-6 w-full md:min-w-[21.75rem]">
              <h2 className="font-bold text-closed-shutter-300">Plans Usage</h2>
              {isLoading ? (
                <div className="flex items-center justify-center p-4 md:p-6">
                  <Loader />
                </div>
              ) : (
                <PlanUsage data={activedOrders || []} />
              )}
            </div>
          </div>

          <div className="flex flex-col flex-grow w-full gap-4 p-4 border rounded-md border-gorgonzola-blue-50">
            <div className="flex flex-col items-center justify-between gap-4 md:gap-0 md:flex-row">
              <h3 className="font-bold text-closed-shutter-300">Plan & User Report</h3>
              <CalendarSelector selectedDate={selectedDate} onDateChange={handleDateChange} />
            </div>

            {isLoading ? (
              <div className="flex items-center justify-center p-4 md:p-6">
                <Loader />
              </div>
            ) : (
              <>
                {error ? (
                  // <div className="text-english-vermillion-300">{error}</div>
                  <div className="text-closed-shutter-300">Error: {error}</div>
                ) : (
                  <PlanChart data={chartData} selectedDate={selectedDate} selectedLabelId={selectedLabelId} onLabelChange={handleLabelChange} />
                )}
              </>
            )}
          </div>
        </div>

        <div className="flex flex-col border border-gorgonzola-blue-50 divide-y divide-gorgonzola-blue-50 rounded-md w-full md:min-w-[38.75rem] *:w-full *:p-4 md:*:p-6">
          <div>
            <h2 className="font-bold text-closed-shutter-300">Plans</h2>
          </div>
          <div>
            <ShakuPlans />
          </div>
        </div>
      </div>

      <PaymentStatusModal isOpen={isPaymentModalOpen} onClose={handleClosePaymentModal} status={paymentStatus || ""} />
    </div>
  );
};

export default Plans;
