import React, { useEffect, useState } from "react";
import { useMainAdmin } from "../../../contexts/MainAdminContext";
import Loader from "../../loading/Loader";
import AdminDashboardTable from "./AdminDashboardTable";
import StatusBadge from "../../table/StatusBadge";
import { Link, useNavigate } from "react-router-dom";
import MainButton from "../../button/MainButton";
import { Eye } from "iconsax-react";
import ServiceDetailsModal from "../analytics/UsageAnalytics/ServiceDetailsModal";
import UsagePercentageChart from "./UsagePercentageChart";
import SalePlanPercentageChart from "./SalePlanPercentageChart";
import SalePlanSumAmountChart from "./SalePlanSumAmountChart";
import PaymentDetailsModal from "../analytics/PaymentsAnalytics/PaymentDetailsModal";

const AdminDashboard: React.FC = () => {
  const {
    mainAdminList,
    mainAdminUsagePercentage,
    mainAdminSalePlanPercentage,
    mainAdminSalePlanSumAmount,
    loading,
    fetchMainAdminList,
    fetchMainAdminUsagePercentage,
    fetchMainAdminSalePlanPercentage,
    fetchMainAdminSalePlanSumAmount,
  } = useMainAdmin();

  useEffect(() => {
    fetchMainAdminList();
    fetchMainAdminUsagePercentage();
    fetchMainAdminSalePlanPercentage();
    fetchMainAdminSalePlanSumAmount();
  }, [fetchMainAdminList, fetchMainAdminUsagePercentage, fetchMainAdminSalePlanPercentage, fetchMainAdminSalePlanSumAmount]);

  const [selectedService, setSelectedService] = useState<any | null>(null);
  const [selectedPayment, setSelectedPayment] = useState<any | null>(null);
  const [isServiceModalOpen, setIsServiceModalOpen] = useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleViewDetails = (item: any) => {
    setSelectedService(item);
    setIsServiceModalOpen(true);
  };

  const handleViewPaymentDetails = (payment: any) => {
    setSelectedPayment(payment);
    setIsPaymentModalOpen(true);
  };

  const handleCloseServiceModal = () => {
    setIsServiceModalOpen(false);
    setSelectedService(null);
  };

  const handleClosePaymentModal = () => {
    setIsPaymentModalOpen(false);
    setSelectedPayment(null);
  };

  if (loading) {
    return (
      <div className="flex justify-center w-full p-4 md:p-6">
        <Loader />
      </div>
    );
  }

  const ticketColumns = [
    {
      key: "title",
      header: "Title",
    },
    {
      key: "created_at",
      header: "Date",
      render: (value: any) => <span className="text-xs">{new Date(value * 1000).toLocaleDateString()}</span>,
    },
    {
      key: "id",
      header: "Show Answer",
      render: (value: any) => (
        <Link to={`/admin/tickets/${value}`}>
          <MainButton type="ghostOutlined">
            <Eye variant="Broken" className="mr-2 size-4" />
            Answer
          </MainButton>
        </Link>
      ),
    },
  ];

  const usageColumns = [
    {
      key: "service_name",
      header: "Service Name",
      render: (value: any) => <span className="text-xs">{value}</span>,
    },
    {
      key: "status",
      header: "Status",
      render: (value: any) => (
        <div className="flex w-full">
          <StatusBadge statusText={value.toLowerCase()} />
        </div>
      ),
    },
    {
      key: "created_at",
      header: "Date",
      render: (value: any) => <span className="text-xs">{value ? new Date(value * 1000).toLocaleDateString() : "-"}</span>,
    },
    {
      key: "id",
      header: "Result",
      render: (value: any, item: any) => (
        <div className="flex justify-center w-full">
          <MainButton type="ghostOutlined" onClick={() => handleViewDetails(item)} disabled={item.status !== "success"}>
            <Eye variant="Broken" className="mr-2 size-4" />
            See
          </MainButton>
        </div>
      ),
    },
  ];

  const userColumns = [
    {
      key: "email",
      header: "Email",
      render: (value: any) => <span className="text-xs *:line-clamp-1">{value}</span>,
    },
    {
      key: "role_info",
      header: "Type user",
      render: (value: any) => (
        <div className="flex w-full">
          <StatusBadge statusText={value.name.toLowerCase()} />
        </div>
      ),
    },
    {
      key: "plan_status",
      header: "Plan",
      render: (value: any) => (
        <div className="flex w-full">
          <StatusBadge statusText={value.toLowerCase()} />
        </div>
      ),
    },
  ];

  const paymentColumns = [
    {
      key: "user",
      header: "Email",
      render: (value: any, item: any) => <span className="text-xs">{item.user.email}</span>,
    },
    {
      key: "amount",
      header: "Amount",
      render: (value: any) => <span className="text-xs">${value}</span>,
    },
    {
      key: "status",
      header: "Status",
      render: (value: any) => (
        <div className="flex w-full">
          <StatusBadge statusText={value.toLowerCase()} />
        </div>
      ),
    },
    {
      key: "created_at",
      header: "Date",
      render: (value: any) => <span className="text-xs">{value ? new Date(value * 1000).toLocaleDateString() : "-"}</span>,
    },
  ];

  return (
    <div className="flex flex-col gap-6 p-4 md:p-6">
      <div className="grid w-full grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-3">
        {/* Charts */}
        <div className="flex flex-col divide-y divide-gorgonzola-blue-50 w-full border rounded-md border-gorgonzola-blue-50 bg-[#F9F9FA]">
          <div className="p-4 md:p-6">
            <h2 className="font-bold text-closed-shutter-300">Service Usage Insights</h2>
          </div>
          <div className="px-4 py-6">{mainAdminUsagePercentage && <UsagePercentageChart data={mainAdminUsagePercentage} />}</div>
        </div>
        <div className="flex flex-col divide-y divide-gorgonzola-blue-50 w-full border rounded-md border-gorgonzola-blue-50 bg-[#F9F9FA]">
          <div className="p-4 md:p-6">
            <h2 className="font-bold text-closed-shutter-300">Plan Sales Analysis</h2>
          </div>
          <div className="px-4 py-6">{mainAdminSalePlanPercentage && <SalePlanPercentageChart data={mainAdminSalePlanPercentage} />}</div>
        </div>
        <div className="flex flex-col divide-y divide-gorgonzola-blue-50 w-full border rounded-md border-gorgonzola-blue-50 bg-[#F9F9FA]">
          <div className="p-4 md:p-6">
            <h2 className="font-bold text-closed-shutter-300">Total Revenue by Plan</h2>
          </div>
          <div className="px-4 py-6">{mainAdminSalePlanSumAmount && <SalePlanSumAmountChart data={mainAdminSalePlanSumAmount} />}</div>
        </div>
      </div>

      <div className="grid w-full grid-cols-1 gap-6 xxl:grid-cols-2">
        {/* Recent Tickets Overview */}
        <div className="w-full border rounded-md border-gorgonzola-blue-50 bg-[#F9F9FA]">
          <div className="flex flex-col divide-y divide-gorgonzola-blue-50">
            <div className="flex flex-col justify-between w-full gap-2.5 p-4 md:py-6 md:items-center md:flex-row md:gap-0">
              <h2 className="font-bold text-closed-shutter-300">Recent Tickets Overview</h2>
              <Link to={"/admin/tickets"} className="text-xs transition-colors w-fit text-closed-shutter-300/70 hover:text-gorgonzola-blue-300 duration-600">
                See Tickets List
              </Link>
            </div>
            <div className="p-4 md:p-6">
              <AdminDashboardTable data={mainAdminList.tickets} columns={ticketColumns} showHeader={false} />
            </div>
          </div>
        </div>

        {/* Recent Usage Overview */}
        <div className="w-full border rounded-md border-gorgonzola-blue-50 bg-[#F9F9FA]">
          <div className="flex flex-col divide-y divide-gorgonzola-blue-50">
            <div className="flex flex-col justify-between w-full gap-2.5 p-4 md:py-6 md:items-center md:flex-row md:gap-0">
              <h2 className="font-bold text-closed-shutter-300">Recent Usage Overview</h2>
              <Link to={"/admin/analytics/usage"} className="text-xs transition-colors w-fit text-closed-shutter-300/70 hover:text-gorgonzola-blue-300 duration-600">
                See Usage List
              </Link>
            </div>
            <div className="p-4 md:p-6">
              <AdminDashboardTable data={mainAdminList.usages} columns={usageColumns} showHeader={false} />
              {selectedService && <ServiceDetailsModal isOpen={isServiceModalOpen} onClose={handleCloseServiceModal} serviceData={selectedService} serviceName={selectedService.service_name} />}
            </div>
          </div>
        </div>

        {/* Recent User Overview */}
        <div className="w-full border rounded-md border-gorgonzola-blue-50 bg-[#F9F9FA]">
          <div className="flex flex-col divide-y divide-gorgonzola-blue-50">
            <div className="flex flex-col justify-between w-full gap-2.5 p-4 md:py-6 md:items-center md:flex-row md:gap-0">
              <h2 className="font-bold text-closed-shutter-300">Recent User Overview</h2>
              <Link to={"/admin/users"} className="text-xs transition-colors w-fit text-closed-shutter-300/70 hover:text-gorgonzola-blue-300 duration-600">
                See Users List
              </Link>
            </div>
            <div className="p-4 md:p-6">
              <AdminDashboardTable data={mainAdminList.users} columns={userColumns} onRowClick={(user) => navigate(`/admin/users/${user.id}`)} showHeader={false} />
            </div>
          </div>
        </div>

        {/* Recent Payment Overview */}
        <div className="w-full border rounded-md border-gorgonzola-blue-50 bg-[#F9F9FA]">
          <div className="flex flex-col divide-y divide-gorgonzola-blue-50">
            <div className="flex flex-col justify-between w-full gap-2.5 p-4 md:py-6 md:items-center md:flex-row md:gap-0">
              <h2 className="font-bold text-closed-shutter-300">Recent Payment Overview</h2>
              <Link to={"/admin/analytics/payments"} className="text-xs transition-colors w-fit text-closed-shutter-300/70 hover:text-gorgonzola-blue-300 duration-600">
                See Payments List
              </Link>
            </div>
            <div className="p-4 md:p-6">
              <AdminDashboardTable data={mainAdminList.payments} columns={paymentColumns} onRowClick={handleViewPaymentDetails} showHeader={false} />
              {selectedPayment && <PaymentDetailsModal isOpen={isPaymentModalOpen} onClose={handleClosePaymentModal} paymentId={selectedPayment.id} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
