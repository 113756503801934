import React, { useState } from "react";
import { Eye } from "iconsax-react";
import CustomPagination from "../../table/CustomPagination";
import { TableColumn } from "../../../utilities/types/support/types";
import StatusBadge from "../../table/StatusBadge";
import { TransformTime } from "../../../utilities/helper/TransformPublishedAt";
import MainButton from "../../button/MainButton";
import PaymentDetailsModal from "../analytics/PaymentsAnalytics/PaymentDetailsModal";
import Table from "../../table/Table";
import MobilePaymentCard from "./MobilePaymentCard";

interface PaymentsTableProps {
  data: any;
  onPageChange: (newPage: number) => void;
}

const PaymentsTable: React.FC<PaymentsTableProps> = ({ data, onPageChange }) => {
  const [selectedPayment, setSelectedPayment] = useState<any>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const paymentsColumns: TableColumn<any>[] = [
    {
      key: "id",
      header: "Invoice",
      render: (value) => <span className="text-xs font-medium">#{value ? value : "-"}</span>,
    },
    {
      key: "user",
      header: "Email",
      render: (value, item) => <span className="text-xs">{item.user.email ? item.user.email : "-"}</span>,
    },
    {
      key: "pay_operator",
      header: "P.Method",
      render: (value, item) => {
        return (
          <div className="flex items-center gap-2">
            <img src={item.pay_operator_logo} alt={value} className="w-8 h-6" />
            {item.last4_card_number ? <span>•••• {item.last4_card_number}</span> : null}
          </div>
        );
      },
    },
    {
      key: "amount",
      header: "Amount",
      render: (value) => <span className="text-xs">${value ? value : "-"}</span>,
    },
    {
      key: "status",
      header: "Status",
      render: (value) => (
        <div className="flex w-full">
          <StatusBadge statusText={value.toLowerCase()} />
        </div>
      ),
    },
    {
      key: "created_at",
      header: "Date",
      render: (value) => <span className="text-xs">{value ? new Date(value * 1000).toLocaleDateString() : "-"}</span>,
    },
    {
      key: "time",
      header: "Time",
      render: (value, item) => <span className="text-xs">{item.created_at ? TransformTime(new Date(item.created_at * 1000).toISOString()) : "-"}</span>,
    },
    {
      key: "id",
      header: "Details",
      render: (value, row) => (
        <MainButton
          type="ghostOutlined"
          onClick={() => {
            setSelectedPayment(row);
            setIsModalOpen(true);
          }}
        >
          <Eye variant="Broken" className="mr-2 size-4" />
          See
        </MainButton>
      ),
    },
  ];

  return (
    <div className="w-full border rounded-md border-gorgonzola-blue-50">
      <div className="flex flex-col divide-y divide-gorgonzola-blue-50">
        <div className="flex flex-col justify-between w-full gap-2.5 p-4 md:py-6 md:items-center md:flex-row md:gap-0">
          <h2 className="font-bold text-closed-shutter-300">Recent Payment Transactions</h2>
          <span className="text-xs text-closed-shutter-300/70">List of your payments</span>
        </div>

        {data && data.data && data.data.length > 0 ? (
          <>
            {/* Mobile View */}
            <div className="block xl:hidden odd:*:bg-gorgonzola-blue-50/30 even:*:bg-white">
              {data.data.map((payment: any) => (
                <MobilePaymentCard key={payment.id} payment={payment} />
              ))}
            </div>
            {/* Desktop View */}
            <div className="hidden w-full xl:block">
              <Table data={data.data} columns={paymentsColumns} />
            </div>

            {/* Pagination */}
            <CustomPagination
              currentPage={data.current_page}
              totalPages={data.last_page}
              hasPreviousPage={data.current_page > 1}
              hasNextPage={data.current_page < data.last_page}
              onPageChange={onPageChange}
            />
            {/* Payment Details Modal */}
            <PaymentDetailsModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} paymentId={selectedPayment?.id} />
          </>
        ) : (
          <div className="p-6 text-center">
            <p className="text-closed-shutter-300">No payment data available</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentsTable;
