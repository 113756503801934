import React from "react";
import UnitToggle from "../../../shared/UnitToggle";
import { useBodyMeasurementServiceContext } from "../../../../contexts/BodyMeasurementServiceContext";
import { ValidMeasurement } from "../../../../utilities/helper/filterValidMeasurements";

interface MeasurementTableProps {
  measurements: Array<ValidMeasurement>;
}

const MeasurementTable: React.FC<MeasurementTableProps> = ({ measurements }) => {
  const { selectedUnit, handleUnitChange, convertValue } = useBodyMeasurementServiceContext();

  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-center">
        <UnitToggle selectedUnit={selectedUnit} onUnitChange={handleUnitChange} />
      </div>
      <div className="grid w-full grid-rows-3 gap-1 p-3 border rounded-md border-gorgonzola-blue-50">
        {measurements.map((measurement, index) => (
          <div key={measurement.key} className={`flex justify-between gap-1 w-full *:rounded *:p-2 ${index % 2 === 0 ? "*:bg-gorgonzola-blue-50/20" : "*:bg-gorgonzola-blue-50/50"}`}>
            <span className="flex items-center">
              <img src={measurement.icon} alt={`${measurement.label} icon`} className="size-5 min-w-5" />
            </span>

            <span className="text-xs md:text-base flex-grow md:min-w-[11.25rem] line-clamp-1">{measurement.label}</span>

            <span className="text-xs md:text-base w-[6.2rem] md:w-36 line-clamp-1">
              {convertValue(measurement.value)} {selectedUnit}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MeasurementTable;
