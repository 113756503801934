import React from "react";
import Table from "../../../table/Table";
import StatusBadge from "../../../table/StatusBadge";
import MobilePlanCard from "./MobilePlanCard";
import { TableColumn } from "../../../../utilities/types/support/types";
import { useNavigate } from "react-router-dom";

interface PlansAnalyticsTableProps {
  plansData: any[];
}

const PlansAnalyticsTable: React.FC<PlansAnalyticsTableProps> = ({ plansData }) => {
  const navigate = useNavigate();

  const handleRowClick = (plan: any) => {
    navigate(`/admin/users/${plan.user_id}/payments`);
  };

  const plansColumns: TableColumn<any>[] = [
    {
      key: "user",
      header: "Business Email",
      render: (value, item) => <span className="text-xs">{item.user?.email ? item.user?.email : "-"}</span>,
    },
    {
      key: "service",
      header: "Service",
      render: (value, item) => <span className="text-xs">{item.plan?.remaining_apis[0].api_name ? item.plan?.remaining_apis[0].api_name : "-"}</span>,
    },
    {
      key: "plan",
      header: "Plan",
      render: (value, item) => <span className="text-xs">{item.plan?.name ? item.plan?.name : "-"}</span>,
    },
    {
      key: "actived_date",
      header: "Start Plan",
      render: (value) => <span className="text-xs">{value ? new Date(value * 1000).toLocaleDateString() : "-"}</span>,
    },
    {
      key: "expired_date",
      header: "End Plan",
      render: (value) => <span className="text-xs">{value ? new Date(value * 1000).toLocaleDateString() : "-"}</span>,
    },
    {
      key: "status",
      header: "Status",
      render: (value) => (
        <div className="flex w-full">
          <StatusBadge statusText={value.toLowerCase()} />
        </div>
      ),
    },
    {
      key: "remaining_money",
      header: "Req/Req use",
      render: (value, item) => (
        <span className="text-xs">
          {item.apis.total_requests}/{item.apis.use_requests}
        </span>
      ),
    },
  ];

  return (
    <>
      <div className="block xl:hidden odd:*:bg-gorgonzola-blue-50/30 even:*:bg-white">
        {plansData.map((plan) => (
          <MobilePlanCard key={plan.id} plan={plan} onClick={() => handleRowClick(plan)} />
        ))}
      </div>

      <div className="hidden w-full xl:block">
        <Table data={plansData} columns={plansColumns} onRowClick={handleRowClick} />
      </div>
    </>
  );
};

export default PlansAnalyticsTable;
