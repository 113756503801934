import React, { useEffect } from "react";
import { useBodyMeasurementServiceContext } from "../../../../contexts/BodyMeasurementServiceContext";
import BodyMeasurementServiceImageDisplay from "../BodyMeasurementServiceImageDisplay";
import ErrorDisplay from "../../ErrorDisplay";
import FreeTrialServicesLoader from "../../FreeTrialServicesLoader";
import { useNavigate } from "react-router-dom";

const Measurement: React.FC = () => {
  const { height, convertValue, submitMeasurement, fetchAvailableCharts, error, setError, resetBodyMeasurementService, selectedUnit } = useBodyMeasurementServiceContext();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMeasurementData = async () => {
      await submitMeasurement();
      await fetchAvailableCharts();
    };
    fetchMeasurementData();
  }, []);

  if (error) {
    return (
      <ErrorDisplay
        error={error}
        onRetry={() => {
          setError(null);
          // submitMeasurement();
          resetBodyMeasurementService();
          navigate("/dashboard/free-trial/body-measurment");
        }}
      />
    );
  }

  return (
    <div className="flex flex-col items-center gap-32 w-full md:w-[20rem]">
      <FreeTrialServicesLoader />
      <div className="flex flex-col items-center gap-6">
        <div className="w-full px-2 py-2 space-y-2 text-center border border-gorgonzola-blue-100 rounded-xl">
          <h2 className="text-base font-medium text-closed-shutter-400">
            Your Height: {convertValue(height!)} {selectedUnit}
          </h2>
        </div>

        <div className="w-full max-w-sm p-4 border rounded-xl border-gorgonzola-blue-50">
          <BodyMeasurementServiceImageDisplay />
        </div>
      </div>
    </div>
  );
};

export default Measurement;
