import { GenderSpecificAnatomy } from "../types/free-trial/body-measurement-service/types";

export const anatomyConfig: GenderSpecificAnatomy = {
  genderfree: {
    image: "/assets/image/free-trial/body-measurment/body-analysis-visualizer/male-anatomy.png",
    bodyParts: {
      Neck: {
        position: "top-[5.1rem] left-[9.25rem]",
        size: "w-[3.323125rem] h-[1.2rem]",
        icon: "/assets/image/free-trial/body-measurment/body-analysis-visualizer/male/neck.svg",
      },
      Shoulder: {
        position: "top-[5.925rem] left-[7.6rem]",
        size: "w-[6.65625rem] h-[2.094375rem]",
        icon: "/assets/image/free-trial/body-measurment/body-analysis-visualizer/male/shoulder.svg",
      },
      Chest: {
        position: "top-[7.4rem] left-[7.5rem]",
        size: "w-[6.919375rem] h-[2rem]",
        icon: "/assets/image/free-trial/body-measurment/body-analysis-visualizer/male/chest.svg",
      },
      "Under the chest": {
        position: "top-[8rem] left-[7.51rem]",
        size: "w-[6.9rem] h-[2.5rem]",
        icon: "/assets/image/free-trial/body-measurment/body-analysis-visualizer/male/under-chest.svg",
      },
      Bust: {
        position: "top-[7.4rem] left-[7.5rem]",
        size: "w-[6.919375rem] h-[2rem]",
        icon: "/assets/image/free-trial/body-measurment/body-analysis-visualizer/male/chest.svg",
      },
      "Under the bust": {
        position: "top-[8rem] left-[7.51rem]",
        size: "w-[6.9rem] h-[2.5rem]",
        icon: "/assets/image/free-trial/body-measurment/body-analysis-visualizer/male/under-chest.svg",
      },
      Waist: {
        position: "top-[10.375rem] left-[8.18rem]",
        size: "w-[5.5rem] h-[2rem]",
        icon: "/assets/image/free-trial/body-measurment/body-analysis-visualizer/male/waist.svg",
      },
      Hip: {
        position: "top-[12.19375rem] left-[8rem]",
        size: "w-[6rem] h-[2.609375rem]",
        icon: "/assets/image/free-trial/body-measurment/body-analysis-visualizer/male/hip.svg",
      },
      Thigh: {
        position: "top-[17.0625rem] left-[7.2rem]",
        size: "w-[4rem] h-[2.609375rem]",
        icon: "/assets/image/free-trial/body-measurment/body-analysis-visualizer/male/thigh.svg",
      },
      Leg: {
        position: "top-[23.0625rem] left-[7rem]",
        size: "w-[3.103125rem] h-[1.904375rem]",
        icon: "/assets/image/free-trial/body-measurment/body-analysis-visualizer/male/leg.svg",
      },
      FullArmLength: {
        position: "top-[4.4rem] left-[1.7rem] rotate-[16deg]",
        size: "w-[6.75rem] h-[8.75rem]",
        icon: "/assets/image/free-trial/body-measurment/body-analysis-visualizer/male/arm.svg",
      },
      // Wrist: {
      //   position: "top-[9.9rem] left-[2.2rem]",
      //   size: "w-[2.540625rem] h-[2.625rem]",
      //   icon: "/assets/image/free-trial/body-measurment/body-analysis-visualizer/male/wrist.svg",
      // },
    },
  },
  men: {
    image: "/assets/image/free-trial/body-measurment/body-analysis-visualizer/male-anatomy.png",
    bodyParts: {
      Neck: {
        position: "top-[5.1rem] left-[9.25rem]",
        size: "w-[3.323125rem] h-[1.2rem]",
        icon: "/assets/image/free-trial/body-measurment/body-analysis-visualizer/male/neck.svg",
      },
      Shoulder: {
        position: "top-[5.925rem] left-[7.6rem]",
        size: "w-[6.65625rem] h-[2.094375rem]",
        icon: "/assets/image/free-trial/body-measurment/body-analysis-visualizer/male/shoulder.svg",
      },
      Chest: {
        position: "top-[7.4rem] left-[7.5rem]",
        size: "w-[6.919375rem] h-[2rem]",
        icon: "/assets/image/free-trial/body-measurment/body-analysis-visualizer/male/chest.svg",
      },
      "Under the chest": {
        position: "top-[8rem] left-[7.51rem]",
        size: "w-[6.9rem] h-[2.5rem]",
        icon: "/assets/image/free-trial/body-measurment/body-analysis-visualizer/male/under-chest.svg",
      },
      Bust: {
        position: "top-[7.4rem] left-[7.5rem]",
        size: "w-[6.919375rem] h-[2rem]",
        icon: "/assets/image/free-trial/body-measurment/body-analysis-visualizer/male/chest.svg",
      },
      "Under the bust": {
        position: "top-[8rem] left-[7.51rem]",
        size: "w-[6.9rem] h-[2.5rem]",
        icon: "/assets/image/free-trial/body-measurment/body-analysis-visualizer/male/under-chest.svg",
      },
      Waist: {
        position: "top-[10.375rem] left-[8.18rem]",
        size: "w-[5.5rem] h-[2rem]",
        icon: "/assets/image/free-trial/body-measurment/body-analysis-visualizer/male/waist.svg",
      },
      Hip: {
        position: "top-[12.19375rem] left-[8rem]",
        size: "w-[6rem] h-[2.609375rem]",
        icon: "/assets/image/free-trial/body-measurment/body-analysis-visualizer/male/hip.svg",
      },
      Thigh: {
        position: "top-[17.0625rem] left-[7.2rem]",
        size: "w-[4rem] h-[2.609375rem]",
        icon: "/assets/image/free-trial/body-measurment/body-analysis-visualizer/male/thigh.svg",
      },
      Leg: {
        position: "top-[23.0625rem] left-[7rem]",
        size: "w-[3.103125rem] h-[1.904375rem]",
        icon: "/assets/image/free-trial/body-measurment/body-analysis-visualizer/male/leg.svg",
      },
      FullArmLength: {
        position: "top-[4.4rem] left-[1.7rem] rotate-[16deg]",
        size: "w-[6.75rem] h-[8.75rem]",
        icon: "/assets/image/free-trial/body-measurment/body-analysis-visualizer/male/arm.svg",
      },
      // Wrist: {
      //   position: "top-[9.9rem] left-[2.2rem]",
      //   size: "w-[2.540625rem] h-[2.625rem]",
      //   icon: "/assets/image/free-trial/body-measurment/body-analysis-visualizer/male/wrist.svg",
      // },
    },
  },
  women: {
    image: "/assets/image/free-trial/body-measurment/body-analysis-visualizer/female-anatomy.png",
    bodyParts: {
      Neck: {
        position: "top-[3.7rem] left-[9.25rem]",
        size: "w-[3.323125rem] h-[1.2rem]",
        icon: "/assets/image/free-trial/body-measurment/body-analysis-visualizer/female/neck.svg",
      },
      Shoulder: {
        position: "top-[4.325rem] left-[7.6rem]",
        size: "w-[6.65625rem] h-[2.094375rem]",
        icon: "/assets/image/free-trial/body-measurment/body-analysis-visualizer/female/shoulder.svg",
      },
      Bust: {
        position: "top-[6.3rem] left-[8rem]",
        size: "w-[5.919375rem] h-[1.850625rem]",
        icon: "/assets/image/free-trial/body-measurment/body-analysis-visualizer/female/bust.svg",
      },
      "Under the bust": {
        position: "top-[7rem] left-[7.71rem]",
        size: "w-[6.65625rem] h-[2.094375rem]",
        icon: "/assets/image/free-trial/body-measurment/body-analysis-visualizer/female/under-bust.svg",
      },
      Chest: {
        position: "top-[7.4rem] left-[7.5rem]",
        size: "w-[6.919375rem] h-[2rem]",
        icon: "/assets/image/free-trial/body-measurment/body-analysis-visualizer/male/chest.svg",
      },
      "Under the chest": {
        position: "top-[8rem] left-[7.51rem]",
        size: "w-[6.9rem] h-[2.5rem]",
        icon: "/assets/image/free-trial/body-measurment/body-analysis-visualizer/male/under-chest.svg",
      },
      Waist: {
        position: "top-[9.375rem] left-[8.48rem]",
        size: "w-[4.875rem] h-[1.875rem]",
        icon: "/assets/image/free-trial/body-measurment/body-analysis-visualizer/female/waist.svg",
      },
      Hip: {
        position: "top-[11.919375rem] left-[7.71rem]",
        size: "w-[6.418125rem] h-[2.609375rem]",
        icon: "/assets/image/free-trial/body-measurment/body-analysis-visualizer/female/hip.svg",
      },
      Thigh: {
        position: "top-[17.0625rem] left-[6.9rem]",
        size: "w-[4.3725rem] h-[2.609375rem]",
        icon: "/assets/image/free-trial/body-measurment/body-analysis-visualizer/female/thigh.svg",
      },
      Leg: {
        position: "top-[25.0625rem] left-[7rem]",
        size: "w-[3.103125rem] h-[1.904375rem]",
        icon: "/assets/image/free-trial/body-measurment/body-analysis-visualizer/female/leg.svg",
      },
      FullArmLength: {
        position: "top-[4.6875rem] left-[1.2rem] rotate-[-15deg]",
        size: "w-[8.78125rem] h-[7rem]",
        icon: "/assets/image/free-trial/body-measurment/body-analysis-visualizer/female/arm.svg",
      },
      // Wrist: {
      //   position: "top-[11rem] left-[3rem]",
      //   size: "w-[2.540625rem] h-[2.625rem]",
      //   icon: "/assets/image/free-trial/body-measurment/body-analysis-visualizer/female/wrist.svg",
      // },
    },
  },
};
