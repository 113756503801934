import React from "react";
import { useVisualClothesServiceContext } from "../../../contexts/VisualClothesServiceContext";
import Loader from "../../loading/Loader";
import ProductImage from "./ProductImage";
import CustomPagination from "../../table/CustomPagination";

const SimilarItems: React.FC = () => {
  const { currentSearchResults: searchResults, searchError, visualClothesResult, hoveredItem, searchResultsMap, handleItemPagination } = useVisualClothesServiceContext();

  const calculateDiscountPercentage = (currentPrice: string, previousPrice: string | null): number | null => {
    if (!previousPrice) return null;
    const current = Number(currentPrice);
    const previous = Number(previousPrice);
    if (current >= previous) return null;
    return Math.round(((previous - current) / previous) * 100);
  };

  const getCurrentItemIndex = () => {
    if (!hoveredItem || !visualClothesResult) return -1;
    return visualClothesResult.Clothes.findIndex((item) => item === hoveredItem);
  };

  const itemIndex = getCurrentItemIndex();
  const currentItemState = itemIndex !== -1 ? searchResultsMap[itemIndex] : null;

  const handlePageChange = (page: number) => {
    if (itemIndex !== -1) {
      handleItemPagination(itemIndex, page);
    }
  };

  if (searchError) {
    // return <div className="w-full p-6 text-center text-english-vermillion-300">{searchError}</div>;
    return <div className="w-full p-6 text-center text-closed-shutter-300">Error: {searchError}</div>;
  }

  if (currentItemState?.error) {
    // return <div className="w-full p-6 text-center text-english-vermillion-300">{currentItemState.error}</div>;
    return <div className="w-full p-6 text-center text-closed-shutter-300">Error: {currentItemState.error}</div>;
  }

  if (!hoveredItem || (currentItemState && currentItemState.loading)) {
    return (
      <div className="flex justify-center w-full p-4 md:p-6">
        <Loader />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-6">
      <div className="grid place-items-center grid-cols-2 gap-y-4 gap-x-2 md:gap-4 p-4 md:grid-cols-3 xl:grid-cols-3 xxl:grid-cols-4 *:flex *:flex-col *:w-[8rem] md:*:w-[12.1875rem] *:min-h-[19.75rem] *:bg-white *:gap-2 *:rounded-lg *:cursor-pointer *:transition-shadow *:duration-600 hover:*:shadow-[0px_17.5px_70px_0px_rgba(0,_0,_0,_0.12)] overflow-y-auto custom_scroll">
        {searchResults.map((item, index) => {
          const discountPercentage = calculateDiscountPercentage(item.CurrentPrice, item.PreviousPrice);

          return (
            <a href={item.Url} key={`${hoveredItem?.ClotheType}-${index}-${item.Url}`} target="_blank" rel="noopener noreferrer">
              <div className="relative w-full h-[11.75rem] rounded-t-md overflow-hidden">
                <ProductImage images={item.ImgUrl} alt={item.Name} className="object-cover h-full min-w-full" />
                {discountPercentage && item.CurrentPrice !== item.PreviousPrice && (
                  <div className="absolute right-0 p-0 px-2 py-1 bottom-3 bg-gorgonzola-blue-50/70">
                    {/* <span className="text-xs text-english-vermillion-300">{discountPercentage}%</span> */}
                    <span className="text-xs text-closed-shutter-300">Error: {discountPercentage}%</span>
                  </div>
                )}
              </div>

              <div className="flex flex-col gap-2">
                <div className="inline-flex items-center gap-2 ps-2">
                  <span className="text-2xs text-closed-shutter-300">{item.Brand}</span>
                </div>

                <span className="w-full border-b border-b-gorgonzola-blue-50" />

                <div className="flex flex-col gap-3 ps-2">
                  <div className="space-y-2">
                    <h3 className="text-xs text-black/70 line-clamp-2 md:line-clamp-1">{item.Name}</h3>
                    <div className="inline-flex items-center gap-2 *:font-bold *:text-xs md:*:text-base">
                      {!item.PreviousPrice || item.CurrentPrice === item.PreviousPrice ? (
                        <span className="text-closed-shutter-300">${item.CurrentPrice}</span>
                      ) : (
                        <>
                          <span className="line-through text-closed-shutter-800/30">${item.PreviousPrice}</span>
                          <span className="text-closed-shutter-300">${item.CurrentPrice}</span>
                        </>
                      )}
                    </div>
                  </div>

                  <span className="text-2xs text-closed-shutter-300">View on {item.Brand}</span>
                </div>
              </div>
            </a>
          );
        })}
      </div>

      {currentItemState?.pagination && currentItemState.pagination.totalPages > 1 && (
        <CustomPagination
          currentPage={currentItemState.pagination.currentPage}
          totalPages={currentItemState.pagination.totalPages}
          hasPreviousPage={currentItemState.pagination.currentPage > 1}
          hasNextPage={currentItemState.pagination.currentPage < currentItemState.pagination.totalPages}
          onPageChange={handlePageChange}
        />
      )}
    </div>
  );
};

export default SimilarItems;
