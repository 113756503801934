export const MAX_FILE_SIZE = 3 * 1024 * 1024; // 3MB in bytes

export interface ImageValidationResult {
  isValid: boolean;
  error?: string;
}

export const validateImage = (file: File): ImageValidationResult => {
  // Check file size
  if (file.size > MAX_FILE_SIZE) {
    return {
      isValid: false,
      error: "Image size must be less than 3MB",
    };
  }

  // Check file type
  if (!file.type.startsWith("image/")) {
    return {
      isValid: false,
      error: "Please upload a valid image file",
    };
  }

  return {
    isValid: true,
  };
};
