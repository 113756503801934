import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Link, useLocation } from "react-router-dom";
import { useLogoutHandler } from "../modal/LogoutHandler";
import HamburgerIcon from "./HamburgerIcon";
import NavbarRightSide from "./NavbarRightSide";
import { LogoutCurve } from "iconsax-react";
import { SidebarItem } from "../../utilities/types/sidebar/types";
import { exactRouteMatch, isSubRoute } from "../../utilities/helper/routingHelpers";
import { useGlobalInfo } from "../../contexts/GlobalInfoContext";
import { useSidebarItems } from "../../utilities/helper/useSidebarItems";

const MobileNavbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { handleLogout, LogoutModalComponent } = useLogoutHandler();
  const { pathname } = useLocation();
  const { isAdmin } = useGlobalInfo();
  const { mainItems, primaryItems } = useSidebarItems();

  const allNavItems = [...mainItems, ...primaryItems].filter((item): item is SidebarItem & { href: string } => {
    return !item.logout && typeof item.href === "string";
  });

  const isItemActive = (item: SidebarItem): boolean => {
    if (!item.href) return false;

    // For the dashboard route, only activate on exact match
    if (item.href === "/dashboard" || item.href === "/admin") {
      return exactRouteMatch(pathname, item.href);
    }

    // For other routes, activate on exact match or if it's a sub-route
    return exactRouteMatch(pathname, item.href) || isSubRoute(pathname, item.href);
  };

  return (
    <div className="w-full">
      <div className="flex flex-col gap-4 w-ull lg:hidden">
        <div className={`relative flex items-center w-full z-[998]`}>
          <div className="flex items-center justify-between w-full px-4 bg-transparent">
            <Link to={isAdmin ? "/admin" : "/dashboard"}>
              {/* <img src="/assets/svg/main_logo_mobile.svg" alt="Main Logo" className=" min-w-fit max-h-[2.6875rem]" /> */}
              <img src="/assets/image/logo/new-logo.png" alt="Main Logo" className=" min-w-[2.6875rem] size-[2.6875rem]" />
            </Link>

            <div className="flex items-center gap-4">
              <NavbarRightSide />

              <div className="p-2 rounded-lg bg-gorgonzola-blue-50" onClick={() => setIsOpen(!isOpen)}>
                <HamburgerIcon isOpen={isOpen} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            className={`fixed inset-0 h-dvh bg-transparent w-full px-4 lg:px-8 pt-[4.75rem] z-[997] overflow-hidden select-none`}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ type: "spring", stiffness: 400, damping: 40 }}
          >
            <div className={`fixed inset-0 h-screen bg-[#F6F6F7] w-full z-[995] overflow-hidden`} />
            <div className="relative size-full flex flex-col justify-between gap-8 p-4 bg-gorgonzola-blue-50 rounded-t-3xl z-[996] overflow-y-auto overflow-x-hidden">
              <div className="flex flex-col gap-10">
                <div>
                  <ul className="relative flex flex-col w-full divide-y divide-gorgonzola-blue-100">
                    {allNavItems.map((item) => (
                      <Link to={item.href} key={item.title}>
                        <li
                          onClick={() => setIsOpen(false)}
                          className={`flex items-center gap-3 py-4 w-full bg-gorgonzola-blue-50 ${isItemActive(item) ? "text-gorgonzola-blue-500" : "text-closed-shutter-300"}`}
                        >
                          {/* <span className="size-5">{item.svg}</span> */}
                          <span>{React.cloneElement(item.svg, { variant: isItemActive(item) ? "Bold" : "Broken", className: "size-6" })}</span>

                          <span className="text-xs">{item.title}</span>
                        </li>
                      </Link>
                    ))}

                    <li onClick={handleLogout} className="flex items-center w-full gap-3 py-4 cursor-pointer bg-gorgonzola-blue-50 text-english-vermillion-300">
                      <span className="size-5">
                        <LogoutCurve variant="Broken" />
                      </span>
                      <span className="text-xs">Log out</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      {LogoutModalComponent}
    </div>
  );
};

export default MobileNavbar;
