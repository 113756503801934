import React, { useMemo } from "react";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, ChartOptions, ChartData } from "chart.js";
import MainButton from "../button/MainButton";
import { useHumanDate } from "../../hooks/useHumanDate";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

interface PlanChartProps {
  data: any;
  selectedDate: Date;
  selectedLabelId: number | null;
  onLabelChange: (labelId: number | null) => void;
}

const PlanChart: React.FC<PlanChartProps> = ({ data, selectedDate, selectedLabelId, onLabelChange }) => {
  const { formatTimestamp } = useHumanDate();

  const chartData = useMemo(() => {
    if (!data || !data.chart || data.chart.length === 0) {
      return { labels: [], datasets: [] };
    }

    const sortedData = [...data.chart].sort((a, b) => a.period - b.period);
    const labels = sortedData.map((item) => formatTimestamp(item.period));

    const datasets = (data.labels || []).map((label: any) =>
      selectedLabelId === label.id || selectedLabelId === null
        ? {
            label: label.name,
            data: sortedData.map((item: any) => (item.label_id === label.id ? item.total : 0)),
            borderColor: label.name === "Body Measurement" ? "#F7BA1E" : label.name === "Auto Tagging" ? "#3491FA" : label.name === "Visual Clothes Search" ? "#14C9C9" : "red",
            backgroundColor: label.name === "Body Measurement" ? "#F7BA1E" : label.name === "Auto Tagging" ? "#3491FA" : label.name === "Visual Clothes Search" ? "#14C9C9" : "red",
            pointRadius: 0,
          }
        : ""
    );

    return { labels, datasets } as ChartData<"line">;
  }, [data, formatTimestamp]);

  const maxY = useMemo(() => {
    if (!chartData || chartData.datasets.length === 0) return 10;
    const allValues = chartData.datasets.flatMap((dataset) => dataset.data as number[]).filter((value): value is number => typeof value === "number");
    return Math.max(10, Math.ceil(Math.max(...allValues) + 5));
  }, [chartData]);

  const chartOptions: ChartOptions<"line"> = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: "category" as const,
        grid: {
          display: false,
        },
        ticks: {
          maxRotation: 0,
          autoSkip: true,
          maxTicksLimit: 12,
        },
      },
      y: {
        stacked: false,
        beginAtZero: true,
        grid: {
          display: true,
          color: "#EDEDFA",
          lineWidth: 1,
          drawTicks: false,
          drawOnChartArea: true,
        },
        min: 0,
        max: maxY,
        ticks: {
          stepSize: 1,
        },
        border: {
          display: false,
        },
      },
    },

    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        mode: "index",
        intersect: false,
        backgroundColor: "#fff",
        titleColor: "#484554c2",
        titleFont: {
          size: 15,
          weight: "bold",
        },
        titleAlign: "center",
        bodyColor: "#23262F",
        bodyFont: {
          size: 15,
          weight: "bolder",
        },
        bodyAlign: "left",
        bodySpacing: 8,
        borderColor: "#C6C7F0",
        borderWidth: 1,
        cornerRadius: 16,
        padding: 12,
        usePointStyle: true,
        boxPadding: 8,
        callbacks: {
          title: (context) => {
            return formatTimestamp(data?.chart[context[0].dataIndex]?.period || 0);
          },
          label: function (context) {
            const label = context.dataset.label || "";
            const value = context.parsed.y;
            return `${label}: ${value}`;
          },
          labelTextColor: function (context) {
            return context.dataset.borderColor as string;
          },
        },
      },
    },
    interaction: {
      mode: "nearest",
      axis: "x",
      intersect: false,
    },
  };

  if (!data || !data.labels || data.labels.length === 0) {
    return <div className="text-center text-closed-shutter-300">No chart data available.</div>;
  }

  return (
    <div className="flex flex-col gap-4 ">
      <div className="flex gap-3 *:min-w-fit overflow-x-auto">
        <MainButton type={selectedLabelId === null ? "primary" : "ghostOutlined"} onClick={() => onLabelChange(null)}>
          All
        </MainButton>

        {data.labels.map((label: any) => (
          <MainButton key={label.id} type={selectedLabelId === label.id ? "primary" : "ghostOutlined"} onClick={() => onLabelChange(label.id)}>
            <span
              className={`rounded-full size-6 inline-block mr-2`}
              style={{
                backgroundColor: label.name === "Body Measurement" ? "#F7BA1E" : label.name === "Auto Tagging" ? "#3491FA" : label.name === "Visual Clothes Search" ? "#14C9C9" : "red",
              }}
            />
            {label.name}
          </MainButton>
        ))}
      </div>

      <div className="w-full h-[16.75rem]">
        <Line options={chartOptions} data={chartData} />
      </div>
    </div>
  );
};

export default PlanChart;
