import React from "react";
import { Plan } from "./PlanOverview";
import SizeMeasurementIcon from "../icon/SizeMeasurementIcon";
import ClothesRecognitionIcon from "../icon/ClothesRecognitionIcon";
import VisualClothesSearchIcon from "../icon/VisualClothesSearchIcon";
import { Link } from "react-router-dom";
import { Crown1 } from "iconsax-react";
import Tooltip from "../common/Tooltip";

export enum ApiType {
  SIZE_MEASUREMENT = "Body Measurement",
  CLOTHES_RECOGNITION = "Auto Tagging",
  VISUAL_CLOTHES_SEARCH = "Visual Clothes Search",
}

interface ApiIcon {
  type: ApiType;
  icon: React.FC<{ className: string }>;
}

const API_ICONS: ApiIcon[] = [
  {
    type: ApiType.SIZE_MEASUREMENT,
    icon: SizeMeasurementIcon,
  },
  {
    type: ApiType.CLOTHES_RECOGNITION,
    icon: ClothesRecognitionIcon,
  },
  {
    type: ApiType.VISUAL_CLOTHES_SEARCH,
    icon: VisualClothesSearchIcon,
  },
];

interface PlanIconsProps {
  activedOrders: { plan: Plan }[];
  currentPlanIndex: number;
  selectedApiType: ApiType;
  onPlanSelect: (index: number) => void;
  onApiSelect: (apiType: ApiType) => void;
}

const PlanIcons: React.FC<PlanIconsProps> = ({ activedOrders, currentPlanIndex, selectedApiType, onPlanSelect, onApiSelect }) => {
  return (
    <div className="flex gap-2">
      {API_ICONS.map((apiIcon) => {
        // const planIndex = activedOrders.findIndex((order) => order.plan.apis[0].name === apiIcon.type);
        const IconComponent = apiIcon.icon;
        const isSelected = apiIcon.type === selectedApiType;

        return (
          <div key={apiIcon.type}>
            <Tooltip key={apiIcon.type} text={apiIcon.type} position="top" className="hidden md:block">
              <button
                className={`p-2 group rounded-md transition-colors duration-600 size-10 *:size-full ${isSelected ? "bg-shindig-200" : "bg-shindig-100"}`}
                onClick={() => {
                  onApiSelect(apiIcon.type);
                }}
              >
                <IconComponent className={`transition-all size-6 duration-600 group-hover:scale-110 ${isSelected ? "text-shindig-500" : "text-shindig-300/50"}`} />
              </button>
            </Tooltip>
            <button
              className={`md:hidden p-2 group rounded-md transition-colors duration-600 size-10 *:size-full ${isSelected ? "bg-shindig-200" : "bg-shindig-100"}`}
              onClick={() => {
                onApiSelect(apiIcon.type);
              }}
            >
              <IconComponent className={`transition-all size-6 duration-600 group-hover:scale-110 ${isSelected ? "text-shindig-500" : "text-shindig-300/50"}`} />
            </button>
          </div>
        );
      })}

      <Link to="/dashboard/plans" className="p-2 transition-colors rounded-md group duration-600 bg-slap-happy-50 hover:bg-slap-happy-200 text-slap-happy-300 hover:text-slap-happy-500">
        <Crown1 className="transition-transform size-6 group-hover:scale-110 duration-600" variant="Bold" />
      </Link>
    </div>
  );
};

export default PlanIcons;
