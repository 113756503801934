import React, { useContext } from "react";
import { DashboardContext } from "../contexts/DashboardContext";
import { HierarchySquare3, Signpost } from "iconsax-react";
import MainButton from "./button/MainButton";
import ProjectDeliveriesChart from "./project-deliveries-chart/ProjectDeliveriesChart";
import UserStatistics from "./user-statistics/UserStatistics";
import BalanceOverview from "./main-dashboard/BalanceOverview";
import PlanOverview from "./main-dashboard/PlanOverview";
import Loader from "./loading/Loader";
import { Link } from "react-router-dom";

const MainDashboard: React.FC = () => {
  const { dashboardMap, loading } = useContext(DashboardContext);

  if (loading) {
    return (
      <div className="flex justify-center w-full p-4 md:p-6">
        <Loader />
      </div>
    );
  }

  if (!dashboardMap) {
    return <p className="p-6 mb-4 font-bold text-gray-700">No data available</p>;
  }

  const balance = Number(dashboardMap.funds?.wallet?.balance) || 0;
  const transactions = dashboardMap.balance?.transactions || [];
  const created_at = dashboardMap?.user?.created_at;

  return (
    <div className="flex flex-col items-center gap-4 p-4 md:p-6">
      <div className="flex flex-col lg:flex-row justify-between w-full gap-4 lg:h-[23.25rem] overflow-visible">
        <div className="relative flex justify-end flex-grow overflow-hidden rounded-md min-h-[30rem] md:min-h-max bg-gorgonzola-blue-900">
          <div className="*:object-cover *:max-h-[24rem]">
            <img src="/assets/image/main-dashboard/welcome-bg.png" alt="Welcome Background" className="hidden xl:block" />
            <img src="/assets/image/main-dashboard/mobile-welcome-bg.png" alt="Welcome Background" className="xl:hidden " />
          </div>

          <div className="absolute inset-0 left-0 flex flex-col items-start justify-end h-full gap-6 p-6 md:justify-center xl:p-0 md:gap-20 xl:left-9">
            <div className="flex flex-col gap-6 w-full md:w-[60%] xl:w-[49%]">
              <h1 className="text-lg font-bold text-gorgonzola-blue-50">
                Welcome to Shaku,{" "}
                {dashboardMap.user.first_name || dashboardMap.user.last_name ? (
                  <>
                    {dashboardMap.user.first_name} {dashboardMap.user.last_name}
                  </>
                ) : (
                  "Dear User"
                )}
              </h1>
              {/* <p className="text-xs text-gorgonzola-blue-100">Your service usage has increased by 54% compared to last month—a significant achievement!</p> */}
            </div>

            <div className="flex flex-col w-full gap-4 md:w-fit md:flex-row">
              <Link to={"/dashboard/tutorial"}>
                <MainButton type="secondary" customClass="w-full flex justify-center">
                  <Signpost className="size-6" variant="Broken" />
                  <span className="text-xs">Tutorial</span>
                </MainButton>
              </Link>

              <Link to={"/dashboard/free-trial"}>
                <MainButton type="primary" customClass="w-full flex justify-center">
                  <HierarchySquare3 className="size-6" variant="Broken" />
                  <span className="text-xs">Start Your Free Trial</span>
                </MainButton>
              </Link>
            </div>
          </div>
        </div>

        <div className="flex flex-row lg:flex-col lg:justify-between gap-4 w-full lg:w-fit lg:min-w-[18.875rem] *:max-h-[11.125rem] *:min-w-[18.875rem] *:max-w-[18.875rem] overflow-x-auto md:overflow-x-visible">
          <BalanceOverview balance={balance} transactions={transactions} />
          <PlanOverview activedOrders={dashboardMap.orders?.actived_orders || []} />
        </div>
      </div>

      <div className="flex flex-col justify-between w-full gap-4 xl:flex-row">
        <ProjectDeliveriesChart userCreatedAt={created_at} />

        <UserStatistics userCreatedAt={created_at} />
      </div>
    </div>
  );
};

export default MainDashboard;
