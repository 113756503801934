import React, { createContext, useState, useContext } from "react";
import { getAvailableCharts, SizeGuide, submitSizeMeasurement } from "../utilities/api/bodyMeasurementService";
import { AuthContext } from "./AuthContext";
import { MeasurementResult, SizeGuideRequest } from "../utilities/types/measurement-data/types";
import { convertToBase64 } from "../utilities/helper/convertToBase64";
import { validateImage } from "../utilities/helper/validateImageUpload";

// Add constants at the top of the file
const MIN_HEIGHT_CM = 51;
const MAX_HEIGHT_CM = 230;
const CM_TO_INCH = 0.393701;
const INCH_TO_CM = 2.54;

// Interface for the context value
interface BodyMeasurementServiceContextValue {
  frontImage: string | null;
  sideImage: string | null;
  height: number | null;
  currentStep: number;
  canProceedToNextStep: boolean;
  measurementData: MeasurementResult | null;
  error: string | null;
  frontImageError: string | null;
  sideImageError: string | null;
  availableCharts: any | null;
  sizeGuideResult: any | null;
  loadingCharts: boolean;
  loadingSizeGuide: boolean;
  setError: (error: string | null) => void;
  setFrontImageError: (error: string | null) => void;
  setSideImageError: (error: string | null) => void;
  handlePageChange: (page: number) => void;
  handleFrontImageUpload: (image: File | undefined) => void;
  handleSideImageUpload: (image: File | undefined) => void;
  setHeight: (height: number | null) => void;
  setCanProceedToNextStep: (value: boolean) => void;
  submitMeasurement: () => Promise<void>;
  resetBodyMeasurementService: () => void;
  fetchAvailableCharts: () => Promise<void>;
  submitSizeGuide: (sizeGuideData: SizeGuideRequest) => Promise<void>;
  resetSizeGuide: () => void;
  selectedUnit: "cm" | "inch";
  displayValue: string | null;
  handleUnitChange: (unit: "cm" | "inch") => void;
  convertValue: (value: number) => string;
  getValidationProps: () => {
    min: number;
    max: number;
    step: number;
  };
  handleHeightChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

// Interface for the provider props
interface BodyMeasurementServiceProviderProps {
  children: React.ReactNode;
}

// Create the context
export const BodyMeasurementServiceContext = createContext<BodyMeasurementServiceContextValue | undefined>(undefined);

// Provider component
export const BodyMeasurementServiceProvider: React.FC<BodyMeasurementServiceProviderProps> = ({ children }) => {
  // State variables
  const [frontImage, setFrontImage] = useState<string | null>(null);

  const [sideImage, setSideImage] = useState<string | null>(null);

  const [height, setHeight] = useState<number | null>(null);

  const [currentStep, setCurrentStep] = useState(0);

  const [canProceedToNextStep, setCanProceedToNextStep] = useState(false);

  const [error, setError] = useState<string | null>(null);

  const [frontImageError, setFrontImageError] = useState<string | null>(null);

  const [sideImageError, setSideImageError] = useState<string | null>(null);

  const [measurementData, setMeasurementData] = useState<MeasurementResult | null>(null);

  const { accessToken } = useContext(AuthContext);
  const [availableCharts, setAvailableCharts] = useState<any | null>(null);

  const [sizeGuideResult, setSizeGuideResult] = useState<any | null>(null);

  const [loadingCharts, setLoadingCharts] = useState(false);

  const [loadingSizeGuide, setLoadingSizeGuide] = useState(false);

  const [selectedUnit, setSelectedUnit] = useState<"cm" | "inch">("cm");

  const [displayValue, setDisplayValue] = useState<string | null>(null);

  // Function to handle page change
  const handlePageChange = (page: number) => {
    if (page === 0 || page === 1) {
      setHeight(null);
      setDisplayValue(null);
    }
    setError(null);
    setFrontImageError(null);
    setSideImageError(null);
    setCurrentStep(page);
    setCanProceedToNextStep(false);
  };

  // Function to handle front image upload
  const handleFrontImageUpload = async (image: File | undefined) => {
    if (image) {
      setFrontImageError(null);

      // Validate image before processing
      const validation = validateImage(image);
      if (!validation.isValid) {
        setFrontImageError(validation.error || "Invalid image");
        setCanProceedToNextStep(false);
        setFrontImage(null);
        return;
      }

      const base64 = await convertToBase64(image);
      setFrontImage(base64);
      setCanProceedToNextStep(sideImage !== null);
    }
  };

  // Function to handle side image upload
  const handleSideImageUpload = async (image: File | undefined) => {
    if (image) {
      setSideImageError(null);

      // Validate image before processing
      const validation = validateImage(image);
      if (!validation.isValid) {
        setSideImageError(validation.error || "Invalid image");
        setCanProceedToNextStep(false);
        setSideImage(null);
        return;
      }

      const base64 = await convertToBase64(image);
      setSideImage(base64);
      setCanProceedToNextStep(frontImage !== null);
    }
  };

  const submitMeasurement = async () => {
    if (!frontImage || !sideImage || !height || !accessToken) {
      setError("Missing required information for measurement");
      return;
    }

    try {
      const response = await submitSizeMeasurement(accessToken, {
        present_height: height.toString(),
        img_full_view_body: frontImage,
        img_side_view_body: sideImage,
      });

      if (!response.ok) {
        setError(response.message || "Failed to process image");
      }

      if (response.ok) {
        setMeasurementData(response.data.result);
        setCanProceedToNextStep(true);
        setCurrentStep(3);
        setError(null);
      } else {
        setError(response.message || "Failed to process measurement");
        setCurrentStep(2);
      }
    } catch (error) {
      console.error("Error submitting body measurement:", error);
      // setError("An error occurred during measurement. Please try again.");
      setCurrentStep(2);
    }
  };

  const fetchAvailableCharts = async () => {
    if (!accessToken) {
      setError("No access token available");
      return;
    }

    try {
      setLoadingCharts(true);
      const response = await getAvailableCharts(accessToken);
      if (response.ok) {
        // Store the data directly - the new format is already properly structured
        setAvailableCharts(response.data);
      } else {
        setError(response.message);
      }
    } catch (error) {
      setError("Failed to fetch available charts");
      console.error("Error fetching available charts:", error);
    } finally {
      setLoadingCharts(false);
    }
  };

  const submitSizeGuide = async (sizeGuideData: SizeGuideRequest) => {
    if (!accessToken) {
      setError("No access token available");
      return;
    }

    try {
      setLoadingSizeGuide(true);
      setError(null);
      const response = await SizeGuide(accessToken, sizeGuideData);

      if (response.ok) {
        setSizeGuideResult(response.data);
      } else {
        setError(response.message || "Size guide is not available");
        setSizeGuideResult(null);
      }
    } catch (error) {
      setError("Failed to fetch size guide");
      setSizeGuideResult(null);
      console.error("Error submitting size guide:", error);
    } finally {
      setLoadingSizeGuide(false);
    }
  };

  const handleUnitChange = (unit: "cm" | "inch") => {
    if (displayValue === "") {
      setSelectedUnit(unit);
      return;
    }
    const currentValue = Number(displayValue);
    const newValue = unit === "inch" ? (currentValue * CM_TO_INCH).toFixed(1) : Math.round(currentValue * INCH_TO_CM).toString();

    setSelectedUnit(unit);
    setDisplayValue(newValue);
  };

  const convertValue = (value: number): string => {
    if (selectedUnit === "inch") {
      return (value * CM_TO_INCH).toFixed(1);
    }
    return value.toString();
  };

  const handleHeightChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setDisplayValue(inputValue);

    if (inputValue === "") {
      setCanProceedToNextStep(false);
      return;
    }

    const numericValue = Number(inputValue);
    const cmValue = selectedUnit === "inch" ? Math.round(numericValue * INCH_TO_CM) : numericValue;
    setHeight(cmValue);

    // Add validation for the converted CM value
    const isValidHeight = cmValue > MIN_HEIGHT_CM && cmValue < MAX_HEIGHT_CM;
    setCanProceedToNextStep(isValidHeight);
  };

  const getValidationProps = () => {
    if (selectedUnit === "inch") {
      return {
        min: Math.round(MIN_HEIGHT_CM * CM_TO_INCH),
        max: Math.round(MAX_HEIGHT_CM * CM_TO_INCH),
        step: 0.1,
      };
    }
    return {
      min: MIN_HEIGHT_CM,
      max: MAX_HEIGHT_CM,
      step: 1,
    };
  };

  // Function to reset the Free Trial
  const resetBodyMeasurementService = () => {
    setFrontImage(null);
    setSideImage(null);
    setHeight(null);
    setCurrentStep(0);
    setCanProceedToNextStep(false);
    setError(null);
    setFrontImageError(null);
    setSideImageError(null);
    setMeasurementData(null);
    setAvailableCharts(null);
    setSizeGuideResult(null);
    setLoadingCharts(false);
    setLoadingSizeGuide(false);
    setSelectedUnit("cm");
    setDisplayValue(null);
  };

  // Function to reset the Size Guide Result
  const resetSizeGuide = () => {
    setSizeGuideResult(null);
    setError(null);
    setFrontImageError(null);
    setSideImageError(null);
  };

  // Return the context value
  return (
    <BodyMeasurementServiceContext.Provider
      value={{
        frontImage,
        sideImage,
        height,
        currentStep,
        canProceedToNextStep,
        handlePageChange,
        handleFrontImageUpload,
        handleSideImageUpload,
        availableCharts,
        sizeGuideResult,
        loadingCharts,
        loadingSizeGuide,
        fetchAvailableCharts,
        submitSizeGuide,
        error,
        frontImageError,
        sideImageError,
        setError,
        setFrontImageError,
        setSideImageError,
        setHeight,
        setCanProceedToNextStep,
        submitMeasurement,
        resetBodyMeasurementService,
        resetSizeGuide,
        measurementData,
        selectedUnit,
        displayValue,
        handleUnitChange,
        convertValue,
        getValidationProps,
        handleHeightChange,
      }}
    >
      {children}
    </BodyMeasurementServiceContext.Provider>
  );
};

// Custom hook to consume the context
export const useBodyMeasurementServiceContext = () => {
  const context = useContext(BodyMeasurementServiceContext);
  if (!context) {
    throw new Error("useBodyMeasurementServiceContext must be used within a BodyMeasurementServiceProvider");
  }
  return context;
};
