import React from "react";
import { Warning2 } from "iconsax-react";

interface Props {
  message: string;
}

const UploadErrorMessage: React.FC<Props> = ({ message }) => {
  return (
    <div className="flex items-center gap-2 p-3 text-xs rounded-lg text-english-vermillion-400 bg-english-vermillion-50/50">
      <Warning2 size={16} variant="Bold" />
      <span>{message}</span>
    </div>
  );
};

export default UploadErrorMessage;
