export const FREE_TRIAL = [
  {
    id: 0,
    title: "Body Measurment",
    description:
      "Shaku's Body Measurement service leverages advanced AI technology to provide accurate, personalized sizing, helping customers shop with confidence. Our AI delivers precise measurements, reducing returns and enhancing the online shopping experience with guaranteed perfect fits.",
    image: "/assets/image/free-trial/plans/body_meaurment.png",
    image_alt: "Body Meaurment",
    api_href: "#api_href",
    sdk_href: "body-measurment",
  },
  {
    id: 1,
    title: "Auto Tagging",
    description:
      "Shaku’s Auto Tagging feature transforms online apparel cataloging by automatically identifying and tagging key clothing attributes from images. This streamlines inventory management, improves search functionality, and enhances customer engagement, ultimately driving better sales.",
    image: "/assets/image/free-trial/plans/auto_tagging.png",
    image_alt: "Auto Tagging",
    api_href: "#api_href",
    sdk_href: "auto-tagging",
  },
  {
    id: 2,
    title: "Visual Clothes Search",
    description:
      "With Shaku's Visual Clothes Search, finding and purchasing fashion is effortless. Users can upload an outfit image, and our advanced technology identifies similar items, saving time, inspiring discoveries, and personalizing the shopping experience.",
    image: "/assets/image/free-trial/plans/visual-clothes-search.png",
    image_alt: "Visual Clothes Search",
    api_href: "#api_href",
    sdk_href: "visual-clothes-search",
  },
];
