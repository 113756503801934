import React from "react";
import BodyMeasurementServicePagination from "../BodyMeasurementServicePagination";
import { useBodyMeasurementServiceContext } from "../../../../contexts/BodyMeasurementServiceContext";
import BodyMeasurementServiceImageDisplay from "../BodyMeasurementServiceImageDisplay";
import Loader from "../../../loading/Loader";
import { getValidMeasurements } from "../../../../utilities/helper/filterValidMeasurements";
import SizeGuideQuestionnaire from "../size-guide/SizeGuideQuestionnaire";
import MeasurementTable from "../measurement-display/MeasurementTable";

const Result: React.FC = () => {
  const { measurementData, sizeGuideResult } = useBodyMeasurementServiceContext();

  if (!measurementData) {
    return (
      <div className="flex justify-center w-full p-4 md:p-6">
        <Loader />
      </div>
    );
  }

  const validMeasurements = getValidMeasurements(measurementData);

  return (
    <div className="flex flex-col items-center w-full gap-32">
      <div className="flex flex-col items-center w-full gap-10">
        {/* Only show measurements if not showing size guide result */}
        {!sizeGuideResult && (
          <>
            <BodyMeasurementServiceImageDisplay />
            <MeasurementTable measurements={validMeasurements} />
          </>
        )}

        <SizeGuideQuestionnaire />

        <BodyMeasurementServicePagination handlerButtonText={"Try Again"} isMeasurementSuccessed={true} />
      </div>
    </div>
  );
};

export default Result;
