import React from "react";
import { Ticket } from "../../utilities/types/tickets/types";
import { Link } from "react-router-dom";
import MainButton from "../button/MainButton";
import { Eye } from "iconsax-react";
import StatusBadge from "../table/StatusBadge";
import { formatDate } from "../../utilities/helper/dateUtils";

interface MobileTicketCardProps {
  ticket: Ticket;
}

const MobileTicketCard: React.FC<MobileTicketCardProps> = ({ ticket }) => {
  return (
    <div className="flex flex-col items-center px-4 text-center *:w-full *:py-6 *:border-b *:border-b-gorgonzola-blue-100">
      <span className="font-medium line-clamp-1">Subject:{ticket.title}</span>
      <div className="grid grid-cols-2 *:place-content-center *:place-self-center overflow-hidden">
        <span className="text-xs font-medium">#{ticket.id}</span>

        <Link to={`/dashboard/support/${ticket.id}`} state={{ ticketId: ticket.id }} className="flex items-center justify-center w-full">
          <MainButton type="ghostOutlined">
            <Eye variant="Broken" className="mr-2 size-4" />
            Answer
          </MainButton>
        </Link>
      </div>

      <div className="grid grid-cols-2 *:place-content-center *:place-self-center overflow-hidden">
        <p className="w-full text-left line-clamp-1">Dep:{ticket.section}</p>
        {/* <StatusBadge
          statusText={ticket.status === "open" ? "answered" : ticket.status === "closed" ? "failed" : "waiting"}
          isSuccess={ticket.status === "open" ? true : ticket.status === "closed" ? false : null}
        /> */}
        <StatusBadge statusText={ticket.status} />
      </div>

      <div className="grid grid-cols-2 *:place-content-center *:place-self-center overflow-hidden">
        <p>{ticket.created_at ? formatDate(new Date(ticket.created_at * 1000).toLocaleDateString()) : "-"}</p>
        <p>{ticket.created_at ? new Date(ticket.created_at * 1000).toLocaleTimeString() : "-"}</p>
      </div>
    </div>
  );
};

export default MobileTicketCard;
