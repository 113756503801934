// import React, { useEffect, useState } from "react";
// import { useBodyMeasurementServiceContext } from "../../../../contexts/BodyMeasurementServiceContext";
// import { MeasurementResult } from "../../../../utilities/types/measurement-data/types";
// import { SizeGuideRequest } from "../../../../utilities/types/measurement-data/types";
// import Loader from "../../../loading/Loader";
// import MainButton from "../../../button/MainButton";
// import SizeGuideResult from "./SizeGuideResult";

// export interface QuestionnaireStep {
//   brand: string;
//   gender: string;
//   category: string;
//   type: string;
//   sizeType: string;
// }

// // Use the same interface as the SizeGuideRequest type
// export type SizeGuideData = SizeGuideRequest;

// const SizeGuideQuestionnaire: React.FC = () => {
//   const { availableCharts, fetchAvailableCharts, submitSizeGuide, measurementData, loadingSizeGuide, sizeGuideResult, resetSizeGuide, error } = useBodyMeasurementServiceContext();

//   const [currentStep, setCurrentStep] = useState<number>(0);
//   const [selectedItem, setSelectedItem] = useState<string>("");
//   const [selections, setSelections] = useState<QuestionnaireStep>({
//     brand: "",
//     gender: "",
//     category: "",
//     type: "",
//     sizeType: "",
//   });
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   // Track if we're in GenderFree mode to handle step navigation
//   const [isGenderFreeMode, setIsGenderFreeMode] = useState(false);
//   // Track if we have size types (Regular/PlusSize)
//   const [hasSizeTypes, setHasSizeTypes] = useState(false);
//   // Store types and sizeTypes to avoid recalculating in render
//   const [types, setTypes] = useState<string[]>([]);
//   const [sizeTypes, setSizeTypes] = useState<string[]>([]);

//   useEffect(() => {
//     fetchAvailableCharts();
//   }, []);

//   useEffect(() => {
//     if (sizeGuideResult) {
//       setIsModalOpen(true);
//     }
//   }, [sizeGuideResult]);

//   // Reset internal state when sizeGuideResult is null (when navigating back or clicking "Try Again")
//   useEffect(() => {
//     if (sizeGuideResult === null) {
//       setCurrentStep(0);
//       setSelectedItem("");
//       setIsGenderFreeMode(false);
//       setHasSizeTypes(false);
//       setTypes([]);
//       setSizeTypes([]);
//       setSelections({
//         brand: "",
//         gender: "",
//         category: "",
//         type: "",
//         sizeType: "",
//       });
//     }
//   }, [sizeGuideResult]);

//   // Effect to update types and hasSizeTypes when selections change
//   useEffect(() => {
//     if (!availableCharts) return;

//     let newTypes: string[] = [];
//     let hasTypes = false;

//     // Calculate types based on selections
//     if (isGenderFreeMode && selections.brand) {
//       // GenderFree is now an object with country codes as keys
//       if (availableCharts[selections.brand]?.GenderFree) {
//         newTypes = Object.keys(availableCharts[selections.brand].GenderFree);
//       }
//     } else if (selections.brand && selections.gender && selections.category) {
//       const categoryData = availableCharts[selections.brand]?.[selections.gender]?.[selections.category];
//       if (categoryData) {
//         newTypes = Object.keys(categoryData);

//         // Check if any of the type options have PlusSize/Regular options
//         // We'll check the first type option to determine this
//         const firstType = newTypes[0];
//         if (firstType && categoryData[firstType] && typeof categoryData[firstType] === "object") {
//           const sizeTypeKeys = Object.keys(categoryData[firstType]);
//           hasTypes = sizeTypeKeys.some((key) => key === "Regular" || key === "PlusSize" || key === "PLusSize");
//         }
//       }
//     }

//     setTypes(newTypes);
//     setHasSizeTypes(hasTypes);
//   }, [availableCharts, selections.brand, selections.gender, selections.category, isGenderFreeMode]);

//   // Effect to update sizeTypes when type selection changes
//   useEffect(() => {
//     if (!availableCharts || !selections.type) {
//       setSizeTypes([]);
//       return;
//     }

//     let newSizeTypes: string[] = [];

//     if (selections.brand && selections.gender && selections.category && selections.type) {
//       const typeData = availableCharts[selections.brand]?.[selections.gender]?.[selections.category]?.[selections.type];

//       if (typeData && typeof typeData === "object") {
//         newSizeTypes = Object.keys(typeData);
//         setHasSizeTypes(newSizeTypes.length > 0);
//       }
//     }

//     setSizeTypes(newSizeTypes);
//   }, [availableCharts, selections.type, selections.brand, selections.gender, selections.category]);

//   const handleCloseModal = () => {
//     setIsModalOpen(false);
//   };

//   const handleOpenModal = () => {
//     setIsModalOpen(true);
//   };

//   // Full reset function to reuse in multiple places
//   const handleFullReset = () => {
//     resetSizeGuide();
//     setCurrentStep(0);
//     setIsGenderFreeMode(false);
//     setHasSizeTypes(false);
//     setTypes([]);
//     setSizeTypes([]);
//     setSelectedItem("");
//     setSelections({
//       brand: "",
//       gender: "",
//       category: "",
//       type: "",
//       sizeType: "",
//     });
//   };

//   if (!availableCharts) return null;

//   const brands = Object.keys(availableCharts);
//   const genders = selections.brand ? Object.keys(availableCharts[selections.brand]) : [];
//   const categories = selections.gender && !isGenderFreeMode ? Object.keys(availableCharts[selections.brand][selections.gender]) : [];

//   // Define all possible steps
//   const allSteps = [
//     {
//       key: "brand",
//       title: "Brand",
//       description: "Choose your preferred brand",
//       options: brands,
//     },
//     {
//       key: "gender",
//       title: "Gender",
//       description: "Select the gender category",
//       options: genders,
//     },
//     {
//       key: "category",
//       title: "Category",
//       description: "Choose product category",
//       options: categories,
//     },
//     {
//       key: "type",
//       title: "Size",
//       description: "Select your preferred size guide by country",
//       options: types,
//     },
//     {
//       key: "sizeType",
//       title: "Fit",
//       description: "Select your preferred fit",
//       options: sizeTypes,
//     },
//   ];

//   // Filter steps based on mode and whether we have size types
//   const steps = (() => {
//     let filteredSteps = [...allSteps];

//     // Remove category step for GenderFree
//     if (isGenderFreeMode) {
//       filteredSteps = filteredSteps.filter((step) => step.key !== "category");
//     }

//     // Remove sizeType step if we don't have size types
//     if (!hasSizeTypes) {
//       filteredSteps = filteredSteps.filter((step) => step.key !== "sizeType");
//     }

//     return filteredSteps;
//   })();

//   const handleItemSelect = (value: string) => {
//     setSelectedItem(value);
//   };

//   const handleConfirmSelection = async () => {
//     if (!selectedItem) return;

//     const currentVisualStep = steps[currentStep];
//     const key = currentVisualStep.key as keyof QuestionnaireStep;

//     // Create a new selections object first without setting state
//     const newSelections = { ...selections, [key]: selectedItem };

//     // Handle different steps without nested setState calls
//     if (key === "brand") {
//       // Reset everything after brand
//       newSelections.gender = "";
//       newSelections.category = "";
//       newSelections.type = "";
//       newSelections.sizeType = "";

//       // Update selections first
//       setSelections(newSelections);
//       setIsGenderFreeMode(false); // Reset GenderFree mode when brand changes
//       setCurrentStep(currentStep + 1);
//     } else if (key === "gender") {
//       // Check if GenderFree is selected
//       if (selectedItem === "GenderFree") {
//         // For GenderFree, set category to empty and switch to GenderFree mode
//         newSelections.category = "";
//         newSelections.type = "";
//         newSelections.sizeType = "";

//         // Update selections first
//         setSelections(newSelections);
//         setIsGenderFreeMode(true);
//         setCurrentStep(currentStep + 1); // Move to next visual step
//       } else {
//         // Normal flow for non-GenderFree
//         newSelections.category = "";
//         newSelections.type = "";
//         newSelections.sizeType = "";

//         // Update selections first
//         setSelections(newSelections);
//         setIsGenderFreeMode(false);
//         setCurrentStep(currentStep + 1);
//       }
//     } else if (key === "category") {
//       newSelections.type = "";
//       newSelections.sizeType = "";

//       // Update selections first
//       setSelections(newSelections);
//       setCurrentStep(currentStep + 1);
//     } else if (key === "type") {
//       newSelections.sizeType = "";

//       // Update selections first
//       setSelections(newSelections);

//       // Check if we need to go to sizeType step
//       if (hasSizeTypes) {
//         const typeData = availableCharts[newSelections.brand]?.[newSelections.gender]?.[newSelections.category]?.[selectedItem];
//         if (typeData && typeof typeData === "object" && Object.keys(typeData).length > 0) {
//           setCurrentStep(currentStep + 1);
//           return;
//         }
//       }

//       // If no size types, prepare to submit
//       submitSizeGuideData(newSelections);
//     } else if (key === "sizeType") {
//       // Update selections first
//       setSelections(newSelections);

//       // Submit with the size type
//       submitSizeGuideData(newSelections);
//     }

//     setSelectedItem("");
//   };

//   // Helper function to submit size guide data
//   const submitSizeGuideData = (selectionData: QuestionnaireStep) => {
//     // Prepare size guide data for submission
//     const sizeData = measurementData
//       ? Object.entries(measurementData)
//           .filter(([key]) => !["Details", "MetaData"].includes(key))
//           .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})
//       : {};

//     // Build the KeyList based on the selections
//     const keyList = [];
//     keyList.push(selectionData.brand);
//     keyList.push(selectionData.gender);

//     if (!isGenderFreeMode) {
//       keyList.push(selectionData.category);
//     }

//     keyList.push(selectionData.type);

//     if (hasSizeTypes && selectionData.sizeType) {
//       keyList.push(selectionData.sizeType);
//     }

//     const sizeGuideData: SizeGuideData = {
//       // Brand: "Roots",
//       // Gender: "GenderFree",
//       // Category: "US",
//       // Type: "US",
//       KeyList: keyList,
//       Size: sizeData,
//     };

//     setCurrentStep(steps.length);
//     submitSizeGuide(sizeGuideData);
//   };

//   const handlePrevStep = () => {
//     // If we're viewing the results, do a full reset to start over
//     if (sizeGuideResult) {
//       handleFullReset();
//       return;
//     }

//     // Otherwise, just go back one step
//     if (currentStep > 0) {
//       setCurrentStep(currentStep - 1);
//       setSelectedItem("");
//     }
//   };

//   const renderStepContent = (step: typeof steps[0]) => (
//     <div className="grid grid-cols-2 gap-4">
//       {step.options.map((option: any) => (
//         <button
//           key={option}
//           onClick={() => handleItemSelect(option)}
//           className={`p-4 transition-colors border rounded-lg
//             ${selectedItem === option ? "border-gorgonzola-blue-300 bg-gorgonzola-blue-50" : "hover:bg-gray-50"}`}
//         >
//           {option}
//         </button>
//       ))}
//     </div>
//   );

//   // Process steps for display (we need to dynamically adjust based on GenderFree mode)
//   const displaySteps = [...steps, { title: "Results", key: "results" }];

//   // Normalize gender for SizeGuideResult
//   const normalizedGender = selections.gender ? selections.gender.toLowerCase() : "genderfree";

//   // Get the appropriate grid columns class based on the number of steps
//   const getGridColsClass = (stepsCount: number) => {
//     switch (stepsCount) {
//       case 1:
//         return "grid-cols-1";
//       case 2:
//         return "grid-cols-2";
//       case 3:
//         return "grid-cols-3";
//       case 4:
//         return "grid-cols-4";
//       case 5:
//         return "grid-cols-5";
//       case 6:
//         return "grid-cols-6";
//       default:
//         return "grid-cols-5";
//     }
//   };

//   const gridColsClass = getGridColsClass(displaySteps.length);

//   return (
//     <div className={`flex flex-col w-full gap-4 md:gap-6 md:p-6 lg:p-8 ${!sizeGuideResult ? "max-w-[40rem]" : ""}`}>
//       {!sizeGuideResult ? (
//         <div className={`relative grid gap-2 ${gridColsClass} *:flex-grow`}>
//           {displaySteps.map((step, index) => (
//             <div key={index} className="relative flex flex-col items-center">
//               {index < displaySteps.length - 1 && (
//                 <div className={`block absolute top-5 left-full -translate-x-1/2 w-full h-[2px] ${index < currentStep ? "bg-gorgonzola-blue-300" : "bg-closed-shutter-200"}`} />
//               )}

//               <div
//                 className={`w-10 h-10 rounded-full flex items-center justify-center border transition-all duration-600 bg-white z-20
//                 ${index <= currentStep ? "border-gorgonzola-blue-300 bg-gorgonzola-blue-50" : "border-closed-shutter-200"}`}
//               >
//                 {index + 1}
//               </div>
//               <span
//                 className={`hidden md:block mt-3.25 text-xs font-inter-medium
//                 ${index <= currentStep ? "text-gorgonzola-blue-300" : "text-closed-shutter-200"}`}
//               >
//                 {step.title}
//               </span>
//             </div>
//           ))}
//         </div>
//       ) : null}

//       {currentStep < steps.length ? (
//         <div className="space-y-2">
//           <h2 className="text-lg font-inter-bold text-closed-shutter-300">{steps[currentStep].title}</h2>
//           <p className="text-base font-inter-regular text-closed-shutter-200">{steps[currentStep].description}</p>
//           {renderStepContent(steps[currentStep])}
//         </div>
//       ) : loadingSizeGuide ? (
//         <div className="flex justify-center p-8">
//           <Loader />
//         </div>
//       ) : error ? (
//         <div className="flex flex-col items-center gap-4 p-4">
//           <h2 className="text-center text-closed-shutter-300">This size guide is not available at the moment. Please try another option.</h2>
//           <MainButton type="secondary" onClick={handleFullReset}>
//             Try Different Options
//           </MainButton>
//         </div>
//       ) : null}

//       {sizeGuideResult && (
//         <>
//           <SizeGuideResult results={sizeGuideResult} gender={normalizedGender as "men" | "women" | "genderfree"} />

//           <div className="flex justify-center mt-4 md:mt-6">
//             <MainButton type="secondary" onClick={handleFullReset}>
//               Start Over
//             </MainButton>
//           </div>
//         </>
//       )}

//       {!error && !sizeGuideResult ? (
//         <div className={`flex justify-between mt-2 md:mt-4 lg:mt-6`}>
//           <MainButton type="secondary" onClick={handlePrevStep} disabled={currentStep <= 0}>
//             Previous
//           </MainButton>

//           {currentStep < steps.length && (
//             <MainButton type="primary" onClick={handleConfirmSelection} disabled={!selectedItem}>
//               {currentStep === steps.length - 1 ? "Show Results" : "Next"}
//             </MainButton>
//           )}
//         </div>
//       ) : null}
//     </div>
//   );
// };

// export default SizeGuideQuestionnaire;

import React, { useEffect, useState } from "react";
import { useBodyMeasurementServiceContext } from "../../../../contexts/BodyMeasurementServiceContext";
import { MeasurementResult } from "../../../../utilities/types/measurement-data/types";
import { SizeGuideRequest } from "../../../../utilities/types/measurement-data/types";
import Loader from "../../../loading/Loader";
import MainButton from "../../../button/MainButton";
import SizeGuideResult from "./SizeGuideResult";

export interface QuestionnaireStep {
  brand: string;
  gender: string;
  category: string;
  type: string;
  sizeType: string;
}

// Use the same interface as the SizeGuideRequest type
export type SizeGuideData = SizeGuideRequest;

const SizeGuideQuestionnaire: React.FC = () => {
  const { availableCharts, fetchAvailableCharts, submitSizeGuide, measurementData, loadingSizeGuide, sizeGuideResult, resetSizeGuide, error } = useBodyMeasurementServiceContext();

  const [currentStep, setCurrentStep] = useState<number>(0);
  const [selectedItem, setSelectedItem] = useState<string>("");
  const [selections, setSelections] = useState<QuestionnaireStep>({
    brand: "",
    gender: "",
    category: "",
    type: "",
    sizeType: "",
  });
  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const [isGenderFreeMode, setIsGenderFreeMode] = useState(false);
  // Track if we have size types (Regular/PlusSize)
  const [hasSizeTypes, setHasSizeTypes] = useState(false);
  // Store types and sizeTypes to avoid recalculating in render
  const [types, setTypes] = useState<string[]>([]);
  const [sizeTypes, setSizeTypes] = useState<string[]>([]);

  useEffect(() => {
    fetchAvailableCharts();
  }, []);

  // useEffect(() => {
  //   if (sizeGuideResult) {
  //     setIsModalOpen(true);
  //   }
  // }, [sizeGuideResult]);

  // Reset internal state when sizeGuideResult is null (when navigating back or clicking "Try Again")
  useEffect(() => {
    if (sizeGuideResult === null) {
      setCurrentStep(0);
      setSelectedItem("");
      // setIsGenderFreeMode(false);
      setHasSizeTypes(false);
      setTypes([]);
      setSizeTypes([]);
      setSelections({
        brand: "",
        gender: "",
        category: "",
        type: "",
        sizeType: "",
      });
    }
  }, [sizeGuideResult]);

  // Effect to update types and hasSizeTypes when selections change
  useEffect(() => {
    if (!availableCharts) return;

    let newTypes: string[] = [];
    let hasTypes = false;

    // Calculate types based on selections
    // if (isGenderFreeMode && selections.brand) {
    //   // GenderFree is now an object with country codes as keys
    //   if (availableCharts[selections.brand]?.GenderFree) {
    //     newTypes = Object.keys(availableCharts[selections.brand].GenderFree);
    //   }
    // } else
    if (selections.brand && selections.gender && selections.category) {
      const categoryData = availableCharts[selections.brand]?.[selections.gender]?.[selections.category];
      if (categoryData) {
        newTypes = Object.keys(categoryData);

        // Check if any of the type options have PlusSize/Regular options
        // We'll check the first type option to determine this
        const firstType = newTypes[0];
        if (firstType && categoryData[firstType] && typeof categoryData[firstType] === "object") {
          const sizeTypeKeys = Object.keys(categoryData[firstType]);
          hasTypes = sizeTypeKeys.some((key) => key === "Regular" || key === "PlusSize" || key === "PLusSize");
        }
      }
    }

    setTypes(newTypes);
    setHasSizeTypes(hasTypes);
  }, [availableCharts, selections.brand, selections.gender, selections.category]);

  // Effect to update sizeTypes when type selection changes
  useEffect(() => {
    if (!availableCharts || !selections.type) {
      setSizeTypes([]);
      return;
    }

    let newSizeTypes: string[] = [];

    if (selections.brand && selections.gender && selections.category && selections.type) {
      const typeData = availableCharts[selections.brand]?.[selections.gender]?.[selections.category]?.[selections.type];

      if (typeData && typeof typeData === "object") {
        newSizeTypes = Object.keys(typeData);
        setHasSizeTypes(newSizeTypes.length > 0);
      }
    }

    setSizeTypes(newSizeTypes);
  }, [availableCharts, selections.type, selections.brand, selections.gender, selections.category]);

  // Full reset function to reuse in multiple places
  const handleFullReset = () => {
    resetSizeGuide();
    setCurrentStep(0);
    // setIsGenderFreeMode(false);
    setHasSizeTypes(false);
    setTypes([]);
    setSizeTypes([]);
    setSelectedItem("");
    setSelections({
      brand: "",
      gender: "",
      category: "",
      type: "",
      sizeType: "",
    });
  };

  if (!availableCharts) return null;

  const brands = Object.keys(availableCharts);
  const genders = selections.brand ? Object.keys(availableCharts[selections.brand]) : [];
  // Commented out GenderFree
  // const categories = selections.gender && !isGenderFreeMode ? Object.keys(availableCharts[selections.brand][selections.gender]) : [];
  const categories = selections.gender ? Object.keys(availableCharts[selections.brand][selections.gender]) : [];

  // Define all possible steps
  const allSteps = [
    {
      key: "brand",
      title: "Brand",
      description: "Choose your preferred brand",
      options: brands,
    },
    {
      key: "gender",
      title: "Gender",
      description: "Select the gender category",
      options: genders,
    },
    {
      key: "category",
      title: "Category",
      description: "Choose product category",
      options: categories,
    },
    {
      key: "type",
      title: "Size",
      description: "Select your preferred size guide by country",
      options: types,
    },
    {
      key: "sizeType",
      title: "Fit",
      description: "Select your preferred fit",
      options: sizeTypes,
    },
  ];

  // Filter steps based on mode and whether we have size types
  const steps = (() => {
    let filteredSteps = [...allSteps];

    // Commented out GenderFree
    // Remove category step for GenderFree
    // if (isGenderFreeMode) {
    //   filteredSteps = filteredSteps.filter((step) => step.key !== "category");
    // }

    // Remove sizeType step if we don't have size types
    if (!hasSizeTypes) {
      filteredSteps = filteredSteps.filter((step) => step.key !== "sizeType");
    }

    return filteredSteps;
  })();

  const handleItemSelect = (value: string) => {
    setSelectedItem(value);
  };

  const handleConfirmSelection = async () => {
    if (!selectedItem) return;

    const currentVisualStep = steps[currentStep];
    const key = currentVisualStep.key as keyof QuestionnaireStep;

    // Create a new selections object first without setting state
    const newSelections = { ...selections, [key]: selectedItem };

    // Handle different steps without nested setState calls
    if (key === "brand") {
      // Reset everything after brand
      newSelections.gender = "";
      newSelections.category = "";
      newSelections.type = "";
      newSelections.sizeType = "";

      // Update selections first
      setSelections(newSelections);
      // setIsGenderFreeMode(false);
      setCurrentStep(currentStep + 1);
    } else if (key === "gender") {
      // Commented out GenderFree
      // Check if GenderFree is selected
      // if (selectedItem === "GenderFree") {
      //   // For GenderFree, set category to empty and switch to GenderFree mode
      //   newSelections.category = "";
      //   newSelections.type = "";
      //   newSelections.sizeType = "";

      //   // Update selections first
      //   setSelections(newSelections);
      //   setIsGenderFreeMode(true);
      //   setCurrentStep(currentStep + 1); // Move to next visual step
      // } else {

      // Normal flow for all genders including GenderFree
      newSelections.category = "";
      newSelections.type = "";
      newSelections.sizeType = "";

      // Update selections first
      setSelections(newSelections);
      // setIsGenderFreeMode(false);
      setCurrentStep(currentStep + 1);
      // }
    } else if (key === "category") {
      newSelections.type = "";
      newSelections.sizeType = "";

      // Update selections first
      setSelections(newSelections);
      setCurrentStep(currentStep + 1);
    } else if (key === "type") {
      newSelections.sizeType = "";

      // Update selections first
      setSelections(newSelections);

      // Check if we need to go to sizeType step
      if (hasSizeTypes) {
        const typeData = availableCharts[newSelections.brand]?.[newSelections.gender]?.[newSelections.category]?.[selectedItem];
        if (typeData && typeof typeData === "object" && Object.keys(typeData).length > 0) {
          setCurrentStep(currentStep + 1);
          return;
        }
      }

      // If no size types, prepare to submit
      submitSizeGuideData(newSelections);
    } else if (key === "sizeType") {
      // Update selections first
      setSelections(newSelections);

      // Submit with the size type
      submitSizeGuideData(newSelections);
    }

    setSelectedItem("");
  };

  // Helper function to submit size guide data
  const submitSizeGuideData = (selectionData: QuestionnaireStep) => {
    // Prepare size guide data for submission
    const sizeData = measurementData
      ? Object.entries(measurementData)
          .filter(([key]) => !["Details", "MetaData"].includes(key))
          .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})
      : {};

    // Build the KeyList based on the selections
    const keyList = [];
    keyList.push(selectionData.brand);
    keyList.push(selectionData.gender);

    // Commented out GenderFree
    // if (!isGenderFreeMode) {
    //   keyList.push(selectionData.category);
    // }
    // Always include category for all genders
    keyList.push(selectionData.category);

    keyList.push(selectionData.type);

    if (hasSizeTypes && selectionData.sizeType) {
      keyList.push(selectionData.sizeType);
    }

    const sizeGuideData: SizeGuideData = {
      // Brand: "Roots",
      // Gender: "GenderFree",
      // Category: "US",
      // Type: "US",
      KeyList: keyList,
      Size: sizeData,
    };

    setCurrentStep(steps.length);
    submitSizeGuide(sizeGuideData);
  };

  const handlePrevStep = () => {
    // If we're viewing the results, do a full reset to start over
    if (sizeGuideResult) {
      handleFullReset();
      return;
    }

    // Otherwise, just go back one step
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
      setSelectedItem("");
    }
  };

  const renderStepContent = (step: typeof steps[0]) => (
    <div className="grid grid-cols-2 gap-4">
      {step.options.map((option: any) => (
        <button
          key={option}
          onClick={() => handleItemSelect(option)}
          className={`p-4 transition-colors border rounded-lg 
            ${selectedItem === option ? "border-gorgonzola-blue-300 bg-gorgonzola-blue-50" : "hover:bg-gray-50"}`}
        >
          {option}
        </button>
      ))}
    </div>
  );

  // Process steps for display (we need to dynamically adjust based on GenderFree mode)
  const displaySteps = [...steps, { title: "Results", key: "results" }];

  // Normalize gender for SizeGuideResult
  const normalizedGender = selections.gender ? selections.gender.toLowerCase() : "genderfree";

  // Get the appropriate grid columns class based on the number of steps
  const getGridColsClass = (stepsCount: number) => {
    switch (stepsCount) {
      case 1:
        return "grid-cols-1";
      case 2:
        return "grid-cols-2";
      case 3:
        return "grid-cols-3";
      case 4:
        return "grid-cols-4";
      case 5:
        return "grid-cols-5";
      case 6:
        return "grid-cols-6";
      default:
        return "grid-cols-5";
    }
  };

  const gridColsClass = getGridColsClass(displaySteps.length);

  return (
    <div className={`flex flex-col w-full gap-4 md:gap-6 md:p-6 lg:p-8 ${!sizeGuideResult ? "max-w-[40rem]" : ""}`}>
      {!sizeGuideResult ? (
        <div className={`relative grid gap-2 ${gridColsClass} *:flex-grow`}>
          {displaySteps.map((step, index) => (
            <div key={index} className="relative flex flex-col items-center">
              {index < displaySteps.length - 1 && (
                <div className={`block absolute top-5 left-full -translate-x-1/2 w-full h-[2px] ${index < currentStep ? "bg-gorgonzola-blue-300" : "bg-closed-shutter-200"}`} />
              )}

              <div
                className={`w-10 h-10 rounded-full flex items-center justify-center border transition-all duration-600 bg-white z-20 
                ${index <= currentStep ? "border-gorgonzola-blue-300 bg-gorgonzola-blue-50" : "border-closed-shutter-200"}`}
              >
                {index + 1}
              </div>
              <span
                className={`hidden md:block mt-3.25 text-xs font-inter-medium 
                ${index <= currentStep ? "text-gorgonzola-blue-300" : "text-closed-shutter-200"}`}
              >
                {step.title}
              </span>
            </div>
          ))}
        </div>
      ) : null}

      {currentStep < steps.length ? (
        <div className="space-y-2">
          <h2 className="text-lg font-inter-bold text-closed-shutter-300">{steps[currentStep].title}</h2>
          <p className="text-base font-inter-regular text-closed-shutter-200">{steps[currentStep].description}</p>
          {renderStepContent(steps[currentStep])}
        </div>
      ) : loadingSizeGuide ? (
        <div className="flex justify-center p-8">
          <Loader />
        </div>
      ) : error ? (
        <div className="flex flex-col items-center gap-4 p-4">
          <h2 className="text-center text-closed-shutter-300">This size guide is not available at the moment. Please try another option.</h2>
          <MainButton type="secondary" onClick={handleFullReset}>
            Try Different Options
          </MainButton>
        </div>
      ) : null}

      {sizeGuideResult && (
        <>
          <SizeGuideResult results={sizeGuideResult} gender={normalizedGender as "men" | "women" | "genderfree"} />

          <div className="flex justify-center mt-4 md:mt-6">
            <MainButton type="secondary" onClick={handleFullReset}>
              Start Over
            </MainButton>
          </div>
        </>
      )}

      {!error && !sizeGuideResult ? (
        <div className={`flex justify-between mt-2 md:mt-4 lg:mt-6`}>
          <MainButton type="secondary" onClick={handlePrevStep} disabled={currentStep <= 0}>
            Previous
          </MainButton>

          {currentStep < steps.length && (
            <MainButton type="primary" onClick={handleConfirmSelection} disabled={!selectedItem}>
              {currentStep === steps.length - 1 ? "Show Results" : "Next"}
            </MainButton>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default SizeGuideQuestionnaire;


