import React, { createContext, useState, useContext, useCallback } from "react";
import { AuthContext } from "./AuthContext";
import { ClothesDetectionResult, ClothingItem } from "../utilities/types/free-trial/auto-tagging-service/types";
import { convertToBase64 } from "../utilities/helper/convertToBase64";
import { searchSimilarClothes, visualClothesService } from "../utilities/api/visualClothesService";
import { SearchResponseItem, SearchResults } from "../utilities/types/free-trial/visual-clothes-service/types";

interface PaginationState {
  currentPage: number;
  totalPages: number;
}

interface SearchResultState {
  loading: boolean;
  error: string | null;
  results: SearchResponseItem[];
  pagination: PaginationState;
}

interface SearchResultsMap {
  [key: number]: SearchResultState;
}

interface VisualClothesServiceContextValue {
  uploadedImage: string | null;
  imageDimensions: { width: number; height: number } | null;
  scaleFactor: { x: number; y: number } | null;
  currentStep: number;
  canProceedToNextStep: boolean;
  visualClothesResult: ClothesDetectionResult | null;
  hoveredItem: ClothingItem | null;
  error: string | null;
  searchLoading: boolean;
  searchError: string | null;
  currentPage: number;
  totalPages: number;
  allSearchResults: SearchResults[];
  currentSearchResults: SearchResponseItem[];
  searchResults: SearchResponseItem[];
  firstLoadedItem: ClothingItem | null;
  isFirstServiceCalled: boolean;
  searchResultsMap: SearchResultsMap;
  handleItemPagination: (itemIndex: number, page: number) => Promise<void>;
  isItemLoading: (item: ClothingItem) => boolean;
  searchSimilarItems: () => Promise<void>;
  setError: (error: string | null) => void;
  handlePageChange: (page: number) => void;
  handleImageUpload: (image: File | undefined) => void;
  setCanProceedToNextStep: (value: boolean) => void;
  submitVisualClothes: () => Promise<void>;
  resetVisualClothesService: () => void;
  setHoveredItem: (item: ClothingItem | null) => void;
  getAdjustedCoordinates: (region: ClothingItem["Region"]) => { X1: number; Y1: number; X2: number; Y2: number };
}

export const VisualClothesServiceContext = createContext<VisualClothesServiceContextValue | undefined>(undefined);

export const VisualClothesServiceProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [uploadedImage, setUploadedImage] = useState<string | null>(null);
  const [imageDimensions, setImageDimensions] = useState<{ width: number; height: number } | null>(null);
  const [scaleFactor, setScaleFactor] = useState<{ x: number; y: number } | null>(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [canProceedToNextStep, setCanProceedToNextStep] = useState(false);
  const [visualClothesResult, setVisualClothesResult] = useState<ClothesDetectionResult | null>(null);
  const [hoveredItem, setHoveredItem] = useState<ClothingItem | null>(null);
  const { accessToken } = useContext(AuthContext);
  const [error, setError] = useState<string | null>(null);
  const [searchResults, setSearchResults] = useState<SearchResponseItem[]>([]);
  const [allSearchResults, setAllSearchResults] = useState<SearchResults[]>([]);
  const [currentSearchResults, setCurrentSearchResults] = useState<SearchResponseItem[]>([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchError, setSearchError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchResultsMap, setSearchResultsMap] = useState<SearchResultsMap>({});
  const [firstLoadedItem, setFirstLoadedItem] = useState<ClothingItem | null>(null);
  const [isFirstServiceCalled, setIsFirstServiceCalled] = useState(false);

  const handlePageChange = (page: number) => {
    setError(null);
    setCurrentStep(page);
    setCanProceedToNextStep(false);
  };

  const handleImageUpload = useCallback(async (image: File | undefined) => {
    if (image) {
      setError(null);

      const originalBase64 = await convertToBase64(image);
      setUploadedImage(originalBase64);

      const img = new Image();
      await new Promise((resolve) => {
        img.onload = () => {
          const originalWidth = img.naturalWidth;
          const originalHeight = img.naturalHeight;

          const containerWidth = 240; // 15rem
          const containerHeight = 400; // 25rem

          const scale = Math.min(containerWidth / originalWidth, containerHeight / originalHeight);

          const scaledWidth = originalWidth * scale;
          const scaledHeight = originalHeight * scale;

          const x = (containerWidth - scaledWidth) / 2;
          const y = (containerHeight - scaledHeight) / 2;

          setImageDimensions({
            width: originalWidth,
            height: originalHeight,
          });

          setScaleFactor({
            x: scale,
            y: scale,
          });
        };
        img.src = URL.createObjectURL(image);
      });

      setCanProceedToNextStep(true);
    }
  }, []);

  const submitVisualClothes = async () => {
    if (!uploadedImage || !accessToken) {
      setError("No image uploaded or access token missing");
      return;
    }

    try {
      const response = await visualClothesService(accessToken, uploadedImage);
      if (!response.ok) {
        setError(response.message || "Failed to process image");
      }

      if (response.ok && response.data.result.Clothes.length > 0) {
        setVisualClothesResult(response.data.result);
        setCanProceedToNextStep(true);
        setCurrentStep(2);
        setError(null);
      } else if (!response.data.result.Clothes.length) {
        setError("The image couldn't be processed. Please try again with a different image.");
        setCurrentStep(1);
      } else {
        setError(response.message || "Failed to process image");
        setCurrentStep(1);
      }
    } catch (error) {
      console.error("Error submitting auto tagging:", error);
      // setError("An error occurred during auto tagging. Please try again.");
      setCurrentStep(1);
    }
  };

  const searchSimilarItems = async () => {
    if (!visualClothesResult?.Clothes.length || !accessToken) {
      setSearchError("No detected clothes or missing access token");
      return;
    }

    setSearchLoading(true);
    setSearchError(null);
    setIsFirstServiceCalled(false);

    try {
      const initialMap: SearchResultsMap = {};
      visualClothesResult.Clothes.forEach((_, index) => {
        initialMap[index] = {
          loading: true,
          error: null,
          results: [],
          pagination: {
            currentPage: 1,
            totalPages: 1,
          },
        };
      });
      setSearchResultsMap(initialMap);

      const searchPromises = visualClothesResult.Clothes.map(async (item, index) => {
        const params: any = {
          ...item,
          Country: "us",
          Count: 3,
          Page: currentPage,
        };

        try {
          const response = await searchSimilarClothes(accessToken, params);

          if (response.ok) {
            setSearchResultsMap((prev) => ({
              ...prev,
              [index]: {
                loading: false,
                error: null,
                results: response.data.Result,
                pagination: {
                  currentPage: response.data.current_page,
                  totalPages: response.data.last_page,
                },
              },
            }));

            if (index === 0) {
              setIsFirstServiceCalled(true);
              setHoveredItem(item);
              setCurrentSearchResults(response.data.Result);
              setTotalPages(response.data.last_page);
            }
          } else {
            throw new Error(response.message || "Failed to fetch similar items");
          }
        } catch (error) {
          setSearchResultsMap((prev) => ({
            ...prev,
            [index]: {
              loading: false,
              // error: error instanceof Error ? error.message : "Failed to fetch similar items",
              error: "Failed to fetch similar items",
              results: [],
              pagination: {
                currentPage: 1,
                totalPages: 1,
              },
            },
          }));

          if (index === 0) {
            // setSearchError(error instanceof Error ? error.message : "Failed to fetch similar items");
            setSearchError("Failed to fetch similar items");
          }
        }
      });

      await Promise.all(searchPromises);
    } catch (error) {
      setSearchError("Failed to fetch similar items");
    } finally {
      setSearchLoading(false);
    }
  };

  const handleItemPagination = async (itemIndex: number, pageNumber: number): Promise<void> => {
    if (!visualClothesResult?.Clothes || !accessToken) {
      return;
    }

    const targetItem = visualClothesResult.Clothes[itemIndex];

    setSearchResultsMap((prev) => ({
      ...prev,
      [itemIndex]: {
        ...prev[itemIndex],
        loading: true,
        error: null,
      },
    }));

    try {
      const params: any = {
        ...targetItem,
        Country: "us",
        Count: 3,
        Page: pageNumber,
      };

      const response = await searchSimilarClothes(accessToken, params);

      if (response.ok) {
        setSearchResultsMap((prev) => ({
          ...prev,
          [itemIndex]: {
            loading: false,
            error: null,
            results: response.data.Result,
            pagination: {
              currentPage: response.data.current_page,
              totalPages: response.data.last_page,
            },
          },
        }));

        if (hoveredItem === visualClothesResult.Clothes[itemIndex]) {
          setCurrentSearchResults(response.data.Result);
          setCurrentPage(response.data.current_page);
          setTotalPages(response.data.last_page);
        }
      } else {
        throw new Error("Failed to fetch page");
      }
    } catch (error) {
      setSearchResultsMap((prev) => ({
        ...prev,
        [itemIndex]: {
          ...prev[itemIndex],
          loading: false,
          error: "Failed to fetch page",
          pagination: {
            ...prev[itemIndex].pagination,
          },
        },
      }));
    }
  };

  const handleHoveredItemChange = useCallback(
    (item: ClothingItem | null) => {
      if (!item || !visualClothesResult) {
        setHoveredItem(null);
        return;
      }

      setHoveredItem(item);

      const itemIndex = visualClothesResult.Clothes.findIndex((clothe) => clothe === item);

      const itemState = searchResultsMap[itemIndex];

      if (itemState?.loading) {
        setCurrentSearchResults([]);
      } else if (itemState?.results) {
        setCurrentSearchResults(itemState.results);
      }
    },
    [visualClothesResult, searchResultsMap]
  );

  const isItemLoading = useCallback(
    (item: ClothingItem) => {
      if (!visualClothesResult || !visualClothesResult.Clothes.length) return false;
      const itemIndex = visualClothesResult.Clothes.findIndex((clothe) => clothe === item);
      return searchResultsMap[itemIndex]?.loading ?? true;
    },
    [visualClothesResult, searchResultsMap]
  );

  const getAdjustedCoordinates = useCallback(
    (region: ClothingItem["Region"]) => {
      if (!imageDimensions || !scaleFactor) return region;

      const containerWidth = 240; // 15rem
      const containerHeight = 400; // 25rem

      const scaledWidth = imageDimensions.width * scaleFactor.x;
      const scaledHeight = imageDimensions.height * scaleFactor.y;
      const offsetX = (containerWidth - scaledWidth) / 2;
      const offsetY = (containerHeight - scaledHeight) / 2;

      return {
        X1: ((region.X1 * scaleFactor.x + offsetX) / containerWidth) * 100,
        Y1: ((region.Y1 * scaleFactor.y + offsetY) / containerHeight) * 100,
        X2: ((region.X2 * scaleFactor.x + offsetX) / containerWidth) * 100,
        Y2: ((region.Y2 * scaleFactor.y + offsetY) / containerHeight) * 100,
      };
    },
    [imageDimensions, scaleFactor]
  );

  const resetVisualClothesService = () => {
    setUploadedImage(null);
    setImageDimensions(null);
    setScaleFactor(null);
    setCurrentStep(0);
    setCanProceedToNextStep(false);
    setVisualClothesResult(null);
    setHoveredItem(null);
    setError(null);
    setSearchResults([]);
    setAllSearchResults([]);
    setCurrentSearchResults([]);
    setSearchError(null);
    setSearchLoading(false);
    setCurrentPage(1);
    setTotalPages(1);
  };

  return (
    <VisualClothesServiceContext.Provider
      value={{
        uploadedImage,
        imageDimensions,
        scaleFactor,
        currentStep,
        canProceedToNextStep,
        visualClothesResult,
        hoveredItem,
        error,
        setError,
        searchResults,
        allSearchResults,
        currentSearchResults,
        searchLoading,
        searchError,
        currentPage,
        totalPages,
        isItemLoading,
        searchResultsMap,
        firstLoadedItem,
        isFirstServiceCalled,
        searchSimilarItems,
        handleItemPagination,
        handlePageChange,
        handleImageUpload,
        setCanProceedToNextStep,
        submitVisualClothes,
        resetVisualClothesService,
        setHoveredItem: handleHoveredItemChange,
        getAdjustedCoordinates,
      }}
    >
      {children}
    </VisualClothesServiceContext.Provider>
  );
};

export const useVisualClothesServiceContext = () => {
  const context = useContext(VisualClothesServiceContext);
  if (!context) {
    throw new Error("useVisualClothesServiceContext must be used within an VisualClothesServiceProvider");
  }
  return context;
};
