import React, { forwardRef } from "react";
import { useVisualClothesServiceContext } from "../../../contexts/VisualClothesServiceContext";

interface VisualClothesServiceImageDisplayProps {
  className?: string;
}

const VisualClothesServiceImageDisplay = forwardRef<HTMLImageElement, VisualClothesServiceImageDisplayProps>(({ className }, ref) => {
  const { uploadedImage } = useVisualClothesServiceContext();

  return (
    <div className="grid place-content-center w-[15rem] h-[25rem] border-2 rounded-md border-gorgonzola-blue-50 overflow-hidden">
      {uploadedImage ? (
        <img ref={ref} src={`data:image/png;base64,${uploadedImage}`} alt="Uploaded clothes" className={`object-contain w-[15rem] h-[25rem] ${className || ""}`} />
      ) : (
        <div className="grid w-full place-content-center">
          <img
            ref={ref}
            src="/assets/image/free-trial/visual-clothes-search/visual-clothes-search.png"
            alt="Default visual clothes"
            className={`object-contain w-[15rem] h-[25rem] ${className || ""}`}
          />
        </div>
      )}
    </div>
  );
});

VisualClothesServiceImageDisplay.displayName = "VisualClothesServiceImageDisplay";

export default VisualClothesServiceImageDisplay;
