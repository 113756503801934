import React from "react";
import { Link } from "react-router-dom";
import MainButton from "../../button/MainButton";
import { Eye } from "iconsax-react";
import StatusBadge from "../../table/StatusBadge";
import { TransformTime } from "../../../utilities/helper/TransformPublishedAt";

interface MobileTicketCardProps {
  ticket: any;
}

const MobileTicketCard: React.FC<MobileTicketCardProps> = ({ ticket }) => {
  return (
    <div className="flex flex-col items-center px-4 text-center *:w-full *:py-6 *:border-b *:border-b-gorgonzola-blue-100 text-closed-shutter-300">
      <span className="font-medium line-clamp-1">{ticket.user_email}</span>

      <div className="grid grid-cols-2 gap-2 ">
        <div>
          <p className="text-xs text-gray-500">Subject</p>
          <p>{ticket.title}</p>
        </div>
        <div>
          <p className="text-xs text-gray-500">Status</p>
          <div className="flex justify-center">
            <StatusBadge statusText={ticket.status} />
          </div>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-2 *:space-y-2">
        <div>
          <p className="text-xs text-gray-500">Date</p>
          <p>{ticket.created_at ? new Date(ticket.created_at * 1000).toLocaleDateString() : "-"}</p>
        </div>
        <div>
          <p className="text-xs text-gray-500">Time</p>
          <p>{ticket.created_at ? TransformTime(new Date(ticket.created_at * 1000).toISOString()) : "-"}</p>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-2">
        <div>
          <p className="text-xs text-gray-500">Department</p>
          <p>{ticket.section}</p>
        </div>

        <Link to={`/admin/tickets/${ticket.id}`} className="flex justify-center w-full">
          <MainButton type={ticket.status === "open" ? "primary" : "ghostOutlined"}>
            <Eye variant="Broken" className="mr-2 size-4" />
            Answer
          </MainButton>
        </Link>
      </div>
    </div>
  );
};

export default MobileTicketCard;
