import React, { useEffect } from "react";
import { useVisualClothesServiceContext } from "../../../../contexts/VisualClothesServiceContext";
import VisualClothesServiceLoader from "../../FreeTrialServicesLoader";
import VisualClothesServiceImageDisplay from "../VisualClothesServiceImageDisplay";
import ErrorDisplay from "../../ErrorDisplay";
import { useNavigate } from "react-router-dom";

const VisualClothesServiceProssesing: React.FC = () => {
  const { submitVisualClothes, error, setError, resetVisualClothesService } = useVisualClothesServiceContext();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchVisualClothesData = async () => {
      await submitVisualClothes();
    };
    fetchVisualClothesData();
  }, []);

  if (error) {
    return (
      <ErrorDisplay
        error={error}
        onRetry={() => {
          setError(null);

          // submitVisualClothes();
          resetVisualClothesService();
          navigate("/dashboard/free-trial/visual-clothes-search");
        }}
      />
    );
  }

  return (
    <div className="flex flex-col items-center gap-32 w-full md:w-[20rem]">
      <VisualClothesServiceLoader />

      <div className="flex flex-col items-center gap-10">
        <span className="font-bold text-center capitalize text-closed-shutter-300">Processing...</span>
        <VisualClothesServiceImageDisplay />
      </div>
    </div>
  );
};

export default VisualClothesServiceProssesing;
