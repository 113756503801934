import React, { useState, useContext, useEffect } from "react";
import { SHAKU_PLANS, SHAKU_PLANS_CATEGORY } from "../../utilities/defaults/plans/shaku-plans";
import ShakuPlansItem from "./ShakuPlansItem";
import ShakuPlansItemMobileSlider from "./ShakuPlansItemMobileSlider";
import MainButton from "../button/MainButton";
import { getCheckout } from "../../utilities/api/planService";
import { AuthContext } from "../../contexts/AuthContext";
import { enqueueSnackbar } from "notistack";
import Loader from "../loading/Loader";
import { ShakuPlan } from "../../utilities/types/plan/types";
import ConfirmationModal from "../modal/ConfirmationModal";
import { useScrollToElement } from "../../hooks/useScrollToElement";
import { AnimatePresence, motion } from "framer-motion";

const ShakuPlans: React.FC = () => {
  const [activeCategory, setActiveCategory] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [checkoutData, setCheckoutData] = useState<any>(null);
  const [selectedPlanCheckoutId, setSelectedPlanCheckoutId] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { accessToken } = useContext(AuthContext);

  const plans = SHAKU_PLANS.find((category) => category.categoryId === activeCategory)?.data || [];
  useScrollToElement();

  useEffect(() => {
    const hash = window.location.hash;
    if (hash === "#buy_plan") {
      const element = document.getElementById("buy_plan");
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({ behavior: "smooth" });
        }, 100);
      }
    }
  }, []);

  const handleCategoryClick = (id: number) => {
    setActiveCategory(id);
  };

  const handlePlanClick = async (plan: ShakuPlan) => {
    if (plan.checkoutId === null) {
      enqueueSnackbar("You've successfully selected the free plan!", { variant: "success" });
      return;
    }

    setIsLoading(true);
    try {
      const response = await getCheckout(accessToken!, plan.checkoutId);
      setCheckoutData(response.data);
      setSelectedPlanCheckoutId(plan.checkoutId);
      setShowModal(true);
    } catch (error) {
      enqueueSnackbar("Unable to retrieve checkout information. Please try again.", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center w-full gap-8" id="buy_plan">
      <div className="flex flex-col items-center w-full md:w-[80%] gap-3 text-center lg:gap-6 *:capitalize">
        <h2 className="text-xs lg:text-base text-gorgonzola-blue-300">Maximum Features at the Most Affordable Price</h2>
        <h3 className="w-full text-lg font-bold text-closed-shutter-300">
          Boost conversions and take your<span className="text-gorgonzola-blue-300"> business to the next level </span>with our game-changing solutions.
        </h3>
      </div>

      <div className="flex flex-col items-center w-full gap-6 md:gap-10">
        <div className="flex flex-col items-center gap-3 p-3 rounded-full md:flex-row lg:bg-white">
          {SHAKU_PLANS_CATEGORY.map((plan, index) => (
            <MainButton key={index} type={plan.id === activeCategory ? "primary" : "secondary"} onClick={() => handleCategoryClick(plan.id)}>
              <div className="*:size-6">
                <span className="*:size-6" dangerouslySetInnerHTML={{ __html: plan.svg }} />
              </div>
              <span>{plan.title}</span>
            </MainButton>
          ))}
        </div>

        <AnimatePresence mode="wait">
          <motion.div
            key={activeCategory}
            className="hidden gap-4 xl:grid xl:grid-cols-3 xxl:grid-cols-5 w-fit"
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -50 }}
            transition={{ duration: 0.6 }}
          >
            {plans.map((plan) => (
              <ShakuPlansItem key={plan.id} plan={plan} onClick={() => handlePlanClick(plan)} />
            ))}
          </motion.div>
        </AnimatePresence>

        {/* <div className="hidden gap-4 xl:grid xl:grid-cols-3 xxl:grid-cols-5 w-fit">
          {plans.map((plan) => (
            <ShakuPlansItem key={plan.id} plan={plan} onClick={() => handlePlanClick(plan)} />
          ))}
        </div> */}

        {/* <div className="block xl:hidden w-full lg:max-w-[70%]">
          <ShakuPlansItemMobileSlider plans={plans} onPlanClick={handlePlanClick} />
        </div> */}

        <AnimatePresence mode="wait">
          <motion.div
            key={activeCategory}
            className="block xl:hidden w-full lg:max-w-[70%]"
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -50 }}
            transition={{ duration: 0.6 }}
          >
            <ShakuPlansItemMobileSlider plans={plans} onPlanClick={handlePlanClick} />
          </motion.div>
        </AnimatePresence>
      </div>

      {isLoading ? (
        <div className="fixed inset-0 z-[9999] flex items-center justify-center bg-black bg-opacity-50">
          <div className="flex flex-col items-center p-6 bg-white rounded-lg">
            <Loader />
            <p className="mt-4 text-center">Loading checkout information...</p>
          </div>
        </div>
      ) : (
        <ConfirmationModal isOpen={showModal} onClose={() => setShowModal(false)} checkoutData={checkoutData} checkoutId={selectedPlanCheckoutId} />
      )}
    </div>
  );
};

export default ShakuPlans;
