import React from "react";
import { useBodyMeasurementServiceContext } from "../../../../contexts/BodyMeasurementServiceContext";
import BodyMeasurementServiceImageDisplay from "../BodyMeasurementServiceImageDisplay";
import BodyMeasurementServicePagination from "../BodyMeasurementServicePagination";

const Height: React.FC = () => {
  const { selectedUnit, displayValue, handleUnitChange, getValidationProps, handleHeightChange } = useBodyMeasurementServiceContext();
  
  return (
    <div className="flex flex-col items-center w-full gap-32">
      <div className="flex flex-col items-center w-full max-w-xl gap-6">
        {/* Height Input Section */}
        <div className="flex flex-col items-center w-full gap-6">
          <div className="space-y-2 text-center">
            <h2 className="text-[24px] font-medium text-closed-shutter-400">Enter Your Height</h2>
            <p className="text-xs text-closed-shutter-300/70">This helps us provide accurate measurements</p>
          </div>

          <div className="flex flex-col w-full max-w-sm gap-8">
            {/* Height Input Group */}
            <div className="relative">
              {/* Unit Toggle */}
              <div className="absolute -translate-y-1/2 right-2 top-1/2">
                <div className="flex items-center p-1 border rounded-full bg-gorgonzola-blue-50/20 border-gorgonzola-blue-100">
                  {(["cm", "inch"] as const).map((unit) => (
                    <button
                      key={unit}
                      onClick={() => handleUnitChange(unit)}
                      className={`px-3 py-1.5 rounded-full text-xs font-medium transition-all duration-300
                        ${selectedUnit === unit ? "bg-white text-gorgonzola-blue-400 shadow-sm" : "text-closed-shutter-300 hover:text-closed-shutter-400"}`}
                    >
                      {unit.toUpperCase()}
                    </button>
                  ))}
                </div>
              </div>

              <input
                id="userHeight"
                type="number"
                value={displayValue || ""}
                {...getValidationProps()}
                placeholder={selectedUnit === "cm" ? "180" : "70.9"}
                onChange={handleHeightChange}
                className="w-full py-4 pl-4 pr-32 text-base transition-all duration-300 border outline-none rounded-xl text-closed-shutter-400 placeholder:text-closed-shutter-200 border-gorgonzola-blue-100 focus:border-gorgonzola-blue-300 focus:ring-2 focus:ring-gorgonzola-blue-50"
              />
            </div>
          </div>
        </div>

        {/* Image Display */}
        <div className="w-full max-w-sm p-4 border rounded-xl border-gorgonzola-blue-50">
          <BodyMeasurementServiceImageDisplay />
        </div>
      </div>

      <BodyMeasurementServicePagination />
    </div>
  );
};

export default Height;
