import React, { useState, useEffect, useContext, useMemo } from "react";
import Select, { components } from "react-select";
import { motion } from "framer-motion";
import { ArrowDown2, ArrowRight2 } from "iconsax-react";
import { AuthContext } from "../../contexts/AuthContext";
import { getRequestCharts } from "../../utilities/api/requestChartsService";
import StatisticItem from "./StatisticItem";
import { useTimestamp } from "../../hooks/useTimestamp";
import Loader from "../loading/Loader";
import { getUserUsageTotal } from "../../utilities/api/adminService";

interface UserStatisticsData {
  total: number;
  success: number;
  failed: number;
}

interface UserStatisticsProps {
  userCreatedAt: number;
  isAdmin?: boolean;
  userId?: string;
}

interface MonthOption {
  value: string;
  label: string;
}

const UserStatistics: React.FC<UserStatisticsProps> = ({ userCreatedAt, isAdmin = false, userId }) => {
  const { getTimestamp } = useTimestamp();
  const { accessToken } = useContext(AuthContext);
  const [selectedDate, setSelectedDate] = useState<{ value: string; label: string } | null>(null);
  const [statisticsData, setStatisticsData] = useState<UserStatisticsData | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const dateOptions = useMemo(() => {
    const options: MonthOption[] = [];
    const creationDate = new Date(userCreatedAt * 1000);
    const currentDate = new Date();

    creationDate.setDate(1);
    creationDate.setHours(0, 0, 0, 0);

    currentDate.setDate(1);
    currentDate.setHours(0, 0, 0, 0);

    while (creationDate <= currentDate) {
      const value = `${creationDate.getFullYear()}-${String(creationDate.getMonth() + 1).padStart(2, "0")}`;
      const label = new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "long",
      }).format(creationDate);

      options.unshift({ value, label });
      creationDate.setMonth(creationDate.getMonth() + 1);
    }

    return options;
  }, [userCreatedAt]);

  useEffect(() => {
    const fetchCharts = async () => {
      if (!accessToken || !selectedDate) return;

      setIsLoading(true);

      const [year, month] = selectedDate.value.split("-");
      const lastDay = new Date(parseInt(year), parseInt(month), 0).getDate();

      const fromDate = getTimestamp(new Date(`${year}-${month}-01`));
      const toDate = getTimestamp(new Date(`${year}-${month}-${lastDay}`));

      try {
        let response;
        if (isAdmin && userId) {
          response = await getUserUsageTotal(accessToken, userId, { from_date: fromDate.toString(), to_date: toDate.toString() }, "monthly", Intl.DateTimeFormat().resolvedOptions().timeZone);
        } else {
          response = await getRequestCharts(accessToken, {
            from_date: fromDate.toString(),
            to_date: toDate.toString(),
            point: "monthly",
            client_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          });
        }

        if (response.ok) {
          const data = response.data.chart[0];
          setStatisticsData({
            total: data.total,
            success: data.success,
            failed: data.error,
          });
        } else {
          console.error("Error fetching charts:", response.message);
        }
      } catch (error) {
        console.error("Failed to fetch charts:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCharts();
  }, [accessToken, selectedDate, isAdmin, userId]);

  useEffect(() => {
    if (dateOptions.length > 0 && !selectedDate) {
      setSelectedDate(dateOptions[0]);
    }
  }, [dateOptions, selectedDate]);

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      padding: "0.25rem 0.5rem",
      border: "1px solid #EDEDFA",
      borderRadius: "0.375rem",
      boxShadow: "none",
      transition: "0.6s linear",
      "&:hover": {
        border: "1px solid #C6C7F0",
      },
      cursor: "pointer",
    }),
    option: (provided: any, state: { isSelected: any; isFocused: any }) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#EDEDFA" : "white",
      padding: "0.5rem",
      color: "#484554c2",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#EDEDFA",
      },
      borderBottom: "1px solid #C6C7F0",
      "&:last-child": {
        borderBottom: "none",
      },
    }),
    menu: (provided: any) => ({
      ...provided,
      overflow: "hidden",
      borderRadius: "0.375rem",
      boxShadow: "none",
      border: "1px solid #C6C7F0",
      "&:hover": {
        borderColor: "#C6C7F0",
      },
    }),
    menuList: (provided: any) => ({
      ...provided,
      maxHeight: "15rem",
      padding: 0,
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        margin: "0.5rem 0",
        width: "0.75rem",
      },
      "&::-webkit-scrollbar-track": {
        background: "#EDEDFA",
        borderRadius: "1rem",
      },
      "&::-webkit-scrollbar-thumb": {
        position: "relative",
        background: "#FFFFFF",
        border: "1px solid #E3E4F8",
        borderRadius: "4px",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: "#FFFFFF",
      },
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: "#484554c2",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      color: "#A0AEC0",
    }),
  };

  const DropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        {isOpen ? <ArrowDown2 variant="Broken" className="size-5 text-closed-shutter-300" /> : <ArrowRight2 variant="Broken" className="size-5 text-closed-shutter-300" />}
      </components.DropdownIndicator>
    );
  };

  const calculateSize = (value: number, total: number): number => {
    const baseSize = 224;
    const proportion = value / total;
    return Math.max(baseSize * proportion, 48);
  };
  const calculateGradientPercentage = (value: number, total: number): number => {
    if (value === total) return 3;
    return (value / total) * 100;
  };
  return (
    <div className="flex justify-center gap-4 p-4 overflow-hidden border rounded-md w-full xl:w-[26rem] xl:min-w-[26rem] min-h-max border-gorgonzola-blue-50">
      <div className="flex flex-col gap-10 w-full md:min-h-[22.25rem]">
        <div className="flex flex-col items-center justify-between gap-4 md:gap-0 md:flex-row">
          <h4 className="font-bold text-closed-shutter-300">Users</h4>
          <Select
            value={selectedDate}
            onChange={(newValue) => setSelectedDate(newValue as { value: string; label: string })}
            options={dateOptions}
            styles={customStyles}
            components={{
              IndicatorSeparator: () => null,
              DropdownIndicator,
            }}
            onMenuOpen={() => setIsOpen(true)}
            onMenuClose={() => setIsOpen(false)}
            isSearchable={false}
            className="z-20"
          />
        </div>
        <div className="flex flex-col items-center lg:items-start lg:flex-row flex-grow justify-between w-full lg:*:min-h-max gap-6 lg:gap-0">
          {isLoading ? (
            <div className="flex justify-center w-full ">
              <Loader />
            </div>
          ) : statisticsData ? (
            <>
              <div className="flex flex-row justify-center h-full gap-10 lg:flex-col *:text-center lg:*:text-start">
                <motion.div initial={{ opacity: 0, x: -20 }} animate={{ opacity: 1, x: 0 }} transition={{ duration: 0.5 }}>
                  <StatisticItem color="#2B2D7C" label="Total" value={statisticsData.total} />
                </motion.div>

                <motion.div initial={{ opacity: 0, x: -20 }} animate={{ opacity: 1, x: 0 }} transition={{ duration: 0.5, delay: 0.2 }}>
                  <StatisticItem color="#006557" label="Success" value={statisticsData.success} />
                </motion.div>

                <motion.div initial={{ opacity: 0, x: -20 }} animate={{ opacity: 1, x: 0 }} transition={{ duration: 0.5, delay: 0.4 }}>
                  <StatisticItem color="#7C2B2D" label="Failed" value={statisticsData.failed} />
                </motion.div>
              </div>

              <div className="relative flex items-center justify-center size-56 min-h-56">
                {/* Total circle - remains constant size */}
                <motion.div className="absolute rounded-full size-56 min-h-56 *:rounded-full " transition={{ duration: 10, repeat: Infinity, ease: "linear" }} animate={{ rotate: -360 }}>
                  <span
                    className="absolute inset-0 rounded-full"
                    style={{
                      background: `linear-gradient(to bottom, white 0%, #2b2d7c 100%)`,
                    }}
                  />
                  <span className="absolute inset-1/2 -translate-x-1/2 -translate-y-1/2 size-[calc(100%-8px)] bg-white" />
                </motion.div>

                {/* Success circle - with matching style */}
                <motion.div className="absolute rounded-full size-40 *:rounded-full" transition={{ duration: 15, repeat: Infinity, ease: "linear" }} animate={{ rotate: 360 }}>
                  <span
                    className="absolute inset-0 rounded-full"
                    style={{
                      background: `linear-gradient(to right, #006557 0%, white ${calculateGradientPercentage(statisticsData.success, statisticsData.total)}%)`,
                      opacity: 0.85,
                    }}
                  />
                  <span className="absolute inset-1/2 -translate-x-1/2 -translate-y-1/2 size-[calc(100%-8px)] bg-white" />
                </motion.div>

                {/* Failed circle - with matching style */}
                <motion.div className="absolute rounded-full size-24 *:rounded-full" transition={{ duration: 20, repeat: Infinity, ease: "linear" }} animate={{ rotate: -360 }}>
                  <span
                    className="absolute inset-0 rounded-full"
                    style={{
                      background: `linear-gradient(to top, #7C2B2D 0%, white ${calculateGradientPercentage(statisticsData.failed, statisticsData.total)}%)`,
                      opacity: 0.85,
                    }}
                  />

                  <span className="absolute inset-1/2 -translate-x-1/2 -translate-y-1/2 size-[calc(100%-8px)] bg-white" />
                </motion.div>

                {/* Center image */}
                <motion.div
                  className="absolute grid place-content-center"
                  initial={{ scale: 0 }}
                  animate={{ rotate: 360, scale: 1 }}
                  transition={{ delay: 0.5, type: "spring", stiffness: 260, damping: 20 }}
                >
                  <img src="/assets/image/user-statistics/user-statistics.svg" alt="User Avatar" className="rounded-full size-[3.6875]" />
                </motion.div>
              </div>
            </>
          ) : (
            <div className="flex items-center justify-center w-full h-full">
              <p className="text-closed-shutter-300">No data available</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserStatistics;
