import React, { useEffect } from "react";
import { useUsersList } from "../../../contexts/UsersListContext";
import Loader from "../../loading/Loader";
import StatusBadge from "../../table/StatusBadge";
import { Call, Sms } from "iconsax-react";

interface UserDetailsProps {
  userId: string;
}

const UserDetails: React.FC<UserDetailsProps> = ({ userId }) => {
  const { userDetail, fetchUserDetail, loading } = useUsersList();

  useEffect(() => {
    if (userId) {
      fetchUserDetail(userId);
    }
  }, [userId]);

  if (loading) {
    return (
      <div className="flex justify-center w-full p-4 md:p-6">
        <Loader />
      </div>
    );
  }

  if (!userDetail)
    return (
      <div className="flex justify-center w-full p-4 md:p-6">
        {/* <span className="capitalize text-english-vermillion-300">No user details found</span> */}
        <span className="capitalize text-closed-shutter-300">No user details found</span>
      </div>
    );

  return (
    <div className="grid w-full grid-cols-1 gap-5 p-4 border rounded-md md:grid-cols-2 xl:grid-cols-4 place-items-center border-gorgonzola-blue-50 ">
      <div className="flex justify-center w-full overflow-hidden">
        {userDetail.first_name || userDetail.last_name ? (
          <p className="line-clamp-1">
            {userDetail.first_name ? userDetail.first_name : null} {userDetail.last_name ? userDetail.last_name : null}
          </p>
        ) : (
          <span>---</span>
        )}
      </div>
      <div className="flex justify-center w-full overflow-hidden">
        {userDetail.email ? (
          <a href={`mailto:${userDetail.email}`} className="inline-flex items-center w-full gap-2 text-left hover:text-gorgonzola-blue-300 duration-600 line-clamp-1">
            <Sms className="size-[1.125rem]" variant="Broken" />
            <p className="line-clamp-1 ">{userDetail.email}</p>
          </a>
        ) : (
          <span>---</span>
        )}
      </div>

      <div className="flex justify-center w-full overflow-hidden">
        {userDetail.phone_number ? (
          <a href={`tel:${userDetail.phone_number}`} className="inline-flex items-center w-full gap-2 text-left hover:text-gorgonzola-blue-300 duration-600 line-clamp-1">
            <Call className="size-[1.125rem]" variant="Broken" />
            <p className="line-clamp-1">{userDetail.phone_number}</p>
          </a>
        ) : (
          <span>---</span>
        )}
      </div>

      <div className="flex justify-center w-full">
        <StatusBadge statusText={userDetail.role_info.name.toLowerCase()} />
      </div>
    </div>
  );
};

export default UserDetails;
