import { FC, useRef, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import ReCAPTCHA from "react-google-recaptcha";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { signUpWithEmail } from "../../utilities/api/authService";
import { SITE_KEY } from "../../utilities/defaults/recaptcha/sitekey";
import MainButton from ".././button/MainButton";
import TextField from ".././fields/TextField";
import { enqueueSnackbar } from "notistack";
import GoogleButton from "../button/GoogleButton";

interface RegisterFormData {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  confirmPassword: string;
}

const Register: FC = () => {
  const { googleSignIn, isAuthenticated } = useAuth();
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isGoogleSubmitting, setIsGoogleSubmitting] = useState(false);
  const navigate = useNavigate();
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setError: setFormError,
    reset,
  } = useForm<RegisterFormData>({
    mode: "onChange",
    defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      confirmPassword: "",
    },
  });

  if (isAuthenticated) {
    return <Navigate to="/" />;
  }

  const handleGoogleSuccess = async (credentialResponse: any) => {
    setIsGoogleSubmitting(true);
    const success = await googleSignIn(credentialResponse.access_token);
    if (success) {
      enqueueSnackbar("Successfully logged in with Google!", { variant: "success" });
      reset();
      navigate("/");
      setIsGoogleSubmitting(false);
    } else {
      enqueueSnackbar("Error logging in with Google. Please try again.", { variant: "error" });
      setIsGoogleSubmitting(false);
    }
  };

  const handleGoogleError = () => {
    enqueueSnackbar("Google sign-up failed. Please try again.", { variant: "error" });
  };

  const handleCaptchaChange = (value: string | null) => {
    setCaptchaToken(value);
  };

  const onSubmit: SubmitHandler<RegisterFormData> = async (data) => {
    setIsSubmitting(true);

    if (!captchaToken) {
      enqueueSnackbar("Please complete the reCAPTCHA verification.", { variant: "error" });
      setIsSubmitting(false);
      recaptchaRef.current?.reset();
      return;
    }

    if (data.password !== data.confirmPassword) {
      setFormError("confirmPassword", { type: "manual", message: "Passwords do not match" });
      setIsSubmitting(false);
      return;
    }

    try {
      const signUpReq = await signUpWithEmail(data.first_name, data.last_name, data.email, data.password, captchaToken);

      if (signUpReq.ok) {
        enqueueSnackbar(signUpReq.message || "Account created successfully! Please check your email to verify your account.", { variant: "success" });
        reset();
      } else {
        enqueueSnackbar(signUpReq.message || "Registration unsuccessful. Please check your information and try again.", { variant: "error" });
      }
    } catch (err) {
      enqueueSnackbar("An error occurred during sign-up. Please try again later.", { variant: "error" });
    } finally {
      setIsSubmitting(false);
      recaptchaRef.current?.reset();
    }
  };

  const isFormValid = watch("first_name") && watch("last_name") && watch("email") && watch("password") && watch("confirmPassword") && Object.keys(errors).length === 0;

  return (
    <div className="relative flex items-center justify-center w-full min-h-screen gap-10 bg-white xxl:gap-20">
      <span className=" absolute inset-0 -left-[1.7rem] size-[20.5rem] blur-[175px] rounded-full z-10 bg-[#474bcf33]" />
      <div className="z-20 flex flex-col h-full gap-8 px-6 py-16 lg:gap-28">
        {/* <Link to={"https://shaku.tech"} className="flex justify-center md:justify-start"> */}
        {/* <img src="/assets/image/authenticate/mobile-logo.svg" alt="Logo" className="w-[8rem] block lg:hidden" /> */}
        {/* <img src="/assets/image/authenticate/desktop-logo.svg" alt="Logo" className="w-[11rem] hidden lg:block" /> */}
        {/* <img src="/assets/image/logo/new-logo.png" alt="Logo" className="hidden size-16 lg:block" /> */}
        {/* </Link> */}
        <div className="flex flex-col items-center gap-20 w-full lg:w-[21rem] max-w-[21rem]">
          <div className="space-y-2">
            <h1 className="text-lg font-bold text-closed-shutter-300">Sign up</h1>
            <p className="text-xs font-normal text-closed-shutter-200">Provide the necessary information to sign up and get started.</p>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col w-full gap-6">
            <div className="flex flex-col gap-2">
              <Controller
                name="first_name"
                control={control}
                rules={{ required: "First name is required" }}
                render={({ field }) => (
                  <TextField label="First Name" placeholder="Enter your first name" error={!!errors.first_name} success={!!watch("first_name") && !errors.first_name} {...field} />
                )}
              />
              {/* {errors.first_name && <div className="text-xs text-english-vermillion-300">{errors.first_name.message}</div>} */}
              {errors.first_name && <div className="text-xs text-closed-shutter-300">Error: {errors.first_name.message}</div>}
            </div>
            <div className="flex flex-col gap-2">
              <Controller
                name="last_name"
                control={control}
                rules={{ required: "Last name is required" }}
                render={({ field }) => <TextField label="Last Name" placeholder="Enter your last name" error={!!errors.last_name} success={!!watch("last_name") && !errors.last_name} {...field} />}
              />
              {/* {errors.last_name && <div className="text-xs text-english-vermillion-300">{errors.last_name.message}</div>} */}
              {errors.last_name && <div className="text-xs text-closed-shutter-300">Error: {errors.last_name.message}</div>}
            </div>
            <div className="flex flex-col gap-2">
              <Controller
                name="email"
                control={control}
                rules={{
                  required: "Email is required",
                  pattern: {
                    value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/,
                    message: "Invalid email address",
                  },
                }}
                render={({ field }) => <TextField label="Email" placeholder="Enter your email" type="email" error={!!errors.email} success={!!watch("email") && !errors.email} {...field} />}
              />
              {/* {errors.email && <div className="text-xs text-english-vermillion-300">{errors.email.message}</div>} */}
              {errors.email && <div className="text-xs text-closed-shutter-300">Error: {errors.email.message}</div>}
            </div>
            <div className="flex flex-col gap-2">
              <Controller
                name="password"
                control={control}
                rules={{
                  required: "Password is required",
                  minLength: { value: 8, message: "Password must be at least 8 characters long" },

                  pattern: {
                    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>/?])(?!.*\s).{10,128}$/,
                    message: "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character",
                  },
                }}
                render={({ field }) => (
                  <TextField
                    label="Password"
                    placeholder="Enter your password"
                    type="password"
                    showPasswordHint
                    error={!!errors.password}
                    success={!!watch("password") && !errors.password}
                    {...field}
                  />
                )}
              />
            </div>
            <div className="flex flex-col gap-2">
              <Controller
                name="confirmPassword"
                control={control}
                rules={{
                  required: "Confirm password is required",
                  validate: (value) => value === watch("password") || "Passwords do not match",
                }}
                render={({ field }) => (
                  <TextField
                    label="Confirm Password"
                    placeholder="Confirm your password"
                    type="password"
                    error={!!errors.confirmPassword}
                    success={!!watch("confirmPassword") && !errors.confirmPassword}
                    {...field}
                  />
                )}
              />
            </div>

            <div className="flex justify-center">
              <ReCAPTCHA ref={recaptchaRef} sitekey={SITE_KEY} onChange={handleCaptchaChange} />
            </div>

            <div className="flex flex-col items-center gap-6">
              <div className="flex flex-col justify-end w-full gap-4">
                <MainButton type="primary" isSubmit disabled={!isFormValid || isSubmitting} customClass=" justify-center min-h-14 ">
                  {isSubmitting ? "Submitting..." : "Sign up"}
                </MainButton>

                <GoogleButton onSuccess={handleGoogleSuccess} onError={handleGoogleError} onSubmiting={isGoogleSubmitting} text="Sign in with Google" />
              </div>

              <div className="space-x-2 text-xs text-center">
                <span className="text-closed-shutter-300">Already have an account?</span>
                <span className="text-gorgonzola-blue-300">
                  <Link className="" to="/auth/login">
                    Sign In
                  </Link>
                </span>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="flex-grow hidden px-6 py-16 lg:block max-w-[40rem]">
        <img src="/assets/image/authenticate/auth-pattern.png" alt="pattern" className="min-w-full" />
      </div>
    </div>
  );
};

export default Register;
