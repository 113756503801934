import React from "react";
import { useNavigate } from "react-router-dom";
import { User } from "../../../utilities/types/admin/types";
import Table from "../../table/Table";
import StatusBadge from "../../table/StatusBadge";
import MobileUserCard from "./MobileUserCard";
import { TableColumn } from "../../../utilities/types/support/types";

interface UsersTableProps {
  users: User[];
}

const UsersTable: React.FC<UsersTableProps> = ({ users }) => {
  const navigate = useNavigate();

  const handleRowClick = (user: User) => {
    navigate(`/admin/users/${user.id}`);
  };

  const userColumns: TableColumn<User>[] = [
    {
      key: "id",
      header: "Users Id(B)",
      render: (value) => <span className="text-xs font-medium">#{value ? value : "-"}</span>,
    },
    {
      key: "email",
      header: "Email",
      render: (value) => <span className="text-xs line-clamp-1">{value ? value : "-"}</span>,
    },
    {
      key: "role_info",
      header: "Type user",
      render: (value) => (
        <div className="flex w-full">
          <StatusBadge statusText={value.name.toLowerCase()} />
        </div>
      ),
    },
    {
      key: "total_requests",
      header: "Total Requests",
      render: (value) => (
        <div className="flex items-center justify-center">
          <span className="text-xs">{value}</span>
        </div>
      ),
    },
    {
      key: "plan_status",
      header: "Plan",
      render: (value) => (
        <div className="flex w-full">
          <StatusBadge statusText={value.toLowerCase()} />
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="block xl:hidden odd:*:bg-gorgonzola-blue-50/30 even:*:bg-white">
        {users.map((user) => (
          <MobileUserCard key={user.id} user={user} onClick={() => handleRowClick(user)} />
        ))}
      </div>

      <div className="hidden w-full xl:block">
        <Table data={users} columns={userColumns} onRowClick={handleRowClick} />
      </div>
    </>
  );
};

export default UsersTable;
