import React from "react";
import ClothesRecognitionResult from "../../../usage/ClothesRecognitionResult";
import BodyMeasurementResult from "../../../usage/BodyMeasurementResult";
import VisualClothesSearchResult from "../../../usage/VisualClothesSearchResult";
import { SearchResponseItem } from "../../../../utilities/types/free-trial/visual-clothes-service/types";
import { MeasurementResult } from "../../../../utilities/types/measurement-data/types";
import { ClothesDetectionResult } from "../../../../utilities/types/free-trial/auto-tagging-service/types";
import ReusableModal from "../../../modal/ReusableModal";

interface ServiceDetailsModalProps {
  isOpen: boolean;
  onClose: () => void;
  serviceData: any;
  serviceName: string;
}

const ServiceDetailsModal: React.FC<ServiceDetailsModalProps> = ({ isOpen, onClose, serviceData, serviceName }) => {
  const renderContent = () => {
    if (serviceName === "Auto Tagging") {
      return <ClothesRecognitionResult clothesData={serviceData.response.Data as ClothesDetectionResult} />;
    } else if (serviceName === "Body Measurement") {
      return <BodyMeasurementResult measurementData={serviceData.response.Data as MeasurementResult} />;
    } else if (serviceName === "Visual Clothes Search") {
      return <VisualClothesSearchResult visualClothesData={serviceData.response.Data.Result as SearchResponseItem[]} />;
    }
    return null;
  };

  return (
    <ReusableModal isOpen={isOpen} onClose={onClose}>
      <div className="flex flex-col w-full gap-6 bg-white rounded-l min-w-72">
        <h2 className="py-2 font-bold text-closed-shutter-300 pe-12">{serviceName} Result</h2>
        {serviceData.response ? (
          renderContent()
        ) : (
          <div className="p-4 text-center">
            {/* <p className="font-bold text-english-vermillion-300">No data available for this service.</p> */}
            <p className="font-bold text-closed-shutter-300">No data available for this service.</p>
            <p className="mt-2 text-xs text-closed-shutter-200">Please try again or contact support if the issue persists.</p>
          </div>
        )}
      </div>
    </ReusableModal>
  );
};

export default ServiceDetailsModal;
