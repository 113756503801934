import React from "react";
import { useAutoTaggingServiceContext } from "../../../contexts/AutoTaggingServiceContext";

const ImageDisplay: React.FC = () => {
  const { uploadedImage } = useAutoTaggingServiceContext();

  return (
    <div className="grid place-content-center w-[15rem] h-[25rem] border-2 rounded-md border-gorgonzola-blue-50 overflow-hidden ">
      {uploadedImage ? (
        <img src={`data:image/png;base64,${uploadedImage}`} alt="" className="object-contain w-[15rem] h-[25rem]" />
      ) : (
        <div className="grid size-full place-content-center">
          <img src="/assets/image/free-trial/auto-tagging/temp-auto-tagging.png" alt="temp auto tagging" className="object-contain object-center w-full h-full" />
        </div>
      )}
    </div>
  );
};

export default ImageDisplay;
