import React, { useState } from "react";
import { TableColumn } from "../../../../utilities/types/support/types";
import StatusBadge from "../../../table/StatusBadge";
import Table from "../../../table/Table";
import MobileUsageCard from "./MobileUsageCard";
import MainButton from "../../../button/MainButton";
import { Eye } from "iconsax-react";
import ServiceDetailsModal from "./ServiceDetailsModal";
import { TransformTime } from "../../../../utilities/helper/TransformPublishedAt";

interface UsageAnalyticsTableProps {
  usageData: any[];
}

const UsageAnalyticsTable: React.FC<UsageAnalyticsTableProps> = ({ usageData }) => {
  const [selectedService, setSelectedService] = useState<any | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleViewDetails = (item: any) => {
    setSelectedService(item);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedService(null);
  };

  const usageColumns: TableColumn<any>[] = [
    {
      key: "user_email",
      header: "Business Email",
      render: (value) => <span className="text-xs">{value ? value : "-"}</span>,
    },
    {
      key: "service_name",
      header: "Service",
      render: (value) => <span className="text-xs">{value ? value : "-"}</span>,
    },
    {
      key: "status",
      header: "Status",
      render: (value) => (
        <div className="flex w-full">
          <StatusBadge statusText={value.toLowerCase()} />
        </div>
      ),
    },
    {
      key: "created_at",
      header: "Date",
      render: (value) => <span className="text-xs">{value ? new Date(value * 1000).toLocaleDateString() : "-"}</span>,
    },
    {
      key: "time",
      header: "Time",
      render: (value, item) => <span className="text-xs">{item.created_at ? TransformTime(new Date(item.created_at * 1000).toISOString()) : "-"}</span>,
    },
    {
      key: "Result",
      header: "Result",
      render: (value, item) => (
        <div className="flex justify-center w-full">
          <MainButton type="ghostOutlined" onClick={() => handleViewDetails(item)} disabled={item.status !== "success"}>
            <Eye variant="Broken" className="mr-2 size-4" />
            See
          </MainButton>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="block xl:hidden odd:*:bg-gorgonzola-blue-50/30 even:*:bg-white">
        {usageData.map((usage) => (
          <MobileUsageCard key={usage.id} usage={usage} onViewDetails={handleViewDetails} />
        ))}
      </div>

      <div className="hidden w-full xl:block">
        <Table data={usageData} columns={usageColumns} />
      </div>

      {selectedService && <ServiceDetailsModal isOpen={isModalOpen} onClose={handleCloseModal} serviceData={selectedService} serviceName={selectedService.service_name} />}
    </>
  );
};

export default UsageAnalyticsTable;
