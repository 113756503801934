import React, { useState } from "react";
import { Eye } from "iconsax-react";
import Table from "../../table/Table";
import MainButton from "../../button/MainButton";
import { TableColumn } from "../../../utilities/types/support/types";
import MobileContactCard from "./MobileContactCard";
import ContactModal from "./ContactModal";
import TransformPublishedAt from "../../../utilities/helper/TransformPublishedAt";

interface ContactsTableProps {
  contactsData: any[];
}

const ContactsTable: React.FC<ContactsTableProps> = ({ contactsData }) => {
  const [selectedContact, setSelectedContact] = useState<any>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const contactsColumns: TableColumn<any>[] = [
    {
      key: "email",
      header: "Business Email",
      render: (value, item) => <span className="text-xs">{item.attributes.email ? item.attributes.email : "-"}</span>,
    },
    {
      key: "first_name",
      header: "First Name",
      render: (value, item) => <span className="text-xs">{item.attributes.first_name ? item.attributes.first_name : "-"}</span>,
    },
    {
      key: "last_name",
      header: "Last Name",
      render: (value, item) => <span className="text-xs">{item.attributes.last_name ? item.attributes.last_name : "-"}</span>,
    },
    {
      key: "updatedAt",
      header: "Date",
      render: (value, item) => <span className="text-xs">{item.attributes.updatedAt ? TransformPublishedAt(item.attributes.updatedAt) : "-"}</span>,
    },
    {
      key: "id",
      header: "Show Description",
      render: (value, row) => (
        <MainButton
          type="ghostOutlined"
          onClick={() => {
            setSelectedContact(row);
            setIsModalOpen(true);
          }}
        >
          <Eye variant="Broken" className="mr-2 size-4" />
          See
        </MainButton>
      ),
    },
  ];

  return (
    <>
      <div className="block xl:hidden odd:*:bg-gorgonzola-blue-50/30 even:*:bg-white">
        {contactsData.map((contact) => (
          <MobileContactCard key={contact.id} contact={contact} />
        ))}
      </div>

      <div className="hidden w-full xl:block">
        <Table data={contactsData} columns={contactsColumns} />
      </div>

      <ContactModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} contact={selectedContact} />
    </>
  );
};

export default ContactsTable;
