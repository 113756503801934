import React, { createContext, useState, useContext, useCallback } from "react";
import { AuthContext } from "./AuthContext";
import { ClothesDetectionResult, ClothingItem } from "../utilities/types/free-trial/auto-tagging-service/types";
import { convertToBase64 } from "../utilities/helper/convertToBase64";
import { autoTaggingService } from "../utilities/api/autoTaggingService";

interface AutoTaggingServiceContextValue {
  uploadedImage: string | null;
  imageDimensions: { width: number; height: number } | null;
  scaleFactor: { x: number; y: number } | null;
  currentStep: number;
  canProceedToNextStep: boolean;
  autoTaggingResult: ClothesDetectionResult | null;
  hoveredItem: ClothingItem | null;
  error: string | null;
  setError: (error: string | null) => void;
  handlePageChange: (page: number) => void;
  handleImageUpload: (image: File | undefined) => void;
  setCanProceedToNextStep: (value: boolean) => void;
  submitAutoTagging: () => Promise<void>;
  resetAutoTaggingService: () => void;
  setHoveredItem: (item: ClothingItem | null) => void;
  getAdjustedCoordinates: (region: ClothingItem["Region"]) => { X1: number; Y1: number; X2: number; Y2: number };
}

export const AutoTaggingServiceContext = createContext<AutoTaggingServiceContextValue | undefined>(undefined);

export const AutoTaggingServiceProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [uploadedImage, setUploadedImage] = useState<string | null>(null);
  const [imageDimensions, setImageDimensions] = useState<{ width: number; height: number } | null>(null);
  const [scaleFactor, setScaleFactor] = useState<{ x: number; y: number } | null>(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [canProceedToNextStep, setCanProceedToNextStep] = useState(false);
  const [autoTaggingResult, setAutoTaggingResult] = useState<ClothesDetectionResult | null>(null);
  const [hoveredItem, setHoveredItem] = useState<ClothingItem | null>(null);
  const { accessToken } = useContext(AuthContext);
  const [error, setError] = useState<string | null>(null);

  const handlePageChange = (page: number) => {
    setError(null);
    setCurrentStep(page);
    setCanProceedToNextStep(false);
  };

  const handleImageUpload = useCallback(async (image: File | undefined) => {
    if (image) {
      setError(null);

      const originalBase64 = await convertToBase64(image);
      setUploadedImage(originalBase64);

      const img = new Image();
      await new Promise((resolve) => {
        img.onload = () => {
          const originalWidth = img.width;
          const originalHeight = img.height;

          const containerWidth = 240; // 15rem
          const containerHeight = 400; // 25rem

          const scale = Math.min(containerWidth / originalWidth, containerHeight / originalHeight);

          const scaledWidth = originalWidth * scale;
          const scaledHeight = originalHeight * scale;

          const x = (containerWidth - scaledWidth) / 2;
          const y = (containerHeight - scaledHeight) / 2;

          setImageDimensions({
            width: originalWidth,
            height: originalHeight,
          });

          setScaleFactor({
            x: scale,
            y: scale,
          });
        };
        img.src = URL.createObjectURL(image);
      });

      setCanProceedToNextStep(true);
    }
  }, []);

  const submitAutoTagging = async () => {
    if (!uploadedImage || !accessToken) {
      setError("No image uploaded or access token missing");
      return;
    }

    try {
      const response = await autoTaggingService(accessToken, uploadedImage);
      if (!response.ok) {
        setError(response.message || "Failed to process image");
      }

      if (response.ok && response.data.result.Clothes.length > 0) {
        setAutoTaggingResult(response.data.result);
        setCanProceedToNextStep(true);
        setCurrentStep(2);
        setError(null);
      } else if (!response.data.result.Clothes.length) {
        setError("The image couldn't be processed. Please try again with a different image.");
        setCurrentStep(1);
      } else {
        setError(response.message || "Failed to process image");
        setCurrentStep(1);
      }
    } catch (error) {
      console.error("Error submitting auto tagging:", error);
      // setError("An error occurred during auto tagging. Please try again.");
      setCurrentStep(1);
    }
  };

  const resetAutoTaggingService = () => {
    setUploadedImage(null);
    setImageDimensions(null);
    setScaleFactor(null);
    setCurrentStep(0);
    setCanProceedToNextStep(false);
    setAutoTaggingResult(null);
    setHoveredItem(null);
    setError(null);
  };

  const getAdjustedCoordinates = useCallback(
    (region: ClothingItem["Region"]) => {
      if (!imageDimensions || !scaleFactor) return region;

      const containerWidth = 240; // 15rem
      const containerHeight = 400; // 25rem

      const scaledWidth = imageDimensions.width * scaleFactor.x;
      const scaledHeight = imageDimensions.height * scaleFactor.y;
      const offsetX = (containerWidth - scaledWidth) / 2;
      const offsetY = (containerHeight - scaledHeight) / 2;

      return {
        X1: ((region.X1 * scaleFactor.x + offsetX) / containerWidth) * 100,
        Y1: ((region.Y1 * scaleFactor.y + offsetY) / containerHeight) * 100,
        X2: ((region.X2 * scaleFactor.x + offsetX) / containerWidth) * 100,
        Y2: ((region.Y2 * scaleFactor.y + offsetY) / containerHeight) * 100,
      };
    },
    [imageDimensions, scaleFactor]
  );

  return (
    <AutoTaggingServiceContext.Provider
      value={{
        uploadedImage,
        imageDimensions,
        scaleFactor,
        currentStep,
        canProceedToNextStep,
        autoTaggingResult,
        hoveredItem,
        error,
        setError,
        handlePageChange,
        handleImageUpload,
        setCanProceedToNextStep,
        submitAutoTagging,
        resetAutoTaggingService,
        setHoveredItem,
        getAdjustedCoordinates,
      }}
    >
      {children}
    </AutoTaggingServiceContext.Provider>
  );
};

export const useAutoTaggingServiceContext = () => {
  const context = useContext(AutoTaggingServiceContext);
  if (!context) {
    throw new Error("useAutoTaggingServiceContext must be used within an AutoTaggingServiceProvider");
  }
  return context;
};
