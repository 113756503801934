import React, { useState, useRef, useEffect } from "react";
import { Camera } from "iconsax-react";
import { motion, AnimatePresence } from "framer-motion";
import MainButton from "../button/MainButton";
import { useGlobalInfo } from "../../contexts/GlobalInfoContext";

interface AvatarUploadProps {
  onUpload: (file: string) => void;
}

const AvatarUpload: React.FC<AvatarUploadProps> = ({ onUpload }) => {
  const { userInfo } = useGlobalInfo();

  const [showModal, setShowModal] = useState(false);
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [currentAvatar, setCurrentAvatar] = useState<string>("/assets/image/profile/avatar.svg");
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (userInfo?.profile?.profile_link) {
      setCurrentAvatar(userInfo.profile.profile_link);
    }
  }, [userInfo]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      if (file.size > 3 * 1024 * 1024) {
        alert("File size should not exceed 3MB");
        resetFileInput();
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result as string);
        setShowModal(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleUpload = () => {
    if (previewImage) {
      onUpload(previewImage.split(",")[1]);
      resetState();
    }
  };

  const resetState = () => {
    setShowModal(false);
    setPreviewImage(null);
    resetFileInput();
  };

  const resetFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  return (
    <>
      <div className="relative cursor-pointer size-fit" onClick={() => fileInputRef.current?.click()}>
        <div className="overflow-hidden rounded-full size-32 bg-gorgonzola-blue-50">
          <img src={currentAvatar} alt="User Avatar" className="object-contain size-full" />
        </div>
        <span className="absolute bottom-0 p-1 overflow-hidden -translate-x-1/2 translate-y-1/2 bg-white rounded-full left-1/2 w-fit">
          <Camera className="size-6" variant="Broken" />
        </span>
        <input type="file" ref={fileInputRef} onChange={handleFileChange} accept="image/*" style={{ display: "none" }} />
      </div>

      <AnimatePresence>
        {showModal && (
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[9999]">
            <div className="flex flex-col items-center gap-6 p-10 bg-white rounded-xl w-fit">
              <img src={previewImage || ""} alt="Preview" className="object-contain mx-auto mb-4 rounded-full size-32" />
              <h2 className="font-bold ">Change Profile Picture</h2>
              <div className="flex justify-end space-x-4">
                <MainButton type="ghostOutlined" onClick={resetState}>
                  Close
                </MainButton>
                <MainButton type="primary" onClick={handleUpload}>
                  Change Profile
                </MainButton>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default AvatarUpload;
