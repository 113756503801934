import { useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useVisualClothesServiceContext } from "../../../../contexts/VisualClothesServiceContext";
import VisualClothesServicePagination from "../VisualClothesServicePagination";
import InteractiveVisualClothesResult from "../InteractiveVisualClothesResult";
import CroppedImageDisplay from "../CroppedImageDisplay";
import SimilarItems from "../SimilarItems";

const VisualClothesServiceResult = () => {
  const { visualClothesResult, hoveredItem, setHoveredItem, searchSimilarItems, isFirstServiceCalled } = useVisualClothesServiceContext();

  useEffect(() => {
    if (visualClothesResult?.Clothes.length) {
      searchSimilarItems();
    }
  }, [visualClothesResult]);

  return (
    <div className="flex flex-col items-center w-full gap-32">
      <div className="flex flex-col items-center w-full gap-6 xl:items-start xl:flex-row">
        <div className="relative flex flex-col items-center w-full gap-4 p-4 border rounded-lg md:flex-row lg:flex-col xxl:flex-row md:w-fit h-fit border-gorgonzola-blue-50">
          <AnimatePresence>
            {!isFirstServiceCalled && (
              <motion.div className="absolute inset-0 grid place-content-center bg-black/50 rounded-lg z-50 *:size-20" initial="hidden" animate="visible" exit="hidden">
                <img src="/assets/image/free-trial/auto-tagging/analyzing.gif" alt="analyzing gif" />
              </motion.div>
            )}
          </AnimatePresence>

          <div className="flex flex-col gap-4">
            <InteractiveVisualClothesResult />
          </div>

          {visualClothesResult?.Clothes.length ? (
            <div className="flex flex-col gap-9">
              <div className="grid grid-cols-2 md:grid-cols-subgrid lg:grid-cols-2 xxl:grid-cols-subgrid gap-4 overflow-y-auto max-h-[25rem] custom_scroll">
                {visualClothesResult?.Clothes.map((clothingItem, index) => (
                  <div
                    key={index}
                    className={`flex flex-col items-center gap-2 p-2 rounded-md border border-gorgonzola-blue-50 transition-colors duration-600 cursor-pointer ${
                      hoveredItem === clothingItem ? "bg-gorgonzola-blue-100" : ""
                    }`}
                    onClick={() => setHoveredItem(clothingItem)}
                  >
                    <CroppedImageDisplay clothingItem={clothingItem} />

                    <div className="font-medium text-2xs text-closed-shutter-300">
                      {clothingItem.ClotheType}
                      {clothingItem.Design && ` - ${clothingItem.Design}`}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : null}
        </div>

        <div className="flex flex-col flex-grow w-full border divide-y rounded-lg border-gorgonzola-blue-50 divide-gorgonzola-blue-50">
          <div className="w-full p-4 md:p-6">
            <h2 className="font-bold text-closed-shutter-300">Similar Products</h2>
          </div>
          <SimilarItems />
        </div>
      </div>

      <VisualClothesServicePagination handlerButtonText="Try Again" isTaggingCompleted={true} />
    </div>
  );
};

export default VisualClothesServiceResult;
