import React from "react";

interface UnitToggleProps {
  selectedUnit: "cm" | "inch";
  onUnitChange: (unit: "cm" | "inch") => void;
}

const UnitToggle: React.FC<UnitToggleProps> = ({ selectedUnit, onUnitChange }) => {
  return (
    <div className="flex items-center p-1 border rounded-full border-gorgonzola-blue-50 bg-gorgonzola-blue-50/10">
      {(["cm", "inch"] as const).map((unit) => (
        <button
          key={unit}
          onClick={() => onUnitChange(unit)}
          className={`px-2.5 py-1 rounded-full text-sm font-medium transition-all duration-300
            ${selectedUnit === unit ? "bg-white text-closed-shutter-400 shadow-sm" : "text-closed-shutter-300 hover:text-closed-shutter-400"}`}
        >
          {unit.toUpperCase()}
        </button>
      ))}
    </div>
  );
};

export default UnitToggle;
