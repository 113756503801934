import React from "react";
import { anatomyConfig } from "../../../../utilities/config/anatomyConfig";
import { BodyPartAnalysis } from "../../../../utilities/types/free-trial/body-measurement-service/types";

interface BodyAnalysisVisualizerProps {
  analysis: {
    [key: string]: BodyPartAnalysis | string;
  };
  selectedSize: string;
  gender: "men" | "women" | "genderfree";
}

const BodyAnalysisVisualizer: React.FC<BodyAnalysisVisualizerProps> = ({ analysis, selectedSize, gender }) => {
  const normalizedGender = (gender || "genderfree").toLowerCase() as keyof typeof anatomyConfig;

  const config = anatomyConfig[normalizedGender] || anatomyConfig.genderfree;

  return (
    <div className="flex flex-col items-center w-full gap-6 md:gap-8 max-w-fit">
      <div className="grid w-full grid-cols-3 gap-4 p-4 place-items-center">
        <div className="col-span-3 relative w-[21.908125rem] h-[31rem] scale-75 md:scale-100">
          <img src={config.image} alt="Body Anatomy" className="object-contain w-full h-full" />

          {Object.entries(analysis).map(([part, data]) => {
            const partConfig = config.bodyParts[part];
            if (!partConfig) return null;

            return (
              <div key={part} className={`absolute ${partConfig.position}`}>
                <img src={partConfig.icon} className={`${partConfig.size}`} alt={part} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default BodyAnalysisVisualizer;
