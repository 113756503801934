import { ClothesDetectionResponse } from "../types/free-trial/auto-tagging-service/types";
import { SearchResponse } from "../types/free-trial/visual-clothes-service/types";
import instance from "./axiosInstance";

export const visualClothesService = async (accessToken: string, image: string, subUserId?: string | number): Promise<ClothesDetectionResponse> => {
  try {
    const response = await instance.post<ClothesDetectionResponse>(
      "/api/v1/services/search/clothesRecognition",
      { image, sub_user_id: subUserId },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error submitting auto tagging:", error);
    throw error;
  }
};

export const searchSimilarClothes = async (accessToken: string, params: any): Promise<any> => {
  try {
    const response = await instance.post<SearchResponse>("/api/v1/services/search", params, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error searching similar clothes:", error);
    throw error;
  }
};
