import React, { useEffect, useState } from "react";
import { useUsersList } from "../../../../contexts/UsersListContext";
import { Buliding, Call, Flag, House2, InfoCircle, Sms } from "iconsax-react";
import StatusBadge from "../../../table/StatusBadge";
import UserInfoModal from "./UserInfoModal";

interface UserInfoProps {
  userId: string;
}

const UserInfo: React.FC<UserInfoProps> = ({ userId }) => {
  const { userDetail, fetchUserDetail } = useUsersList();
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (userId) {
      fetchUserDetail(userId);
    }
  }, [userId]);

  if (!userDetail)
    return (
      <div className="flex justify-center w-full p-4 md:p-6">
        {/* <span className="capitalize text-english-vermillion-300">No user details found</span> */}
        <span className="capitalize text-closed-shutter-300">No user details found</span>
      </div>
    );

  return (
    <div className="flex flex-col items-center w-full gap-5 p-4 border rounded-md lg:flex-row border-gorgonzola-blue-50">
      <div className="flex justify-center">
        <div className="overflow-hidden rounded-md size-20 bg-gorgonzola-blue-50">
          <img src={userDetail.profile ? userDetail.profile : "/assets/image/profile/avatar.svg"} alt="User Avatar" className="object-contain size-full" />
        </div>
      </div>

      <div className="flex flex-col lg:flex-row items-center justify-between flex-grow gap-6 lg:gap-4 text-xs text-closed-shutter-300 min-h-28 *:flex *:flex-col *:items-center lg:*:items-start *:justify-center *:gap-3">
        <div>
          {userDetail.first_name || userDetail.last_name ? (
            <p>
              {userDetail.first_name ? userDetail.first_name : null} {userDetail.last_name ? userDetail.last_name : null}
            </p>
          ) : (
            <span>---</span>
          )}

          {userDetail.email ? (
            <a href={`mailto:${userDetail.email}`} className="inline-flex items-center w-full gap-2 text-left hover:text-gorgonzola-blue-300 duration-600 line-clamp-1">
              <Sms className="size-[1.125rem]" variant="Broken" />
              <p>{userDetail.email}</p>
            </a>
          ) : (
            <span>---</span>
          )}

          {userDetail.phone_number ? (
            <a href={`tel:${userDetail.phone_number}`} className="inline-flex items-center w-full gap-2 text-left hover:text-gorgonzola-blue-300 duration-600 line-clamp-1">
              <Call className="size-[1.125rem]" variant="Broken" />
              <p>{userDetail.phone_number}</p>
            </a>
          ) : (
            <span>---</span>
          )}
        </div>

        <div>
          <div className="inline-flex items-center gap-8">
            <div className="inline-flex items-center gap-2 text-closed-shutter-300/70 min-w-[8.8125rem]">
              <Flag className="size-[1.125rem]" variant="Broken" />
              <span>Country</span>
            </div>
            {userDetail.country ? <p className="text-closed-shutter-300">{userDetail.country}</p> : <span>---</span>}
          </div>

          <div className="inline-flex items-center gap-8">
            <div className="inline-flex items-center gap-2 text-closed-shutter-300/70 min-w-[8.8125rem]">
              <Buliding className="size-[1.125rem]" variant="Broken" />
              <span>City</span>
            </div>
            {userDetail.city ? <p className="text-closed-shutter-300">{userDetail.city}</p> : <span>---</span>}
          </div>

          <div className="inline-flex items-center gap-8">
            <div className="inline-flex items-center gap-2 text-closed-shutter-300/70 min-w-[8.8125rem]">
              <House2 className="size-[1.125rem]" variant="Broken" />
              <span>Company</span>
            </div>
            {userDetail.company ? <p className="text-closed-shutter-300">{userDetail.company}</p> : <span>---</span>}
          </div>
        </div>

        <div className="!gap-8">
          <div className="flex w-full">
            <StatusBadge statusText={userDetail.role_info.name.toLowerCase()} />
          </div>

          <div className="inline-flex items-center gap-2 cursor-pointer hover:text-gorgonzola-blue-300 duration-600" onClick={() => setIsModalOpen(true)}>
            <InfoCircle className="size-[1.125rem]" variant="Broken" />
            <span>More info</span>
          </div>
        </div>
      </div>
      <UserInfoModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} userDetail={userDetail} />
    </div>
  );
};

export default UserInfo;
