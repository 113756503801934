import instance from "./axiosInstance";
import { UserFilters, ServiceFilters, PlanFilters, TicketFilters, PaymentFilters, SendMessageParams, WalletDateRange } from "../types/admin/types";
import axios from "axios";

const adminBaseUrl = "/api/admin";

// Dashboard Services

//  * Fetches the dashboard list.
export const getDashboardList = async (accessToken: string) => {
  instance.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
  try {
    const response = await instance.get(`${adminBaseUrl}/dashboard`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

//  * Fetches the dashboard usage percentage.
export const getDashboardUsagePercentage = async (accessToken: string) => {
  instance.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
  try {
    const response = await instance.get(`${adminBaseUrl}/dashboard/usage`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

//  * Fetches the dashboard sale plan percentage.
export const getDashboardSalePlanPercentage = async (accessToken: string) => {
  instance.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
  try {
    const response = await instance.get(`${adminBaseUrl}/dashboard/salePlan`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

//  * Fetches the dashboard sale plan sum amount.
export const getDashboardSalePlanSumAmount = async (accessToken: string) => {
  instance.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
  try {
    const response = await instance.get(`${adminBaseUrl}/dashboard/amountPlan`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// User List Services

//  * Fetches a paginated list of users with optional filters.
export const getUsers = async (accessToken: string, page: number = 1, perPage: number = 10, filters?: UserFilters) => {
  instance.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
  try {
    const response = await instance.get(`${adminBaseUrl}/users`, {
      params: {
        page,
        per_page: perPage,
        ...filters,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// User Specific Services

//  * Fetches the service usage history for a specific user.ش
export const getUserUsage = async (accessToken: string, userId: string, page: number = 1, perPage: number = 10, filters?: ServiceFilters) => {
  instance.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
  try {
    const response = await instance.get(`${adminBaseUrl}/users/history/services`, {
      params: { user_id: userId, page, per_page: perPage, ...filters },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

//  * Fetches the list of plans purchased by a specific user.
export const getUserPlans = async (accessToken: string, userId: string, page: number = 1, perPage: number = 10, filters?: PlanFilters) => {
  instance.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
  try {
    const response = await instance.get(`${adminBaseUrl}/user/orders`, {
      params: { user_id: userId, page, per_page: perPage, ...filters },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

//  * Fetches the list of tickets created by a specific user.
export const getUserTickets = async (accessToken: string, userId: string, page: number = 1, perPage: number = 10, filters?: TicketFilters) => {
  instance.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
  try {
    const response = await instance.get(`${adminBaseUrl}/user/tickets`, {
      params: { user_id: userId, page, per_page: perPage, ...filters },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

//  * Fetches the payment history for a specific user.
export const getUserPayments = async (accessToken: string, userId: string, page: number = 1, perPage: number = 10, filters?: PaymentFilters) => {
  instance.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
  try {
    const response = await instance.get(`${adminBaseUrl}/users/history/payments`, {
      params: { user_id: userId, page, per_page: perPage, ...filters },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Global Services (All Users)

//  * Fetches a paginated list of service usage history for all users.
export const getAllUsage = async (accessToken: string, page: number = 1, perPage: number = 10, filters?: ServiceFilters) => {
  instance.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
  try {
    const response = await instance.get(`${adminBaseUrl}/history/services`, {
      params: {
        page,
        per_page: perPage,
        ...filters,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

//  * Fetches a paginated list of payment history for all users.
export const getAllPayments = async (accessToken: string, page: number = 1, perPage: number = 10, filters?: PaymentFilters) => {
  instance.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
  try {
    const response = await instance.get(`${adminBaseUrl}/history/payments`, {
      params: {
        page,
        per_page: perPage,
        ...filters,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

//  * Fetches the details of a specific payment by its ID.
export const getPaymentDetail = async (accessToken: string, paymentId: string) => {
  instance.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
  try {
    const response = await instance.get(`${adminBaseUrl}/payment/${paymentId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

//  * Fetches a paginated list of plans purchased by all users.
export const getAllPlans = async (accessToken: string, page: number = 1, perPage: number = 10, filters?: PlanFilters) => {
  instance.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
  try {
    const response = await instance.get(`${adminBaseUrl}/history/orders`, {
      params: {
        page,
        per_page: perPage,
        ...filters,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

//  * Fetches a paginated list of tickets created by all users.
export const getAllTickets = async (accessToken: string, page: number = 1, perPage: number = 10, filters?: TicketFilters) => {
  instance.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
  try {
    const response = await instance.get(`${adminBaseUrl}/tickets`, {
      params: {
        page,
        per_page: perPage,
        ...filters,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// User Detail

//  * Fetches detailed information about a specific user.
export const getUserDetail = async (accessToken: string, userId: string) => {
  instance.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
  try {
    const response = await instance.get(`${adminBaseUrl}/users/${userId}/detail`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// User Payment Chart

//  * Fetches the payment chart data for a specific user within a date range.
export const getUserPaymentChart = async (accessToken: string, userId: string, dateRange: WalletDateRange) => {
  instance.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
  try {
    const response = await instance.get(`${adminBaseUrl}/users/history/wallet/chart`, {
      params: {
        user_id: userId,
        from_date: dateRange.from_date.toString(),
        to_date: dateRange.to_date.toString(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// User Payment Sum

//  * Fetches the total sum of payments for a specific user within a date range.
export const getUserPaymentSum = async (accessToken: string, userId: string, dateRange: WalletDateRange) => {
  instance.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
  try {
    const response = await instance.get(`${adminBaseUrl}/users/history/wallet`, {
      params: {
        user_id: userId,
        from_date: dateRange.from_date.toString(),
        to_date: dateRange.to_date.toString(),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Get Ticket Messages

//  * Fetches the messages for a specific ticket.
export const getTicketMessages = async (accessToken: string, ticketId: string) => {
  instance.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
  try {
    const response = await instance.get(`${adminBaseUrl}/ticket`, {
      params: { ticket_id: ticketId },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Send Ticket Message

//  * Sends a message to a specific ticket.
export const sendTicketMessage = async (accessToken: string, params: SendMessageParams) => {
  instance.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
  try {
    const response = await instance.post(`${adminBaseUrl}/ticket/messages`, params);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Additional Services

//  * Fetches the active plans for a specific user.
export const getUserActivePlans = async (accessToken: string, userId: string) => {
  instance.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
  try {
    const response = await instance.get(`${adminBaseUrl}/users/orders/actived`, {
      params: { user_id: userId },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

//  * Fetches the usage chart data for a specific user within a date range.
export const getUserPlanChart = async (accessToken: string, userId: string, dateRange: WalletDateRange, point: string, labelId?: number | null, clientTimezone?: string) => {
  instance.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
  try {
    const response = await instance.get(`${adminBaseUrl}/users/history/api_charts`, {
      params: {
        user_id: userId,
        from_date: dateRange.from_date.toString(),
        to_date: dateRange.to_date.toString(),
        point,
        label_id: labelId,
        client_timezone: clientTimezone,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

//  * Fetches the total usage requests for a specific user within a date range.
export const getUserUsageTotal = async (accessToken: string, userId: string, dateRange: WalletDateRange, point: string, clientTimezone: string) => {
  instance.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
  try {
    const response = await instance.get(`${adminBaseUrl}/user/requests_charts`, {
      params: {
        user_id: userId,
        from_date: dateRange.from_date.toString(),
        to_date: dateRange.to_date.toString(),
        point,
        // label_id: labelId,
        client_timezone: clientTimezone,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

//  * Fetches the usage chart data for a specific user within a date range.
export const getUserUsageChart = async (accessToken: string, userId: string, dateRange: WalletDateRange, point: string, labelId: number, clientTimezone: string) => {
  instance.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
  try {
    const response = await instance.get(`${adminBaseUrl}/user/requests_charts`, {
      params: {
        user_id: userId,
        from_date: dateRange.from_date.toString(),
        to_date: dateRange.to_date.toString(),
        point,
        label_id: labelId,
        client_timezone: clientTimezone,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

//  * Fetches detailed information about a specific user.
export const getUserShow = async (accessToken: string, userId: string) => {
  instance.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
  try {
    const response = await instance.get(`${adminBaseUrl}/users/${userId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Contacts Service

//  * Fetches the contacts list.

export const getContacts = async (page: number) => {
  try {
    const response = await axios.get(`https://shaku.tech/siteapi/api/contacts?sort=updatedAt:desc&pagination[pageSize]=12&pagination[page]=${page}&populate=*`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
