import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { MainAdminUsagePercentage } from "../../../utilities/types/admin/types";

ChartJS.register(ArcElement, Tooltip, Legend);

interface UsagePercentageChartProps {
  data: MainAdminUsagePercentage[];
}

const UsagePercentageChart: React.FC<UsagePercentageChartProps> = ({ data }) => {
  const orderedData = [
    {
      label: "Auto Tagging",
      color: "#3491FA",
      displayName: "Auto Tagging",
    },
    {
      label: "Visual Clothes Search",
      color: "#14C9C9",
      displayName: "Visual Clothes Search",
    },
    {
      label: "Body Measurement",
      color: "#F7BA1E",
      displayName: "Body Measurement",
    },
  ];

  const sortedData = orderedData.map((item) => {
    const foundItem = data.find((d) => d.label === item.label);
    return {
      ...item,
      usage_percentage: foundItem ? foundItem.usage_percentage : 0,
      usage_count: foundItem ? foundItem.usage_count : 0,
    };
  });

  const chartData = {
    datasets: [
      {
        label: "Usage Percentage",
        data: sortedData.map((item) => item.usage_percentage),
        backgroundColor: sortedData.map((item) => item.color),
        hoverBackgroundColor: sortedData.map((item) => item.color),
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: "#fff",
        titleColor: "#484554c2",
        titleFont: {
          size: 12,
          weight: "bold" as const,
        },
        titleAlign: "center" as const,
        bodyColor: "#23262F",
        bodyFont: {
          size: 12,
          weight: "bolder" as const,
        },
        bodyAlign: "left" as const,
        bodySpacing: 8,
        borderColor: "#C6C7F0",
        borderWidth: 1,
        cornerRadius: 16,
        padding: 12,
        usePointStyle: true,
        boxPadding: 8,
        callbacks: {
          label: function (context: any) {
            const label = sortedData[context.dataIndex].displayName;
            const value = context.parsed;
            return `${label}: ${value}%`;
          },
          labelTextColor: function (context: any) {
            return sortedData[context.dataIndex].color;
          },
        },
      },
    },
  };

  return (
    <div className="flex flex-col justify-between w-full min-h-[22rem]">
      <div className="relative w-full h-[14.625rem]">
        <div className="absolute bottom-0 z-20 h-full transform -translate-x-1/2 left-1/2">
          <Doughnut data={chartData} options={options} />
        </div>
      </div>
      <div className="flex flex-col items-center w-full gap-6 p-4">
        <div className="flex flex-col w-full gap-3">
          {sortedData.map((item, index) => (
            <div key={index} className="inline-flex items-center justify-between">
              <div className="inline-flex items-center gap-2">
                <span className="rounded-full size-3 min-w-3" style={{ backgroundColor: item.color }}></span>
                <span className="text-xs text-closed-shutter-300/70">{item.displayName}</span>
              </div>
              <span className="text-xs text-closed-shutter-300">{item.usage_percentage}%</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default UsagePercentageChart;
